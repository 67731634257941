import React from "react";
import YouTubeIcon from "@material-ui/icons/YouTube";
import YouTube from "react-youtube";
import { Media } from "react-bootstrap";

const YoutubeWrapper = ({
  title,
  youtubeid,
  newVideo,
  frameid,
  onPlay,
  titleCss = "",
}) => {
  return (
    <React.Fragment>
      {title && (
        <h5 className={`text-left pt-2 pb-1 ${titleCss}`}>
          <Media className="">
            {newVideo && (
              <span
                className={`mt-1 defaultstandardfontsize badge badge-pill badge-danger mr-2`}
              >
                New
              </span>
            )}
            {!newVideo && <YouTubeIcon className="text-danger mr-2" />}
            <Media.Body style={{ lineHeight: "1.3em" }}>{title}</Media.Body>
          </Media>
        </h5>
      )}

      <div
        className="video mb-2"
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          paddingTop: "20px",
          height: 0,
        }}
      >
        <YouTube
          className="youtubecontainer"
          videoId={youtubeid} // defaults -> null
          id={frameid} // defaults -> null
          onPlay={() => onPlay(youtubeid, frameid, title)} // defaults -> noop
        />
      </div>
    </React.Fragment>
  );
};

export default YoutubeWrapper;
