import React from "react";

const LabelLegend = ({ color, label }) => {
  const classStyle = "dotlegend float-left pr-2 " + color;
  return (
    <div className="clearfix">
      <div className={classStyle}></div>
      <div
        className="float-left pl-2"
        style={{ fontSize: "16px", marginTop: "-4px" }}
      >
        {label}
      </div>
    </div>
  );
};

export default LabelLegend;
