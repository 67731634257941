import React, { useEffect, useContext, useRef, useState } from "react";
import fire from "../../../../../services/fire";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import DatePicker from "react-datepicker";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import { useForm, Controller } from "react-hook-form";

import AppGlobalContext from "../../../../../AppGlobalContext";

import {
  useWardSelection,
  useGuardian,
  useRegistrationDetails,
} from "../../hooks/hooks";
import {
  registrationCollectionName,
  guardianCodesCollectionName,
} from "../../services/collections";
import { registrationStatusNameToStatus } from "../../services/registrationStatus";

import SaveDraftConfirmationModal from "./SaveDraftConfirmationModal";
import { countryList } from "../../services/resources";

async function handleSaveData(
  registrationCollection,
  guardianCodesCollection,
  registrationDetails,
  registrationData,
  ward,
  uid,
  setRegistrationStep,
  jumpTo,
  fromReview,
  setFromReview
) {
  if (!uid || !ward.uid) {
    return;
  }

  let registrationDoc;

  registrationData.assignedClass = "";

  if (!registrationDetails.guardianCode) {
    const snapshot = await guardianCodesCollection
      .where("status", "==", "consumed")
      .where("wardId", "==", ward.uid)
      .where("guardianId", "==", uid)
      .limit(1)
      .get();

    if (!snapshot.empty) {
      const datum = snapshot.docs[0].data();
      registrationData.guardianCode = { ...datum };
    }
  }

  if (registrationDetails.id) {
    registrationDoc = registrationCollection.doc(registrationDetails.id);

    const docData = {
      ...registrationDetails,
      ...registrationData,
    };

    try {
      await registrationDoc.update(docData);

      if (jumpTo > 0) {
        if (fromReview) {
          setRegistrationStep(7);
          setFromReview(false);
        } else {
          setRegistrationStep(jumpTo);
        }
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.error("handleSaveData::error:", error);
    }
  } else {
    registrationDoc = registrationCollection.doc();

    const docData = {
      id: registrationDoc.id,
      status: registrationStatusNameToStatus("unregistered"),
      submittedAt: new Date(),
      submittedBy: uid,
      child: {
        name: ward.name,
        uid: ward.uid,
      },
      payments: [],
      ...registrationData,
    };

    try {
      await registrationDoc.set(docData);

      if (jumpTo > 0) {
        if (fromReview) {
          setRegistrationStep(7);
          setFromReview(false);
        } else {
          setRegistrationStep(jumpTo);
        }
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.error("handleSaveData::error:", error);
    }
  }
}

export default function BasicDetails({
  registrationStep,
  setRegistrationStep,
  fromReview,
  setFromReview,
}) {
  const registrationDetails = useRegistrationDetails();

  const { user } = useContext(AppGlobalContext);
  const { selectedWardIndex } = useWardSelection();
  const { wardsData } = useGuardian();

  const ward = wardsData[selectedWardIndex];

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);
  const guardianCodesCollection = db.collection(guardianCodesCollectionName);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      sex: "",
      dateOfBirth: "",
      placeOfBirth: "",
      nationality: "",
    },
  });

  const effectTriggeredRef = useRef(false);

  useEffect(() => {
    if (!effectTriggeredRef.current && registrationDetails.id) {
      effectTriggeredRef.current = true;
      reset({
        name: registrationDetails.name || "",
        sex: registrationDetails.sex || "",
        dateOfBirth: registrationDetails.dateOfBirth
          ? registrationDetails.dateOfBirth.toDate()
          : "",
        placeOfBirth: registrationDetails.placeOfBirth || "",
        nationality: registrationDetails.nationality || "",
      });
    }

    return () => {
      effectTriggeredRef.current = false;
      reset();
    };
  }, [registrationDetails]);

  function onSubmit() {
    const registrationData = getValues();

    handleSaveData(
      registrationCollection,
      guardianCodesCollection,
      registrationDetails,
      registrationData,
      ward,
      user.uid,
      setRegistrationStep,
      registrationStep + 1,
      fromReview,
      setFromReview
    );
  }

  function onSaveDraft() {
    const registrationData = getValues();

    handleSaveData(
      registrationCollection,
      guardianCodesCollection,
      registrationDetails,
      registrationData,
      ward,
      user.uid,
      setRegistrationStep,
      0,
      fromReview,
      setFromReview
    );
  }

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <div
              className="position-absolute p-2 border-bottom border-left"
              style={{
                right: "0px",
                top: "0px",
                borderBottomLeftRadius: "0.25rem",
                backgroundColor: "#dee2e6",
              }}
            >
              1&nbsp;<span className="text-muted">of 4</span>
            </div>
            <h3>Child's Basic Details</h3>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">
                    Enter your child's name (as in birth certificate)
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    className="w-100"
                    placeholder="Enter child's name"
                    {...register("name", { required: true })}
                    isInvalid={!!errors.name}
                  />
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">
                    Select your child's sex
                  </Form.Label>
                  <Form.Check
                    className="w-100"
                    type="radio"
                    name="sex"
                    label="Male"
                    id="Male"
                    value="Male"
                    {...register("sex", { required: true })}
                    isInvalid={!!errors.sex}
                  />
                  <Form.Check
                    className="w-100"
                    type="radio"
                    name="sex"
                    label="Female"
                    id="Female"
                    value="Female"
                    {...register("sex", { required: true })}
                    isInvalid={!!errors.sex}
                  />
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">
                    Enter your child's date of birth (e.g. 01 Jun 2018)
                  </Form.Label>
                  <Row className="w-100 mx-0">
                    <Col xs={12} sm={6}>
                      <Row>
                        <Controller
                          name={"dateOfBirth"}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onBlur, onChange, value } }) => (
                            <Form.Control
                              as={DatePicker}
                              size="lg"
                              selected={value}
                              onBlur={onBlur}
                              onChange={onChange}
                              dateFormat="dd MMM yyyy"
                              placeholderText="dd mmm yyyy"
                              maxDate={new Date()}
                              showYearDropdown={true}
                              showMonthDropdown={true}
                              isInvalid={!!errors.dateOfBirth}
                            />
                          )}
                        />
                        {!!errors.dateOfBirth ? (
                          <></>
                        ) : (
                          <div
                            className="position-absolute"
                            style={{ top: "10px", right: "10px" }}
                          >
                            <CalendarTodayIcon />
                          </div>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Select child's country of birth&nbsp;
                    <span className="text-muted">(optional)</span>
                  </Form.Label>
                  <Row className="w-100 mx-0">
                    <Col className="px-0" xs={12} sm={6}>
                      <Form.Control
                        className="w-100"
                        as="select"
                        size="lg"
                        {...register("placeOfBirth", { required: false })}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          className="text-muted font-italic"
                        >
                          Country of Birth
                        </option>
                        {countryList.map((v) => (
                          <option key={`countryofbirth-${v}`} value={v}>
                            {v}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">
                    Select child's nationality
                  </Form.Label>
                  <Row className="w-100 mx-0">
                    <Col className="px-0" xs={12} sm={6}>
                      <Form.Control
                        className="w-100"
                        as="select"
                        size="lg"
                        {...register("nationality", { required: true })}
                        isInvalid={!!errors.nationality}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          className="text-muted font-italic"
                        >
                          Nationality
                        </option>
                        {countryList.map((v) => (
                          <option key={`nationality-${v}`} value={v}>
                            {v}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Row>
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Container className="px-0 mt-4">
        <Row>
          <Col className="d-none d-sm-flex" />
          <Col xs="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => setShowModal(true)}
            >
              Save Draft
            </Button>
          </Col>
          <Col className="col" sm="auto">
            <Button
              variant="primary"
              size="lg"
              className="w-100"
              onClick={handleSubmit(onSubmit)}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Container>
      {registrationDetails.id && registrationDetails.programmeType ? (
        <Container className="px-0 mt-4 text-wrap text-center">
          If you have enquiries, please contact your Parish Catechist
          Coordinator at&nbsp;
          <span className="text-primary" style={{ cursor: "pointer" }}>
            {registrationDetails.programmeType.enquiryEmail}
          </span>
        </Container>
      ) : (
        <></>
      )}
      <SaveDraftConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSaveDraft={onSaveDraft}
      />
    </>
  );
}
