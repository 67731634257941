import React, { memo } from "react";
import { useSelector } from "react-redux";
import {
  BALLOT_STATUS_INVITED,
  BALLOT_STATUS_VOLUNTEER,
  BALLOT_STATUS_UNREGISTERED,
  BALLOT_STATUS_UNKNOWN,
  BALLOT_STATUS_BALLOTED_SUCCESS,
  BALLOT_STATUS_BALLOTED_UNSUCCESSFUL,
  BALLOT_STATUS_BALLOTED_REJECTED,
  BALLOT_STATUS_REGISTERED,
} from "../utils/papalMassConst";
import SpecialInvite from "./specialInvite";
import Volunteer from "./volunteerScreen";
import UnknowStatus from "./unknownStatus";
import BallotOutputFail from "./ballotOutputFail";
import RejectedBallot from "./rejectedBallot";
import BallotOutputSuccess from "./ballotOutputSuccess";
import GetUserScreen from "./getUserScreen";

function BallotRegistrationResults({ img }) {
  const { ballotStatus, ballotDetails } = useSelector(
    (state) => state.papalMassBallot
  );

  const firstballotseen = ballotDetails?.firstballotseen || false;
  const firstballotpass = ballotDetails?.firstballotpass || false;
  switch (ballotStatus) {
    case BALLOT_STATUS_INVITED:
      return <SpecialInvite img={img} />;
    case BALLOT_STATUS_VOLUNTEER:
      return <Volunteer img={img} />;
    case BALLOT_STATUS_BALLOTED_SUCCESS: //for those who registered for ballotting
      return <BallotOutputSuccess />;
    //please take note, if booking has failed, it will be 6, then changed to 2 but with reballot = true
    case BALLOT_STATUS_REGISTERED:
    case BALLOT_STATUS_BALLOTED_UNSUCCESSFUL: {
      if (!firstballotseen && !firstballotpass) {
        return <BallotOutputFail />;
      }
      return <GetUserScreen img={img} />;
    }
    case BALLOT_STATUS_UNREGISTERED: //for those who registered but cancelled
    case BALLOT_STATUS_UNKNOWN: //for those who haven't registered for ballotting
      return <GetUserScreen img={img} />;
    case BALLOT_STATUS_BALLOTED_REJECTED:
      return <RejectedBallot />;
    default:
      return <UnknowStatus />;
  }
}

export default memo(BallotRegistrationResults);
