import React from "react";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { setErrorProcessing } from "../redux/papalMassBallot";

function ErrorProcessModal() {
  const dispatch = useDispatch();
  const { errorProcessing } = useSelector((state) => state.papalMassBallot);

  return (
    <Modal
      className="p-5"
      show={errorProcessing}
      animation={false}
      centered
      backdrop="static"
      keyboard={false}
      style={{ zIndex: 2000 }}
    >
      <div className="m-5 row justify-content-center d-flex flex-column mx-auto appearslowly">
        <div className="text-center">
          <ErrorRoundedIcon
            className="text-warning appearslowly bounce"
            style={{ fontSize: "100px" }}
          />
        </div>
        <p className="appearslowly text-dark defaultfontsize  font-weight-bold  text-center mt-2 mb-3">
          Please try again.
        </p>

        <div className="text-center appearslowly pb-4">
          <div className="">
            <Button
              variant="primary"
              className="btn-lg mt-4 px-5"
              onClick={() => {
                dispatch(setErrorProcessing(true));
                window.location.reload();
              }}
            >
              Reload Page
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ErrorProcessModal;
