import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../img/logo/logo_archdiocese_transparent.png";

function LandingBanner() {
  return (
    <div className="container py-4">
      <div className="d-flex justify-content-center mt-4">
        <div className="ml-4 mr-1 text-left">
          <h1 className="pb-2">Welcome</h1>
          <p className="normalsmallfontsize">
            Login to view and access <i>my</i>CatholicSG
          </p>
        </div>
        <div className="mx-4 d-none d-sm-block"></div>
        <div className="ml-2 text-right">
          <img
            src={logo}
            width="100px"
            height="100px"
            alt="Archdiocese of Singapore Logo"
          />
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-center align-items-center">
        <div className="text-center ">
          <div style={{ width: "250px" }}>
            <Link
              className="font-weight-bold btn btn-block btn-primary mr-4 mb-3"
              role="button"
              to="/login"
            >
              Login
            </Link>
            <Link
              role="button"
              to="/register"
              className="font-weight-bold btn btn-outline-primary btn-block mb-3"
            >
              Create Account
            </Link>
            <Link
              role="button"
              to="/forgot"
              className="font-weight-bold btn btn-link btn-block text-danger"
            >
              Forgot or Reset Password
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingBanner;
