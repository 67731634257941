import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../common/input";
import SpinnerText from "../../common/spinnerwithText";
import {
  setConfirmText,
  setErrorProcessing,
  setInviteList,
  setProcessing,
  setShowModal,
} from "../redux/papalMassBallot";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { BALLOT_EMAIL_PREFIX } from "../utils/papalMassConst";
import { cancelInvite } from "../service/ballot";

function DeleteInvitationModal() {
  const dispatch = useDispatch();
  const {
    currentUser,
    showModal,
    processing,
    inviteList,
    selectedItem,
    confirmText,
  } = useSelector((state) => state.papalMassBallot);

  if (selectedItem === null) {
    return null;
  }

  const handleChange = ({ currentTarget: input }) => {
    dispatch(setConfirmText(input.value));
  };

  const doDelete = async () => {
    //delete
    dispatch(setProcessing(true));
    const uid = currentUser?.userid;
    const email = selectedItem[`${BALLOT_EMAIL_PREFIX}${selectedItem?.id}`];
    const result = await cancelInvite(uid, email);
    // console.log(result);
    //if error, show error
    if (result?.data?.status === 0 || result?.data?.status === -1) {
      dispatch(setErrorProcessing(true));
      return;
    }
    const newEntries = inviteList.filter(
      (item) => item?.id !== selectedItem?.id
    );
    dispatch(setInviteList(newEntries));
    dispatch(setConfirmText(""));
    dispatch(setProcessing(false));
    dispatch(setShowModal(false));
  };

  return (
    <Modal
      show={showModal}
      animation={false}
      centered
      backdrop="static"
      keyboard={false}
    >
      <div className="text-center align-items-center d-flex justify-content-center flex-column m-4">
        <WarningRoundedIcon
          className="text-danger appearslowly"
          style={{ fontSize: "100px" }}
        />
        <p className="appearslowly text-dark defaultfontsize text-center mt-2 mb-3">
          Proceed to delete invitation for{" "}
          <b>{selectedItem[`email_${selectedItem?.id}`]}?</b>
        </p>
        <Input
          disabled={processing}
          autoFocus={true}
          css="pl-0 pb-0 mt-2 mb-3 text-center"
          type="text"
          name={`email`}
          value={confirmText}
          label={<b>Retype email to proceed</b>}
          placeholder=""
          onChange={handleChange}
        />
        <Button
          disabled={
            processing ||
            confirmText !== selectedItem[`email_${selectedItem?.id}`]
          }
          variant={"danger"}
          className="btn-lg mt-3 px-4"
          onClick={doDelete}
        >
          {!processing ? (
            <div className=" font-weight-bold">Proceed, Delete</div>
          ) : (
            <SpinnerText text={"Processing..."} />
          )}
        </Button>
        <Button
          disabled={processing}
          variant={"link"}
          className="btn-lg mt-3 biggerfontsize"
          onClick={() => dispatch(setShowModal(false))}
        >
          Go Back
        </Button>
      </div>
    </Modal>
  );
}

export default DeleteInvitationModal;
