import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ConfettiExplosion from "react-confetti-explosion";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { useSelector, useDispatch } from "react-redux";
import {
  setShowModal,
  setShowConfetti,
  setShowOptOut,
  setShowConfirmModal,
  setProcessing,
  setHeaderNotificationText,
  setScreen,
  setMemberList,
  setErrorProcessing,
  setStatusText,
} from "../redux/papalMassBallot";
import LeaveGroupModal from "./leaveGroupModal";
import DisplayMembers from "./displayMembers";
import {
  BALLOT_SCREEN_INDIVIDUAL_SUCCESS,
  BALLOT_USER_LEAVE_JOINED_GROUP,
  PFS_REG_INDIVIDUAL_BALLOT_OK,
  PFS_REG_LEAVE_JOINED_GROUP_BALLOT,
  PFS_REG_LEAVE_JOINED_GROUP_BALLOT_NOK,
  PFS_REG_LEAVE_JOINED_GROUP_BALLOT_OK,
} from "../utils/papalMassConst";
import { getFormattedDate, getUserObjToLog } from "../utils/utils";
import { logAction } from "../../../services/log";
import { leaveGroup, updateUserBallotState } from "../service/ballot";
import ReloadModal from "../../common/reloadModal";

const largeProps = {
  force: 0.8,
  duration: 3000,
  particleCount: 200,
  width: 1600,
  // colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
};

function JoinGroupSuccess() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    currentUser,
    showModal,
    showConfetti,
    processing,
    showOptOut,
    memberList,
    statusText,
    ballotDetails,
  } = useSelector((state) => state.papalMassBallot);
  // console.log(selectedItem);
  // console.log(memberList);
  const handleLeaveGroup = () => {
    dispatch(setShowConfetti(false));
    dispatch(setShowModal(false));
    dispatch(setShowConfirmModal(true));
  };

  const handleDone = () => {
    dispatch(setShowConfetti(false));
    dispatch(setShowOptOut(true));
    history.push("/home");
  };

  const doLeaveGroup = async () => {
    const uid = currentUser?.userid;
    const userObj = getUserObjToLog(currentUser);
    dispatch(setProcessing(true));

    await logAction(uid, PFS_REG_LEAVE_JOINED_GROUP_BALLOT, userObj);
    try {
      //leave
      const result = await leaveGroup(uid);
      //if error, show error
      if (result?.data?.status === 0 || result?.data?.status === -1) {
        dispatch(setErrorProcessing(true));
        return;
      }
      await updateUserBallotState(uid, BALLOT_USER_LEAVE_JOINED_GROUP);
      //leave group THEN log as individual
      const out = await logAction(
        uid,
        PFS_REG_LEAVE_JOINED_GROUP_BALLOT_OK,
        userObj
      );
      await logAction(uid, PFS_REG_INDIVIDUAL_BALLOT_OK, userObj);
      const date = getFormattedDate(new Date(out?.created));
      dispatch(setStatusText(date));
      dispatch(setProcessing(false));
      dispatch(setShowConfetti(true));
      dispatch(setShowModal(false));
      dispatch(
        setHeaderNotificationText(
          "You have left your group and are registered for "
        )
      );
      //clear member list
      dispatch(setMemberList([]));
      dispatch(setShowConfirmModal(false));
      dispatch(setScreen(BALLOT_SCREEN_INDIVIDUAL_SUCCESS));
    } catch (error) {
      await logAction(uid, PFS_REG_LEAVE_JOINED_GROUP_BALLOT_NOK, {
        ...userObj,
        error: error.message,
      });
      //TODO: set to proper state and screen
      console.error("Failed to update user ballot status:", error);
    }
  };

  return (
    <div className="mt-4 row justify-content-center mx-auto appearslowly">
      <ReloadModal />
      <main className="container">
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-4 text-center pb-4">
            <div className="text-center">
              {showConfetti && (
                <div className="d-flex justify-content-center align-items-center">
                  <ConfettiExplosion zIndex={10000} {...largeProps} />
                </div>
              )}
              <div
                className="mt-2 mx-auto justify-content-center d-flex text-center align-items-center"
                style={{
                  color: "#fff",
                  width: "90px",
                  height: "90px",
                  border: "2px solid #28a745",
                  backgroundColor: "#28a745",
                  borderRadius: "50%",
                }}
              >
                <CheckRoundedIcon
                  className={`text-white appearslowly ${
                    showModal ? "" : "reveal"
                  }`}
                  style={{ fontSize: "90px" }}
                />
              </div>
            </div>
            {ballotDetails?.hasOwnProperty("firstballotpass") &&
            ballotDetails?.firstballotpass === false ? (
              <p className="appearslowly text-dark defaultfontsize text-center mt-2 mb-4">
                You were unsuccessful in the 1st ballot and have been enrolled
                into the 2nd ballot
              </p>
            ) : (
              <p className="appearslowly text-dark defaultfontsize text-center mt-2 mb-4">
                You have successfully registered for <b>Group Balloting</b>!
              </p>
            )}
            <p className="appearslowly text-dark text-center mt-0 mb-3">
              {statusText}
            </p>
            <DisplayMembers memberList={memberList} />
            <p className="appearslowly text-left mt-3 mb-3">
              <i>Results of the 2nd ballot will be released on 19 August.</i>
            </p>
            <div className="text-center appearslowly">
              <div className="">
                <Button
                  disabled={processing}
                  variant="primary"
                  className="btn-lg mt-4 px-5"
                  onClick={handleDone}
                >
                  <div className="mx-5 font-weight-bold">Done</div>
                </Button>
              </div>

              {showOptOut && (
                <div className="">
                  <Button
                    disabled={processing}
                    variant={"link"}
                    className="btn-lg text-danger mt-3 biggerfontsize"
                    onClick={handleLeaveGroup}
                  >
                    Leave Group
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      <LeaveGroupModal onLeaveGroup={doLeaveGroup} />
    </div>
  );
}

export default JoinGroupSuccess;
