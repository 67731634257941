import React, { useEffect, useState } from "react";
import { Button, Media } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ConfettiExplosion from "react-confetti-explosion";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { useSelector, useDispatch } from "react-redux";
import {
  setShowModal,
  setShowConfetti,
  setShowOptOut,
  setSelectedItem,
  setShowConfirmModal,
  setShowInviteModal,
  setProcessing,
  setHeaderNotificationText,
  setScreen,
  setAcceptList,
  setMemberList,
  setRejectList,
  setErrorProcessing,
  setStatusText,
} from "../redux/papalMassBallot";
import DeleteInvitationModal from "./deleteInvitationModal";
import LeaveGroupModal from "./leaveGroupModal";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import {
  BALLOT_ACCEPT_LIST_INIT,
  BALLOT_EMAIL_PREFIX,
  BALLOT_MAX_MEMBERS,
  BALLOT_SCREEN_INDIVIDUAL_SUCCESS,
  BALLOT_SIMULATE,
  BALLOT_USER_LEAVE_GROUP_CREATED,
  PFS_REG_INDIVIDUAL_BALLOT_OK,
  PFS_REG_LEAVE_CREATED_GROUP_BALLOT,
  PFS_REG_LEAVE_CREATED_GROUP_BALLOT_NOK,
  PFS_REG_LEAVE_CREATED_GROUP_BALLOT_OK,
} from "../utils/papalMassConst";
import InviteEntryModal from "./inviteEntryModal";
import { getFormattedDate, getUserObjToLog } from "../utils/utils";
import { leaveGroup, updateUserBallotState } from "../service/ballot";
import { logAction } from "../../../services/log";
import SelectLeaderModal from "./selectLeaderModal";
import ReloadModal from "../../common/reloadModal";

const largeProps = {
  force: 0.8,
  duration: 3000,
  particleCount: 200,
  width: 1600,
  // colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
};

function GroupSuccess() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    currentUser,
    showModal,
    showConfetti,
    processing,
    showOptOut,
    inviteList,
    acceptList,
    rejectList,
    showControls,
    selectedItem,
    statusText,
    ballotDetails,
  } = useSelector((state) => state.papalMassBallot);

  const [rejectListFiltered, setRejectListFiltered] = useState([]);

  useEffect(() => {
    const acceptEmails = acceptList.map((i) => i.email);
    const filterAccepted = rejectList.filter(
      (i) => acceptEmails.indexOf(i.email) === -1
    );
    const inviteEmails = inviteList.map((i) => {
      const name = `${BALLOT_EMAIL_PREFIX}${i?.id}`;
      return i[name];
    });
    const filterPending = filterAccepted.filter(
      (i) => inviteEmails.indexOf(i.email) === -1
    );

    const filterDuplicates = {};
    filterPending.forEach((i) => {
      filterDuplicates[i.email] = i;
    });

    const filterDuplicatesArray = Object.values(filterDuplicates);

    setRejectListFiltered(filterDuplicatesArray);
  }, [rejectList]);

  // console.log(selectedItem);
  // console.log(acceptList);
  const handleDelete = async (i) => {
    //delete
    dispatch(setShowConfetti(false));
    dispatch(setSelectedItem(i));
    dispatch(setShowModal(true));
  };

  const handleAddNew = async () => {
    //add new
    dispatch(setShowConfetti(false));
    dispatch(setShowInviteModal(true));
  };

  const handleDone = () => {
    // console.log("Calling Done...");
    // console.log("Optout: ", showOptOut);
    dispatch(setShowConfetti(false));
    dispatch(setShowOptOut(true));
    if (BALLOT_SIMULATE && !showOptOut) {
      //simulate someone accepted
      //BALLOT_ACCEPT_LIST_INIT
      const obj = BALLOT_ACCEPT_LIST_INIT;
      // console.log(obj);
      dispatch(setAcceptList(obj));
    }
    history.push("/home");
  };

  const handleLeaveGroup = () => {
    dispatch(setShowConfetti(false));
    dispatch(setShowModal(false));
    dispatch(setShowConfirmModal(true));
    dispatch(setSelectedItem(null));
  };

  const doLeaveGroup = async () => {
    const uid = currentUser?.userid;
    const userObj = getUserObjToLog(currentUser);
    dispatch(setProcessing(true));
    //leave

    await logAction(uid, PFS_REG_LEAVE_CREATED_GROUP_BALLOT, userObj);
    try {
      //leave group
      let result;
      if (selectedItem?.userid) {
        // console.log("****** Leaving Group: ", selectedItem?.userid);
        result = await leaveGroup(uid, selectedItem?.userid);
      } else {
        // console.log("Leaving Group Alone");
        result = await leaveGroup(uid);
      }
      // console.log(result);
      //if error, show error
      if (result?.data?.status === 0 || result?.data?.status === -1) {
        dispatch(setErrorProcessing(true));
        return;
      }
      await updateUserBallotState(uid, BALLOT_USER_LEAVE_GROUP_CREATED);
      //leave group
      await logAction(uid, PFS_REG_LEAVE_CREATED_GROUP_BALLOT_OK, userObj);
      //and auto sign up for individual
      const out = await logAction(uid, PFS_REG_INDIVIDUAL_BALLOT_OK, userObj);
      const formattedDate = getFormattedDate(new Date(out?.created));
      dispatch(setStatusText(formattedDate));
      dispatch(setProcessing(false));
      dispatch(setShowConfetti(true));
      dispatch(setShowModal(false));
      dispatch(
        setHeaderNotificationText(
          "You have left your group and are registered for "
        )
      );
      //clear member list
      dispatch(setMemberList([]));
      dispatch(setRejectList([]));
      dispatch(setShowConfirmModal(false));
      dispatch(setScreen(BALLOT_SCREEN_INDIVIDUAL_SUCCESS));
    } catch (error) {
      await logAction(uid, PFS_REG_LEAVE_CREATED_GROUP_BALLOT_NOK, {
        ...userObj,
        error: error.message,
      });
      //TODO: set to proper state and screen
      console.error("Failed to update user ballot status:", error);
    }
  };

  return (
    <div className="mt-4 row justify-content-center mx-auto appearslowly">
      <ReloadModal />
      <main className="container">
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-4 text-center pb-4">
            <div className="text-center">
              {showConfetti && (
                <div className="d-flex justify-content-center align-items-center">
                  <ConfettiExplosion zIndex={10000} {...largeProps} />
                </div>
              )}
              <div
                className="mt-2 mx-auto justify-content-center d-flex text-center align-items-center"
                style={{
                  color: "#fff",
                  width: "90px",
                  height: "90px",
                  border: "2px solid #28a745",
                  backgroundColor: "#28a745",
                  borderRadius: "50%",
                }}
              >
                <CheckRoundedIcon
                  className={`text-white appearslowly ${
                    showModal ? "" : "reveal"
                  }`}
                  style={{ fontSize: "90px" }}
                />
              </div>
            </div>
            {ballotDetails?.hasOwnProperty("firstballotpass") &&
            ballotDetails?.firstballotpass === false ? (
              <p className="appearslowly text-dark defaultfontsize text-center mt-2 mb-4">
                You were unsuccessful in the 1st ballot and have been enrolled
                into the 2nd ballot
              </p>
            ) : (
              <p className="appearslowly text-dark defaultfontsize text-center mt-2 mb-4">
                You have successfully registered for <b>Group Balloting</b>!
              </p>
            )}
            <p className="appearslowly text-dark text-center mt-2 mb-3">
              {statusText}
            </p>
            <p className="appearslowly font-weight-bold text-dark text-left mt-0 mb-2">
              Member{acceptList?.length > 1 ? "s" : ""} (
              {parseInt(acceptList?.length) + 1})
            </p>
            <p
              className="appearslowly text-dark text-left pb-2 mb-0"
              style={{ borderBottom: "1px solid #dee2e6" }}
            >
              1. <span className="ml-1">{currentUser?.fullname} (Leader)</span>
            </p>
            {acceptList.map((i, index) => {
              //get the name
              // console.log(i);
              //Note: should we show email of other persons???
              const { userid, name, email } = i;
              // const name = `${BALLOT_EMAIL_PREFIX}${i?.id}`;
              return (
                <Media
                  style={{ borderBottom: "1px solid #dee2e6" }}
                  className="text-dark text-left pb-2"
                  key={userid}
                >
                  {index + 2}.
                  <Media.Body>
                    <p className="ml-1 appearslowly text-dark text-left mb-0">
                      {name} ({email})
                    </p>
                  </Media.Body>
                </Media>
              );
            })}

            {inviteList?.length > 0 && (
              <p className="appearslowly font-weight-bold biggersize text-left mt-3 mb-0">
                Pending ({parseInt(inviteList?.length)})
              </p>
            )}
            {inviteList.map((i, index) => {
              //get the name
              const name = `${BALLOT_EMAIL_PREFIX}${i?.id}`;
              return (
                <Media
                  key={name}
                  className="my-1"
                  style={{ borderBottom: "1px solid #dee2e6" }}
                >
                  {index + 1}.
                  <Media.Body>
                    <div className="text-left">
                      <p className="ml-1 appearslowly text-left mt-0 mb-2 float-left">
                        {i[name]}
                      </p>
                      {showControls && (
                        <div
                          className="ml-2 py-0 px-1 pointer  btn btn-sm btn-outline-danger float-right mb-2"
                          onClick={() => handleDelete(i)}
                        >
                          <CloseOutlinedIcon className="mediumfontsize adjusticon" />
                        </div>
                      )}
                    </div>
                  </Media.Body>
                </Media>
              );
            })}
            {rejectListFiltered?.length > 0 && (
              <p className="appearslowly font-weight-bold text-dark text-left mt-3 mb-2">
                Declined ({rejectListFiltered.length})
              </p>
            )}

            {rejectListFiltered.map((i, index) => {
              //get the name
              // console.log(i);
              //Note: should we show email of other persons???
              const { userid, name, email } = i;
              // const name = `${BALLOT_EMAIL_PREFIX}${i?.id}`;
              return (
                <Media
                  style={{ borderBottom: "1px solid #dee2e6" }}
                  className="text-dark text-left pb-2"
                  key={userid}
                >
                  {index + 1}.
                  <Media.Body>
                    <p className="ml-1 appearslowly text-dark text-left mb-0">
                      {name} ({email})
                    </p>
                  </Media.Body>
                </Media>
              );
            })}
            <div className="mt-4 mb-3 d-flex justify-content-center align-items-center">
              {inviteList?.length + acceptList?.length < BALLOT_MAX_MEMBERS && (
                <Button
                  variant="link"
                  style={{ border: "1px dashed #c0c0c0", borderRadius: "4px" }}
                  className="py-2 px-4 font-weight-bold"
                  onClick={handleAddNew}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <AddOutlinedIcon />
                  Add Member
                </Button>
              )}
            </div>
            <div className="appearslowly biggersize text-left my-3 alert alert-primary">
              {inviteList?.length > 0 && (
                <p>
                  An invitation to join your group has been sent to the above
                  myCatholicSG accounts.
                </p>
              )}
              <p className="mb-0">
                Please ensure that all invitees accept their invitations via
                their myCatholicSG account.
              </p>
            </div>

            <p className="appearslowly text-left mt-3 mb-3">
              <i>The ballot outcome will be released here in 19 August.</i>
            </p>

            <div className="text-center appearslowly">
              <div className="">
                <Button
                  disabled={processing}
                  variant="primary"
                  className="btn-lg mt-4 px-5"
                  onClick={handleDone}
                >
                  <div className="mx-5 font-weight-bold">Done</div>
                </Button>
              </div>
              {/* <div className="">
                <Button
                  disabled={processing}
                  variant={showOptOut ? "outline-primary" : "link"}
                  className="btn-lg mt-4 biggerfontsize"
                  onClick={handleGroupOptions}
                >
                  {!processing ? (
                    <div className=" ">Join / Create Group</div>
                  ) : (
                    <SpinnerText text={"Please wait..."} />
                  )}
                </Button>
              </div> */}
              {
                // {(acceptList?.length > 0 || inviteList?.length === 0) &&
                showOptOut && (
                  <div className="">
                    <Button
                      disabled={processing}
                      variant={"link"}
                      className="btn-lg text-danger mt-3 biggerfontsize"
                      onClick={handleLeaveGroup}
                    >
                      Leave Group
                    </Button>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </main>
      <DeleteInvitationModal />
      {acceptList?.length > 0 ? (
        <SelectLeaderModal onLeaveGroup={doLeaveGroup} />
      ) : (
        <LeaveGroupModal onLeaveGroup={doLeaveGroup} />
      )}
      <InviteEntryModal />
    </div>
  );
}

export default GroupSuccess;
