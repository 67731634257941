import React from "react";
import { logEvent } from "../../../services/log";
import YoutubeWrapper from "../../common/youtubeWrapper";
import CFBanner from "./cfBanner";

function JourneyWithPope({ code = "rest" }) {
  return (
    <div>
      {/* //https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fpapalmass%2FPFS%20Resource%20Banner%20-%20for%20DCO.png?alt=media&token=1a4d0f88-4df4-4fd7-88a1-c00f557742b5 */}
      <div className="pt-4">
        <CFBanner code={code} />
      </div>
      <div className="pointer pt-3">
        <a
          href="https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fpapalmass%2FPFSResource.pdf?alt=media&token=31703cf9-6862-4f94-a895-5e86e45faf2f"
          download
          target="_blank"
          rel="noopener noreferrer"
          className=""
          onClick={() => {
            logEvent(`journey_w_pope_pdf_${code}`, { type: "ONE" });
          }}
        >
          <img
            className="responsive img-fluid"
            src="https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fpapalmass%2FPFS%20Resource%20Banner%20-%20for%20DCO.png?alt=media&token=1a4d0f88-4df4-4fd7-88a1-c00f557742b5"
            alt=""
          />
        </a>
      </div>
      <YoutubeWrapper
        titleCss="m-2"
        className="text-left mb-0"
        title="Get Ready for the Papal Mass!"
        youtubeid="3hR3-ytm1HM"
        frameid="journey_w_pope_vid"
        onPlay={(youtubeid, frameid, title) => {
          // console.log(youtubeid, frameid, title);
          logEvent(`${frameid}_${code}`, { type: "ONE" });
        }}
      />
      <div className="mt-3 text-left">
        Gather your ministries, communities, family, and friends to embark on “A
        Joyful Journey with Pope Francis.” Dive into 12 key writings of Pope
        Francis, thoughtfully summarised to enrich your understanding and
        appreciation of his wisdom.
      </div>
      <div className="mb-5 text-center">
        {/* <a
          href="https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fpapalmass%2FPFSResource.pdf?alt=media&token=31703cf9-6862-4f94-a895-5e86e45faf2f"
          download
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-success btn-lg mt-3 mb-5"
          onClick={() => {
            logEvent(`journey_w_pope_pdf_${code}`, { type: "ONE" });
          }}
        >
          Download Now!
        </a>*/}
      </div>
    </div>
  );
}

export default JourneyWithPope;
