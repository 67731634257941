import { createSlice } from "@reduxjs/toolkit";
import {
  // BALLOT_INVITE_LIST_INIT,
  // BALLOT_MEMBER_LIST_INIT,
  // BALLOT_INVITATION_LIST_INIT,
  // BALLOT_ACCEPT_LIST_INIT,
  BALLOT_HEADER_TEXT,
  BALLOT_PHASE_1,
  BALLOT_SCREEN_INIT,
  BALLOT_STATUS_UNREGISTERED,
} from "../utils/papalMassConst";

const papalMassBallotSlice = createSlice({
  name: "papalMassBallot",
  initialState: {
    currentUser: null,
    ballotDetails: null,
    loading: true,
    showPFS: false,
    showLoginModal: false,
    showModal: false,
    showConfirmModal: false,
    showInviteModal: false,
    phase: BALLOT_PHASE_1,
    screen: BALLOT_SCREEN_INIT,
    showConfetti: false,
    processing: false,
    showOptOut: true,
    showControls: true,
    statusText: "",
    headerNotificationText: BALLOT_HEADER_TEXT,
    confirmText: "",
    selectedItem: null,
    showSuccess: false,
    lastActivity: "",
    inviteList: [], //BALLOT_INVITE_LIST_INIT,
    acceptList: [], //BALLOT_ACCEPT_LIST_INIT,
    rejectList: [],
    invitationList: [], //BALLOT_INVITATION_LIST_INIT,
    memberList: [], //BALLOT_MEMBER_LIST_INIT,
    allowSave: false,
    agree: false,
    errorProcessing: false,
    //added after ballot registration
    ballotStatus: BALLOT_STATUS_UNREGISTERED,
    //TODO: add more states
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setBallotDetails: (state, action) => {
      state.ballotDetails = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setShowPFS: (state, action) => {
      state.showPFS = action.payload;
    },
    setShowLoginModal: (state, action) => {
      state.showLoginModal = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setShowConfirmModal: (state, action) => {
      state.showConfirmModal = action.payload;
    },
    setShowInviteModal: (state, action) => {
      state.showInviteModal = action.payload;
    },
    setPhase: (state, action) => {
      state.phase = action.payload;
    },
    setScreen: (state, action) => {
      state.screen = action.payload;
    },
    setShowConfetti: (state, action) => {
      state.showConfetti = action.payload;
    },
    setProcessing: (state, action) => {
      state.processing = action.payload;
    },
    setShowOptOut: (state, action) => {
      state.showOptOut = action.payload;
    },
    setShowControls: (state, action) => {
      state.showControls = action.payload;
    },
    setStatusText: (state, action) => {
      state.statusText = action.payload;
    },
    setHeaderNotificationText: (state, action) => {
      state.headerNotificationText = action.payload;
    },
    setConfirmText: (state, action) => {
      state.confirmText = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setShowSuccess: (state, action) => {
      state.showSuccess = action.payload;
    },
    setLastActivity: (state, action) => {
      state.lastActivity = action.payload;
    },
    setInviteList: (state, action) => {
      state.inviteList = action.payload;
    },
    setAcceptList: (state, action) => {
      state.acceptList = action.payload;
    },
    setRejectList: (state, action) => {
      state.rejectList = action.payload;
    },
    setInvitationList: (state, action) => {
      state.invitationList = action.payload;
    },
    setMemberList: (state, action) => {
      state.memberList = action.payload;
    },
    setAllowSave: (state, action) => {
      state.allowSave = action.payload;
    },
    setAgree: (state, action) => {
      state.agree = action.payload;
    },
    setErrorProcessing: (state, action) => {
      state.errorProcessing = action.payload;
    },
    setBallotStatus: (state, action) => {
      state.ballotStatus = action.payload;
    },
  },
});

export const {
  setCurrentUser,
  setBallotDetails,
  setLoading,
  setShowPFS,
  setShowLoginModal,
  setShowModal,
  setShowConfirmModal,
  setShowInviteModal,
  setPhase,
  setScreen,
  setShowConfetti,
  setProcessing,
  setShowOptOut,
  setShowControls,
  setStatusText,
  setHeaderNotificationText,
  setConfirmText,
  setSelectedItem,
  setShowSuccess,
  setLastActivity,
  setInviteList,
  setAcceptList,
  setRejectList,
  setInvitationList,
  setMemberList,
  setAllowSave,
  setAgree,
  setErrorProcessing,
  setBallotStatus,
} = papalMassBallotSlice.actions;

export default papalMassBallotSlice.reducer;
