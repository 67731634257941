import React from "react";

function DisplayInvitesNotJoin({ memberList, text = "" }) {
  return (
    <div>
      {memberList?.length > 0 && (
        <p className="appearslowly font-weight-bolds text-dark text-left mt-2 mb-2">
          Did not join group before {text} ballot
        </p>
      )}
      {memberList.map((i) => {
        //get the name
        // console.log(i);
        const email = i?.email;
        return (
          <div
            className="roundsmallcornernocolor lightframecolor btn-block my-1 py-2 px-2 text-left"
            key={email}
          >
            <div className="">
              <span className="text-primary font-weight-bold">{email}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default DisplayInvitesNotJoin;
