import React from "react";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { BALLOT_PHASE_4 } from "../utils/papalMassConst";

function Withdrawn() {
  const history = useHistory();
  const { phase } = useSelector((state) => state.papalMassBallot);

  return (
    <div className="text-center align-items-center d-flex justify-content-center flex-column m-4">
      <div className="appearslowly text-center">
        <div className="text-center">
          <ErrorRoundedIcon
            className="text-warning appearslowly bounce"
            style={{ fontSize: "100px" }}
          />
        </div>

        <p className="appearslowly font-weight-bold defaultfontsize text-center mt-4 mb-4">
          You have withdrawn your attendance from the Papal Mass
        </p>

        {phase === BALLOT_PHASE_4 ? (
          <>
            <p className="alert alert-primary appearslowly normalsmallfontsize text-left mt-2 mb-3">
              You will not be allowed to participate in the ballot again.
            </p>
            <p className="appearslowly normalsmallfontsize text-left mt-4 mb-3">
              You may still watch the livestream on YouTube. God bless!{" "}
              <span role="img" aria-label="pray">
                🙏
              </span>
            </p>
          </>
        ) : (
          <p>
            Do tune in to watch the livestream on YouTube. God bless!{" "}
            <span role="img" aria-label="pray">
              🙏
            </span>
          </p>
        )}

        <div className="">
          <Button
            variant="primary"
            onClick={() => history.push("/home")}
            className="btn-lg my-4 biggerfontsize px-5"
          >
            <div className="mx-4">Done</div>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Withdrawn;
