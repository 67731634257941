import React, { memo, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SpinnerText from "../../common/spinnerwithText";
import {
  BALLOT_PHASE_4,
  BALLOT_SCREEN_INDIVIDUAL_SUCCESS,
  BALLOT_USER_INDIVIDUAL_SELECTED,
  PFS_REG_INDIVIDUAL_BALLOT,
  PFS_REG_INDIVIDUAL_BALLOT_NOK,
  PFS_REG_INDIVIDUAL_BALLOT_OK,
} from "../utils/papalMassConst";
import { logAction } from "../../../services/log";
import { getFormattedDate, getUserObjToLog } from "../utils/utils";
import {
  registerUserBallot,
  updateFirstBallotSeen,
  updateUserBallotState,
} from "../service/ballot";
import { useSelector, useDispatch } from "react-redux";
import {
  setScreen,
  setShowConfetti,
  setProcessing,
  setShowOptOut,
  setHeaderNotificationText,
  setErrorProcessing,
  setStatusText,
} from "../redux/papalMassBallot";
import YoutubeTutorial from "./youtubeTutorial";
import ReloadModal from "../../common/reloadModal";

function InitIndividual({ img }) {
  const isMounted = useRef(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser, processing, statusText, phase } = useSelector(
    (state) => state.papalMassBallot
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleUpdate = async () => {
    const uid = currentUser?.userid;
    dispatch(setProcessing(true));
    const userObj = getUserObjToLog(currentUser);
    await logAction(uid, PFS_REG_INDIVIDUAL_BALLOT, userObj);
    try {
      dispatch(setShowOptOut(false));
      const result = await registerUserBallot(currentUser);
      // console.log(result);
      //if error, show error
      if (result?.data?.status === 0 || result?.data?.status === -1) {
        dispatch(setErrorProcessing(true));
        return;
      }
      if (phase === BALLOT_PHASE_4) {
        await updateFirstBallotSeen(
          currentUser?.userid,
          currentUser?.email,
          true
        );
      }
      //check for result, if successful, ok
      const out = await logAction(uid, PFS_REG_INDIVIDUAL_BALLOT_OK, userObj);
      const formattedDate = getFormattedDate(new Date(out?.created));
      dispatch(setStatusText(formattedDate));
      await updateUserBallotState(uid, BALLOT_USER_INDIVIDUAL_SELECTED);
      dispatch(setShowConfetti(true));
      dispatch(setProcessing(false));
      dispatch(
        setHeaderNotificationText("You have successfully registered for ")
      );
      dispatch(setScreen(BALLOT_SCREEN_INDIVIDUAL_SUCCESS));
    } catch (error) {
      await logAction(uid, PFS_REG_INDIVIDUAL_BALLOT_NOK, userObj);
      //TODO: set to proper state and screen
      console.error("Failed to update user ballot status:", error);
    }
  };

  return (
    <div className="mt-4 row justify-content-center mx-auto">
      <main className="container">
        <ReloadModal />
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-4">
            {img}
            <div className="mt-2 pb-0 mb-0 bg-white">
              <hr style={{ borderTop: "2px dashed #f0f0ef" }} />
            </div>
            <p className="alert alert-primary">
              If you are attending the Papal Mass as a volunteer or an invited
              guest, please do not register for the ballot.
            </p>
            <p className="appearslowly text-dark text-left mt-2 mb-4 biggerfontsize">
              {statusText}
            </p>
            <p className="appearslowly font-weight-bold defaultfontsize text-left mt-0 mb-3">
              Would you like to register to Ballot for the Papal Mass?
            </p>
            <div className="appearslowly text-center">
              <div className="">
                <Button
                  disabled={processing}
                  variant="primary"
                  className="btn-lg mt-4 px-5"
                  onClick={handleUpdate}
                >
                  {!processing ? (
                    <div className="mx-5 font-weight-bold">Yes</div>
                  ) : (
                    <SpinnerText text={"Processing..."} />
                  )}
                </Button>
              </div>
              <div className="">
                <Button
                  variant="link"
                  onClick={() => history.push("/home")}
                  disabled={processing}
                  className="btn-lg mt-4 biggerfontsize"
                >
                  Go Back
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <YoutubeTutorial />
          </div>
        </div>
      </main>
    </div>
  );
}

export default memo(InitIndividual);
