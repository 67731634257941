import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  BALLOT_PHASE_4,
  BALLOT_SCREEN_GROUP_CREATE_SUCCESS,
  BALLOT_USER_GROUP_CREATED,
  PFS_REG_CREATE_GROUP_BALLOT,
  PFS_REG_CREATE_GROUP_BALLOT_NOK,
  PFS_REG_CREATE_GROUP_BALLOT_OK,
} from "../utils/papalMassConst";
import {
  setAgree,
  setErrorProcessing,
  setProcessing,
  setScreen,
  setShowConfetti,
  setShowModal,
  setShowOptOut,
  setStatusText,
} from "../redux/papalMassBallot";
import SpinnerText from "../../common/spinnerwithText";
import {
  getEmailsFromInviteList,
  getFormattedDate,
  getUserObjToLog,
} from "../utils/utils";
import { logAction } from "../../../services/log";
import {
  createGroup,
  updateFirstBallotSeen,
  updateUserBallotState,
} from "../service/ballot";

function ConfirmCreateGroupModal() {
  const dispatch = useDispatch();
  const { currentUser, showModal, processing, agree, inviteList, phase } =
    useSelector((state) => state.papalMassBallot);

  const doCreateGroup = async () => {
    //TODO: do something
    const uid = currentUser?.userid;
    const userObj = getUserObjToLog(currentUser);
    dispatch(setProcessing(true));
    await logAction(uid, PFS_REG_CREATE_GROUP_BALLOT, userObj);
    try {
      // console.log(inviteList);
      const emails = getEmailsFromInviteList(inviteList);
      const result = await createGroup(uid, emails);
      // console.log(result);
      if (result?.data?.status === 1) {
        if (phase === BALLOT_PHASE_4) {
          await updateFirstBallotSeen(
            currentUser?.userid,
            currentUser?.email,
            true
          );
        }
        //success
        await updateUserBallotState(uid, BALLOT_USER_GROUP_CREATED);
        const out = await logAction(
          uid,
          PFS_REG_CREATE_GROUP_BALLOT_OK,
          userObj
        );
        const formattedDate = getFormattedDate(new Date(out?.created));
        dispatch(setStatusText(formattedDate));
        dispatch(setProcessing(false));
        dispatch(setShowConfetti(true));
        dispatch(setShowModal(false));
        dispatch(setShowOptOut(false));
        dispatch(setScreen(BALLOT_SCREEN_GROUP_CREATE_SUCCESS));
      } else {
        //if error, show error
        if (result?.data?.status === 0 || result?.data?.status === -1) {
          dispatch(setErrorProcessing(true));
          return;
        }
        console.error("Error adding group: ", result);
        await logAction(uid, PFS_REG_CREATE_GROUP_BALLOT_NOK, {
          ...userObj,
          error: result?.data?.message,
        });
      }
    } catch (error) {
      await logAction(uid, PFS_REG_CREATE_GROUP_BALLOT_NOK, {
        ...userObj,
        error: error.message,
      });
      //TODO: set to proper state and screen
      console.error("Failed to update user ballot status:", error);
    }
  };

  return (
    <Modal
      show={showModal}
      animation={false}
      centered
      backdrop="static"
      keyboard={false}
    >
      <div className="text-center align-items-center d-flex justify-content-center flex-column m-4">
        <p className="appearslowly text-dark defaultfontsize  font-weight-bold  text-left mt-2 mb-3">
          Please read the following terms carefully before proceeding:
        </p>
        <p className="appearslowly text-dark biggestfontsize  text-left mt-2 mb-3">
          By creating your own group, you will not be allowed to join other
          groups until you leave this group.
        </p>
        <div className="text-left biggestfontsize alert alert-primary  mt-2 mb-3">
          The balloting outcome applies to all group members.
        </div>
        <p className="appearslowly text-dark biggestfontsize  text-left mt-2 mb-3">
          You are responsible for ensuring that the member's email addresses are
          correct.
        </p>
        <div className="form-group col-sm-12 text-center px-0">
          <div
            className="mx-2 text-left custom-control-lg custom-control custom-checkbox"
            style={{ zIndex: "0" }}
          >
            <input
              disabled={processing}
              className="custom-control-input"
              value={agree}
              id="agree"
              name="agree"
              type="checkbox"
              onChange={(e) => dispatch(setAgree(!agree))}
              defaultChecked={agree}
            />
            <label className="custom-control-label" htmlFor="agree">
              <span className="pointer font-weight-bold">
                I understand and agree to the terms
              </span>
            </label>
          </div>
        </div>
        <Button
          disabled={!agree || processing}
          variant={"primary"}
          className="btn-lg mt-3 px-4"
          onClick={doCreateGroup}
        >
          {!processing ? (
            <div className="mx-5 font-weight-bold">Proceed</div>
          ) : (
            <SpinnerText text={"Processing..."} />
          )}
        </Button>
        <Button
          disabled={processing}
          variant={"link"}
          className="btn-lg mt-3 biggerfontsize"
          onClick={() => dispatch(setShowModal(false))}
        >
          Go Back
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmCreateGroupModal;
