import React from "react";
import { logEvent } from "../../../services/log";

function CFBanner({ code = "" }) {
  return (
    <div className="pointer pt-0">
      <a
        href="https://www.catholicfoundation.sg/category/all-products"
        download
        target="_blank"
        rel="noopener noreferrer"
        className=""
        onClick={() => {
          logEvent(`papal_souvenirs_${code}`, { type: "CF" });
        }}
      >
        <img
          className="responsive img-fluid"
          src="https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fpapalmass%2Fpope-francis-gif.gif?alt=media&token=99df9fb9-419a-4f31-8a84-d52d87172347"
          alt=""
        />
      </a>
    </div>
  );
}

export default CFBanner;
