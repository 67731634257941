import { getSGTime } from "./utils";

export function getLocalTime() {
  return new Date().getTime();
}

export function mapToDay(value) {
  if (value < 0 || value > 7) {
    return "Sunday";
  }
  let weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";
  return weekday[value];
}

export function getWholeDaysInWeek() {
  const week = [
    "Sundays",
    "Mondays",
    "Tuesdays",
    "Wednesdays",
    "Thursdays",
    "Fridays",
    "Saturdays",
  ];
  return week;
}
export function mapDayToNum(input) {
  switch (input) {
    case "sunday":
      return 0;
    case "monday":
      return 1;
    case "tuesday":
      return 2;
    case "wednesday":
      return 3;
    case "thursday":
      return 4;
    case "friday":
      return 5;
    case "saturday":
      return 6;
    default:
      return -1;
  }
}

//month - 0 (jan), day - 0 (sun)
export function getDatesOfDayOfWeek(year, month, dayOfWeek) {
  const initialDate = new Date(year, month, 1);
  const datesOfDayOfWeek = [];
  while (initialDate.getDay() !== dayOfWeek) {
    initialDate.setDate(initialDate.getDate() + 1);
  }

  while (initialDate.getMonth() === month) {
    const nextDate = new Date(initialDate.getTime());
    datesOfDayOfWeek.push(nextDate.getDate());
    initialDate.setDate(initialDate.getDate() + 7);
  }

  return datesOfDayOfWeek;
}

export function isWeekEndMass(date) {
  const massDate = new Date(date.seconds * 1000);
  const a = getSGTime(massDate);

  const isSunday = a.getDay() === 0;
  let weekEndMass = false;
  let isConsideredSunday = false;
  //saturday 2pm onwards is considered sunday mass according to Fr Richards
  if (a.getDay() === 6 && a.getHours() >= 14) {
    isConsideredSunday = true;
  }

  if (isSunday || isConsideredSunday) {
    weekEndMass = true;
  }
  return weekEndMass;
}

export function isWeekDayMass(date) {
  return !isWeekEndMass(date);
}

export function isSaturdayMass(date) {
  let a = new Date(date.seconds * 1000);

  //if saturday
  return a.getDay() === 6;
}

export function isSundayWeekendMass(date) {
  let a = new Date(date.seconds * 1000);

  return a.getDay() === 0;
}

export function getShortMonths() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months;
}

export function isPastEvent(date) {
  const now = getSGTime().getTime();
  const eventDate = new Date(date.seconds * 1000);
  const eventtime = getSGTime(eventDate).getTime();
  if (now > eventtime) {
    return true;
  }
  return false;
}

export function isBookingClosed(date, cutoff = 1) {
  const now = getSGTime().getTime();
  const eventime = date.seconds * 1000;
  const hourstoclose = cutoff;
  const hourstoclosesec = hourstoclose * 60 * 60 * 1000;
  //NOT EXPLICITLY CLOSED, just to estimate if closed or not
  let eventclosed = false;
  if (now > eventime - hourstoclosesec) {
    eventclosed = true;
  }
  return eventclosed;
}

export function isBookingCancelClosed(date, cutoff = 1) {
  const now = getSGTime().getTime();
  const eventime = date.seconds * 1000;
  const hourstoclose = cutoff;
  const hourstoclosesec = hourstoclose * 60 * 60 * 1000;
  //NOT EXPLICITLY CLOSED, just to estimate if closed or not
  let eventclosed = false;
  if (now > eventime - hourstoclosesec) {
    eventclosed = true;
  }
  return eventclosed;
}

export function getEventDate(date) {
  const eventDate = new Date(date.seconds * 1000);
  const a = getSGTime(eventDate);
  const months = getShortMonths();
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const day = a.getDate();

  return day + " " + month + " " + year;
}

export function getHumanDate(date) {
  const a = new Date(date);
  const months = getShortMonths();
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const day = a.getDate();

  return day + " " + month + " " + year;
}

export function getEventSGDate(eventDate) {
  const a = getSGTime(eventDate);
  const months = getShortMonths();
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const day = a.getDate();

  return day + " " + month + " " + year;
}

export function convertTime12to24(time12h) {
  const [time, modifier] = time12h.split(" ");

  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier.toUpperCase() === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}

export function convertTime24to12(time24h) {
  let [hours, minutes] = time24h.split(":");

  let modifier = "AM";

  if (hours >= 12) {
    modifier = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  }

  if (hours === "00") {
    hours = "12";
  }

  return `${hours}:${minutes} ${modifier}`;
}

export function getValidSaturdayDate(date) {
  //See: https://stackoverflow.com/questions/9444745/javascript-how-to-get-tomorrows-date-in-format-dd-mm-yy/9444776
  const thisday = new Date(date.seconds * 1000);
  const yesterday = new Date(date.seconds * 1000 - 24 * 60 * 60 * 1000);
  //is same month
  if (thisday.getMonth() === yesterday.getMonth()) {
    const months = getShortMonths();
    const year = yesterday.getFullYear();
    const month = months[yesterday.getMonth()];
    const day = yesterday.getDate();

    return day + " " + month + " " + year;
  }
  return null;
}

export function getValidSundayDate(date) {
  //See: https://stackoverflow.com/questions/9444745/javascript-how-to-get-tomorrows-date-in-format-dd-mm-yy/9444776
  const thisday = new Date(date.seconds * 1000);
  const nextday = new Date(date.seconds * 1000 + 24 * 60 * 60 * 1000);
  //is same month
  if (thisday.getMonth() === nextday.getMonth()) {
    const months = getShortMonths();
    const year = nextday.getFullYear();
    const month = months[nextday.getMonth()];
    const day = nextday.getDate();

    return day + " " + month + " " + year;
  }
  return null;
}

export function getStartEndofMonth(preferredMonth, preferredYear) {
  const actualdate = `${preferredMonth} 01 ${preferredYear}`;
  let date = new Date(actualdate);
  let y = date.getFullYear();
  let m = date.getMonth();
  let firstDay = new Date(y, m, 1).getTime() / 1000;
  let lastDay = new Date(y, m + 1, 0, 23, 59, 59).getTime() / 1000;
  return [firstDay, lastDay];
}

export function convertYearMonth(input) {
  //input is 2021-01
  const year = parseInt(input.substr(0, 4));
  const month = parseInt(input.substr(5, 6));
  return [year, month];
}

export function convertYearMonthToStartEndofMonth(label) {
  /* Format 2021-01
   ** Means Jan 2021
   */
  //convert label to timestamp
  let [year, month] = convertYearMonth(label);
  //convert month
  const months = getShortMonths();
  month = months[month - 1];
  console.log(month);
  const [firstDay, lastDay] = getStartEndofMonth(month, year);
  return [firstDay, lastDay];
}

export function isValidYearMonth(input) {
  /*
   ** This accepts 2020-01 format
   */
  const year = input.substr(0, 4);
  const month = input.substr(5, 6);
  if (
    year.length === 4 &&
    parseInt(year) >= 2020 &&
    month.length === 2 &&
    parseInt(month) > 0 &&
    parseInt(month) < 13
  ) {
    return true;
  }
  return false;
}

export function getNextBookingDays(first = 1, last = 2) {
  //get the next 2 days from today, make sure don't go beyond next month
  const date = getSGTime();
  // const date = getSGTime(new Date("March 1, 2022"));
  const firstDay = new Date(date);
  firstDay.setDate(date.getDate() + first);

  const secondDay = new Date(date);
  secondDay.setDate(date.getDate() + last);

  const start =
    new Date(
      firstDay.getFullYear(),
      firstDay.getMonth(),
      firstDay.getDate(),
      0,
      0,
      0
    ).getTime() / 1000;
  const end =
    new Date(
      secondDay.getFullYear(),
      secondDay.getMonth(),
      secondDay.getDate(),
      23,
      59,
      59
    ).getTime() / 1000;
  return [start, end];
}

export function getFirstLastDay(month, year, option = 0) {
  // Generates time based on UTC and subtracts +8 GMT Offset to derive SG Time
  // option 0: firstDay(default), 1: lastDay
  const firstDay = new Date(Date.UTC(year, month, 1, 0, 0, 0, 0));
  const lastDay = new Date(Date.UTC(year, month + 1, 0, 23, 59, 59));
  firstDay.setHours(firstDay.getHours() - 8);
  lastDay.setHours(lastDay.getHours() - 8);
  if (option) {
    return lastDay;
  }
  return firstDay;
}

export function getLastDayOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function getFirstDayOfMonth() {
  const date = new Date();
  const value = new Date(date.getFullYear(), date.getMonth());
  return value;
}

export function getFirstDayOfNextMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 1);
}

export function getTotalDaysInMonth(curDate = new Date()) {
  const date = new Date(curDate.getFullYear(), curDate.getMonth(), 1);

  // Move to the next month and subtract one day to get the last day of the current month
  date.setMonth(date.getMonth() + 1);
  date.setDate(date.getDate() - 1);

  // The date now holds the last day of the specified month
  return date.getDate();
}

export function getEventTimeAMPM(date) {
  const d = new Date(date);
  // Hours part from the timestamp
  let hours = d.getHours();
  // Minutes part from the timestamp

  let minutes = d.getMinutes();
  if (minutes < 10) {
    // minutes += "0";
    minutes = `0${minutes}`;
  }

  hours = (hours + 24) % 24;
  let mid = "am";
  if (hours === 0) {
    //At 00 hours we need to show 12 am
    hours = 12;
  } else if (hours > 12) {
    hours = hours % 12;
    mid = "pm";
  } else if (hours === 12) {
    hours = 12;
    mid = "pm";
  }

  return `${hours}:${minutes}${mid}`;
}

// Takes two date objects and check if its the same day;
export function isSameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function generateMapIDFromEntry(entry) {
  const dayOfMonth = new Date(entry.date.seconds * 1000).getDate();
  const whichDay = Math.ceil(dayOfMonth / 7);

  const whichDate = entry.labelday.toLowerCase();
  return [whichDay, whichDate];
}

export function timestampToHumanDate(ts) {
  const date = new Date(ts).toDateString();
  const time = new Date(ts).toTimeString().split(" ");
  return `${date}, ${time[0]}`;
}

export function isSameYearMonth(date1, date2) {
  if (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth()
  ) {
    return true;
  }
  return false;
}

export function convertToDate(dateString) {
  //  Convert a "dd/MM/yyyy" string into a Date object
  let d = dateString.split("/");
  let dat = new Date(d[2] + "/" + d[1] + "/" + d[0]);
  return dat;
}

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function getStartOfDay(date = new Date()) {
  date.setHours(0, 0, 0);
  return date;
}
export function getSGTimestamp(date = new Date()) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  const sg = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
    timeZone: "Asia/Singapore",
  }).format(date);
  let out = sg.split("at"); //for some reason, it is not working with "at"
  if (out?.length !== 2) {
    out = sg.split(",");
  }
  return new Date(out[0] + out[1]).getTime();
}
