import React, { useState, useEffect, useContext } from "react";
import fire from "../../../services/fire";
import { isSuperAdminRole } from "../../roles/service/v2/roles";
import { useHistory } from "react-router-dom";
import AppGlobalContext from "../../../AppGlobalContext";
import Header from "../../common/header";
import LoadingSpinner from "../../common/loadingSpinner";
import { Button, Modal } from "react-bootstrap";
import { getParishes } from "../../../services/parish";
import LabelLegend from "../../stats/component/labelLegend";
import { PieChart } from "react-minimal-pie-chart";
import { CSVLink } from "react-csv";
import { Tab, Tabs } from "react-bootstrap";
import Table from "../../common/table";
import Input from "../../common/input";
import { getCurrentUser } from "../../../services/user";

const BAL_REG_COL = "ballotregistration";
const FIRST_BAL_RESULTS_COL = `${BAL_REG_COL}results/results/firstballot`;
const SECOND_BAL_RESULTS_COL = `${BAL_REG_COL}results/results/secondballot`;

const YOUNG_INDIVIDUAL = 2;
const FOREIGNER = 3;
const MAJORITY_NON_RESIDENT = 4;
const MAJORITY_NON_PARISH = 5;
const ALL_NC = 6;
const FOREIGN_TELEPHONE = 7;
const YOUNG_GROUP = 8;
const MAJORITY_FOREIGN_TELEPHONE = 9;
const STATUSES = [
  YOUNG_INDIVIDUAL,
  FOREIGNER,
  MAJORITY_NON_RESIDENT,
  MAJORITY_NON_PARISH,
  ALL_NC,
  FOREIGN_TELEPHONE,
  YOUNG_GROUP,
  MAJORITY_FOREIGN_TELEPHONE,
];
const INITIAL_REJECTED = {};
STATUSES.forEach((status) => {
  INITIAL_REJECTED[status] = 0;
});

// const DOCTYPE_FLAGGED = "flagged";
const DOCTYPE_WINNERS = "winners";
// const DOCTYPE_RESULTS = "results";

const SECOND_BALLOT_PARISH_VALUE = "Second Ballot";

const BALLOT_SUCCESS = "3";

const PARISH_ORDER = [
  "27", // St Michael
  "31", // Sts Peter and Paul
  "16", // Sacred Heart
  "25", // St Joseph (Victoria Street)
  "28", // St Stephen
  "1", // Blessed Sacrament Church
  "2", // Cathedral
  "10", // Lady of Lourdes
  "20", // St Bernadette
  "29", // St Teresa
  "13", // Our Lady Queen of Peace
  "21", // St Francis of Assisi
  "4", // Divine Mercy
  "19", // St Anthony
  "24", // St Joseph (Bukit Timah)
  "22", // St Francis Xavier
  "30", // St Vincent de Paul
  "11", // Nativity of the Blessed Virgin Mary
  "32", // Transfiguration
  "12", // Our Lady of Perpetual Succour
  "6", // Holy Family
  "14", // Our Lady Star of the Sea
  "7", // Holy Spirit
  "9", // Immaculate Heart of Mary
  "23", // St Ignatius
  "5", // Holy Cross
  "17", // St Alphonsus (Novena Church)
  "8", // Holy Trinity
  "3", // Christ the King
  "15", // Risen Christ
  "18", // St Anne
  "26", // St Mary of the Angels
];

const SUCCESSFUL_TABLE_COLUMNS = [
  { Header: "Id", accessor: "id", minWidth: 200, canResize: true },
  { Header: "UserId", accessor: "userid", minWidth: 200, canResize: true },
  { Header: "Name", accessor: "name", minWidth: 200, canResize: true },
  { Header: "Email", accessor: "email", minWidth: 200, canResize: true },
  { Header: "Weight", accessor: "weight", canResize: true },
  { Header: "DOB", accessor: "dob", canResize: true },
  { Header: "Catholic", accessor: "catholic", canResize: true },
  { Header: "Citizenship", accessor: "citizenship", canResize: true },
  { Header: "Parish", accessor: "parish", canResize: true },
  { Header: "Balloting Parish", accessor: "ballotingparish", canResize: true },
  { Header: "Created", accessor: "created", canResize: true },
  { Header: "Role", accessor: "role", canResize: true },
  { Header: "GroupId", accessor: "groupid", canResize: true },
  { Header: "Unaffiliated", accessor: "unaffiliated", canResize: true },
  { Header: "Mobile", accessor: "mobile", canResize: true },
];

const Balloting = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(AppGlobalContext);
  const [parish, setParish] = useState(SECOND_BALLOT_PARISH_VALUE);
  const [stats, setStats] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [migrationData, setMigrationData] = useState([]);
  const [successfulData, setSuccessfulData] = useState([]);

  const combineObjectStats = (acc, curr) => {
    Object.keys(acc).forEach((key) => (acc[key] += curr[key] || 0));
    return acc;
  };

  const sumDict = (dict) => {
    return Object.values(dict).reduce((a, b) => a + b, 0);
  };

  const reduceStats = (stats) => {
    return stats.reduce(
      (acc, curr) => {
        return {
          numBallots: acc.numBallots + curr.numBallots,
          counts: combineObjectStats(acc.counts, curr.counts),
          ageGroups: acc.ageGroups.map((group, index) => {
            return {
              title: group.title,
              value: group.value + curr.ageGroups[index].value,
              color: group.color,
            };
          }),
          numBallotCatholics: acc.numBallotCatholics + curr.numBallotCatholics,
          numBallotNonCatholics:
            acc.numBallotNonCatholics + curr.numBallotNonCatholics,
          numSingaporean: acc.numSingaporean + curr.numSingaporean,
          numPR: acc.numPR + curr.numPR,
          numFIN: acc.numFIN + curr.numFIN,
          numVisitor: acc.numVisitor + curr.numVisitor,
          numUnknown: acc.numUnknown + curr.numUnknown,
          invalidCount: acc.invalidCount + curr.invalidCount,
        };
      },
      {
        numBallots: 0,
        counts: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 },
        ageGroups: [
          {
            title: "Below 13",
            value: 0,
            color: "#007bff",
          },
          {
            title: "13 to 19",
            value: 0,
            color: "#a0a0a0",
          },
          {
            title: "20 to 35",
            value: 0,
            color: "#dc3545",
          },
          {
            title: "36 to 50",
            value: 0,
            color: "#28a745",
          },
          {
            title: "51 to 65",
            value: 0,
            color: "#17a2b8",
          },
          {
            title: "65 above",
            value: 0,
            color: "#ffc107",
          },
        ],
        numBallotCatholics: 0,
        numBallotNonCatholics: 0,
        numSingaporean: 0,
        numPR: 0,
        numFIN: 0,
        numVisitor: 0,
        numUnknown: 0,
        invalidCount: 0,
      }
    );
  };

  const getCitizenship = (citizenship) => {
    if (!citizenship) return "Old Account";
    const citizenships = ["Singaporean", "PR", "FIN / Workpass", "Overseas"];
    return citizenships[citizenship - 1];
  };

  const getParish = (parishId) => {
    const parishes = getParishes();
    return parishes.find((parish) => `${parish._id}` === parishId).name;
  };

  const getStats = async () => {
    console.log("Getting stats from first ballot...");

    const querySnapshot = await fire
      .firestore()
      .collection(FIRST_BAL_RESULTS_COL)
      .get();
    const docs = querySnapshot.docs.map((doc) => doc.data());

    console.log("Getting stats from second ballot...");

    const secondBallotDoc = await fire
      .firestore()
      .collection(SECOND_BAL_RESULTS_COL)
      .doc("0")
      .get();
    if (secondBallotDoc.exists) {
      const secondBallotWinnersSnapshot = await fire
        .firestore()
        .collection(SECOND_BAL_RESULTS_COL)
        .where("docType", "==", DOCTYPE_WINNERS)
        .get();

      let winnersList = [];
      if (!secondBallotWinnersSnapshot.empty) {
        for (const winnersDoc of secondBallotWinnersSnapshot.docs) {
          const data = winnersDoc.data();
          if (data.winners) {
            winnersList = winnersList.concat(JSON.parse(data.winners));
          }
        }
      }

      docs.push({
        ...secondBallotDoc.data(),
        winnersList: JSON.stringify(winnersList),
      });
    }

    console.log("Proccesing stats...");

    const docData = docs.map((data) => {
      const winnersList = JSON.parse(data.winnersList).map((winner) => {
        return {
          ...winner,
          catholic: winner.catholic ? "Yes" : "No",
          citizenship: getCitizenship(winner.citizenship),
          created: new Date(winner.created).toLocaleString(),
          parish: getParish(winner.parish),
          ballotingparish: getParish(winner.ballotingparish),
          role: winner.role === "" ? "Individual" : winner.role,
          groupid: winner.groupid === "" ? "-" : winner.groupid,
          unaffiliated: winner.unaffiliated ? "Yes" : "No",
        };
      });
      return {
        ...data,
        winnersList,
      };
    });

    // Aggregate stats
    const all = {
      id: "-1",
      name: "All",
      numSeats: docData.reduce((acc, curr) => acc + curr.numSeats, 0),
      winnersList: docData.reduce(
        (acc, curr) => acc.concat(curr.winnersList),
        []
      ),
      parishionerStats: reduceStats(docData.map((doc) => doc.parishionerStats)),
      winnersStats: reduceStats(docData.map((doc) => doc.winnersStats)),
      ballotStats: reduceStats(docData.map((doc) => doc.ballotStats)),
      segmentNumbers: docData
        .map((doc) => doc.segmentNumbers)
        .reduce(combineObjectStats, {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
        }),
      segmentAllocation: docData
        .map((doc) => doc.segmentAllocation)
        .reduce(combineObjectStats, {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
        }),
      segmentRejected: docData
        .map((doc) => doc.segmentRejected)
        .reduce(combineObjectStats, {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
        }),
      flaggedCounts: docData
        .map((doc) => doc.flaggedCounts)
        .reduce(combineObjectStats, INITIAL_REJECTED),
    };
    const newStats = [all];
    PARISH_ORDER.forEach((parishId) => {
      const parish = docData.find((doc) => doc.id === parishId);
      if (parish) {
        newStats.push(parish);
      }
    });

    const secondBallot = docData.find((doc) => doc.name === "Second Ballot");
    if (secondBallot) {
      newStats.push(secondBallot);
    }

    setStats(newStats);

    console.log("Finished getting stats!");
  };

  const getStatsCharts = (title, statsObj) => {
    return (
      <div className="col-md d-flex flex-row">
        <div className="col-md d-flex flex-column">
          <h6 className="mb-2 mt-4">{`${title} Age Stats`}</h6>
          <div className="card">
            <div className="card-body">
              <div className="col-12 px-0">
                <h5 className="text-muted">% Distribution</h5>
              </div>
              <div className="float-left col-4 px-0 mx-0">
                <LabelLegend
                  color="bg-primary"
                  label={`Below 13 (${statsObj.counts[1]})`}
                />
                <LabelLegend
                  color="bg-secondary"
                  label={`13 to 19 (${statsObj.counts[2]})`}
                />
                <LabelLegend
                  color="bg-danger"
                  label={`20 to 35 (${statsObj.counts[3]})`}
                />
                <LabelLegend
                  color="bg-success"
                  label={`36 to 50 (${statsObj.counts[4]})`}
                />
                <LabelLegend
                  color="bg-info"
                  label={`51 to 65 (${statsObj.counts[5]})`}
                />
                <LabelLegend
                  color="bg-warning"
                  label={`65 above (${statsObj.counts[6]})`}
                />
              </div>
              <div className="float-right col-8 px-0 mx-0">
                <PieChart
                  animation
                  animationDuration={500}
                  animationEasing="ease-out"
                  labelPosition={65}
                  lengthAngle={360}
                  paddingAngle={0}
                  animate
                  startAngle={0}
                  viewBoxSize={[100, 100]}
                  className="p-0"
                  labelStyle={{
                    fontSize: "6px",
                    fontColor: "#FFFFFA",
                  }}
                  label={({ dataEntry }) =>
                    `${Math.round(dataEntry.percentage)}%`
                  }
                  data={statsObj.ageGroups}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md d-flex flex-column">
          <h6 className="mb-2 mt-4">{`${title} Catholicity`}</h6>
          <div className="card">
            <div className="card-body">
              <div className="col-12 px-0">
                <h5 className="text-muted">% Distribution</h5>
              </div>
              <div className="float-left col-4 px-0 mx-0">
                <LabelLegend
                  color="bg-primary"
                  label={`Catholic (${statsObj.numBallotCatholics})`}
                />
                <LabelLegend
                  color="bg-secondary"
                  label={`Non-Catholic (${statsObj.numBallotNonCatholics})`}
                />
              </div>
              <div className="float-right col-8 px-0 mx-0">
                <PieChart
                  animation
                  animationDuration={500}
                  animationEasing="ease-out"
                  labelPosition={65}
                  lengthAngle={360}
                  paddingAngle={0}
                  animate
                  startAngle={0}
                  viewBoxSize={[100, 100]}
                  className="p-0"
                  labelStyle={{
                    fontSize: "6px",
                    fontColor: "#FFFFFA",
                  }}
                  label={({ dataEntry }) =>
                    `${Math.round(dataEntry.percentage)}%`
                  }
                  data={[
                    {
                      title: "Catholic",
                      value: statsObj.numBallotCatholics,
                      color: "#007bff",
                    },
                    {
                      title: "Non-Catholic",
                      value: statsObj.numBallotNonCatholics,
                      color: "#dc3545",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md d-flex flex-column">
          <h6 className="mb-2 mt-4">{`${title} Citizenship`}</h6>
          <div className="card">
            <div className="card-body">
              <div className="col-12 px-0">
                <h5 className="text-muted">% Distribution</h5>
              </div>
              <div className="float-left col-4 px-0 mx-0">
                <LabelLegend
                  color="bg-primary"
                  label={`Sinagporean (${statsObj.numSingaporean})`}
                />
                <LabelLegend
                  color="bg-secondary"
                  label={`PR (${statsObj.numPR})`}
                />
                <LabelLegend
                  color="bg-danger"
                  label={`FIN / Workpass (${statsObj.numFIN})`}
                />
                <LabelLegend
                  color="bg-success"
                  label={`Overseas (${statsObj.numVisitor})`}
                />
                <LabelLegend
                  color="bg-info"
                  label={`Unknown (${statsObj.numUnknown})`}
                />
              </div>
              <div className="float-right col-8 px-0 mx-0">
                <PieChart
                  animation
                  animationDuration={500}
                  animationEasing="ease-out"
                  labelPosition={65}
                  lengthAngle={360}
                  paddingAngle={0}
                  animate
                  startAngle={0}
                  viewBoxSize={[100, 100]}
                  className="p-0"
                  labelStyle={{
                    fontSize: "6px",
                    fontColor: "#FFFFFA",
                  }}
                  label={({ dataEntry }) =>
                    `${Math.round(dataEntry.percentage)}%`
                  }
                  data={[
                    {
                      title: "Singaporean",
                      value: statsObj.numSingaporean,
                      color: "#007bff",
                    },
                    {
                      title: "PR",
                      value: statsObj.numPR,
                      color: "#a0a0a0",
                    },
                    {
                      title: "FIN / Workpass",
                      value: statsObj.numFIN,
                      color: "#dc3545",
                    },
                    {
                      title: "Overseas",
                      value: statsObj.numVisitor,
                      color: "#28a745",
                    },
                    {
                      title: "Old Account",
                      value: statsObj.numUnknown,
                      color: "#17a2b8",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getGroupCharts = (statsObj) => {
    const getChart = (numbers) => {
      return (
        <div className="card">
          <div className="card-body">
            <div className="col-12 px-0">
              <h5 className="text-muted">% Distribution</h5>
            </div>
            <div className="float-left col-4 px-0 mx-0">
              <LabelLegend
                color="bg-primary"
                label={`Individual Balloters (${numbers[1] || 0} people)`}
              />
              <LabelLegend
                color="bg-secondary"
                label={`Groups of 2 (${numbers[2] || 0} people)`}
              />
              <LabelLegend
                color="bg-danger"
                label={`Groups of 3 (${numbers[3] || 0} people)`}
              />
              <LabelLegend
                color="bg-success"
                label={`Groups of 4 (${numbers[4] || 0} people)`}
              />
              <LabelLegend
                color="bg-info"
                label={`Groups of 5 (${numbers[5] || 0} people)`}
              />
              <LabelLegend
                color="bg-warning"
                label={`Groups of 6 (${numbers[6] || 0} people)`}
              />
            </div>
            <div className="float-right col-8 px-0 mx-0">
              <PieChart
                animation
                animationDuration={500}
                animationEasing="ease-out"
                labelPosition={65}
                lengthAngle={360}
                paddingAngle={0}
                animate
                startAngle={0}
                viewBoxSize={[100, 100]}
                className="p-0"
                labelStyle={{
                  fontSize: "6px",
                  fontColor: "#FFFFFA",
                }}
                label={({ dataEntry }) =>
                  `${Math.round(dataEntry.percentage)}%`
                }
                data={[
                  {
                    title: "Individual",
                    value: numbers[1] || 0,
                    color: "#007bff",
                  },
                  {
                    title: "2",
                    value: numbers[2] || 0,
                    color: "#a0a0a0",
                  },
                  {
                    title: "3",
                    value: numbers[3] || 0,
                    color: "#dc3545",
                  },
                  {
                    title: "4",
                    value: numbers[4] || 0,
                    color: "#28a745",
                  },
                  {
                    title: "5",
                    value: numbers[5] || 0,
                    color: "#17a2b8",
                  },
                  {
                    title: "6",
                    value: numbers[6] || 0,
                    color: "#ffc107",
                  },
                ]}
              />
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="col-md d-flex flex-row">
        <div className="col-md d-flex flex-column">
          <h6 className="mb-2 mt-4">Distribution of Balloters by Group Size</h6>
          {getChart(statsObj.segmentNumbers)}
        </div>
        <div className="col-md d-flex flex-column">
          <h6 className="mb-2 mt-4">
            Distribution of Allocations by Group Size
          </h6>
          {getChart(statsObj.segmentAllocation)}
        </div>
        <div className="col-md d-flex flex-column">
          <h6 className="mb-2 mt-4">Distribution of Rejected by Group Size</h6>
          {getChart(statsObj.segmentRejected)}
        </div>
      </div>
    );
  };

  const getParishBreakdown = (parish) => {
    const numBallots = parish.ballotStats.numBallots;
    const numRejected = sumDict(parish.segmentRejected);

    const balloted = [parish.name, numBallots, numRejected, "Balloted"];
    const removed = [parish.name, "", "", "Removed"];
    const allocated = [parish.name, "", "", "Allocated"];

    [1, 2, 3, 4, 5, 6].forEach((groupSize) => {
      const segmentSize = parish.segmentNumbers[groupSize] || 0;
      const rejectedNumber = parish.segmentRejected[groupSize] || 0;
      let allocatedNumber = parish.segmentAllocation[groupSize] || 0;

      const ballotedNumber = segmentSize + rejectedNumber;
      if (groupSize !== 1) {
        balloted.push(ballotedNumber / groupSize);
      }
      balloted.push(ballotedNumber);

      if (groupSize !== 1) {
        removed.push(rejectedNumber / groupSize);
      }
      removed.push(rejectedNumber);

      // allocatedNumber = Math.min(allocatedNumber, segmentSize);
      if (groupSize !== 1) {
        allocated.push(allocatedNumber / groupSize);
      }
      allocated.push(allocatedNumber);
    });

    return [balloted, removed, allocated];
  };

  const getMigrationData = async () => {
    const db = fire.firestore().collection(BAL_REG_COL);
    const data = [
      [
        "Parish",
        "Total",
        "Unregistered",
        "Invited",
        "Registered",
        "Successful",
        "Withdrawn",
        "Volunteers",
        "firstballotseen false",
        "firstballotpass true",
        "firstballotpass false",
        "secondballotseen false",
        "secondballotpass true",
        "secondballotpass false",
      ],
    ];

    const totalSnapshot = await db.get();

    const unregisteredSnapshot = await db
      .where("ballotstatus", "==", "0")
      .get();

    const invitedSnapshot = await db.where("ballotstatus", "==", "1").get();

    const registeredSnapshot = await db.where("ballotstatus", "==", "2").get();

    const successfulSnapshot = await db.where("ballotstatus", "==", "3").get();

    const withdrawnSnapshot = await db.where("ballotstatus", "==", "5").get();

    const volunteerSnapshot = await db.where("ballotstatus", "==", "10").get();

    const firstballotseenFalseSnapshot = await db
      .where("firstballotseen", "==", false)
      .get();

    const firstballotpassTrueSnapshot = await db
      .where("firstballotpass", "==", true)
      .get();

    const firstballotpassFalseSnapshot = await db
      .where("firstballotpass", "==", false)
      .get();

    const secondballotseenFalseSnapshot = await db
      .where("secondballotseen", "==", false)
      .get();

    const secondballotpassTrueSnapshot = await db
      .where("secondballotpass", "==", true)
      .get();

    const secondballotpassFalseSnapshot = await db
      .where("secondballotpass", "==", false)
      .get();

    data.push([
      "All",
      totalSnapshot.size,
      unregisteredSnapshot.size,
      invitedSnapshot.size,
      registeredSnapshot.size,
      successfulSnapshot.size,
      withdrawnSnapshot.size,
      volunteerSnapshot.size,
      firstballotseenFalseSnapshot.size,
      firstballotpassTrueSnapshot.size,
      firstballotpassFalseSnapshot.size,
      secondballotseenFalseSnapshot.size,
      secondballotpassTrueSnapshot.size,
      secondballotpassFalseSnapshot.size,
    ]);

    for (const parishId of PARISH_ORDER) {
      const totalSnapshot = await db
        .where("ballotingparish", "==", parishId)
        .get();

      const unregisteredSnapshot = await db
        .where("ballotstatus", "==", "0")
        .where("ballotingparish", "==", parishId)
        .get();

      const invitedSnapshot = await db
        .where("ballotstatus", "==", "1")
        .where("ballotingparish", "==", parishId)
        .get();

      const registeredSnapshot = await db
        .where("ballotstatus", "==", "2")
        .where("ballotingparish", "==", parishId)
        .get();

      const successfulSnapshot = await db
        .where("ballotstatus", "==", "3")
        .where("ballotingparish", "==", parishId)
        .get();

      const withdrawnSnapshot = await db
        .where("ballotstatus", "==", "5")
        .where("ballotingparish", "==", parishId)
        .get();

      const volunteerSnapshot = await db
        .where("ballotstatus", "==", "10")
        .where("ballotingparish", "==", parishId)
        .get();

      const firstballotseenFalseSnapshot = await db
        .where("firstballotseen", "==", false)
        .where("ballotingparish", "==", parishId)
        .get();

      const firstballotpassTrueSnapshot = await db
        .where("firstballotpass", "==", true)
        .where("ballotingparish", "==", parishId)
        .get();

      const firstballotpassFalseSnapshot = await db
        .where("firstballotpass", "==", false)
        .where("ballotingparish", "==", parishId)
        .get();

      const secondballotseenFalseSnapshot = await db
        .where("secondballotseen", "==", false)
        .where("ballotingparish", "==", parishId)
        .get();

      const secondballotpassTrueSnapshot = await db
        .where("secondballotpass", "==", true)
        .where("ballotingparish", "==", parishId)
        .get();

      const secondballotpassFalseSnapshot = await db
        .where("secondballotpass", "==", false)
        .where("ballotingparish", "==", parishId)
        .get();

      data.push([
        getParish(parishId),
        totalSnapshot.size,
        unregisteredSnapshot.size,
        invitedSnapshot.size,
        registeredSnapshot.size,
        successfulSnapshot.size,
        withdrawnSnapshot.size,
        volunteerSnapshot.size,
        firstballotseenFalseSnapshot.size,
        firstballotpassTrueSnapshot.size,
        firstballotpassFalseSnapshot.size,
        secondballotseenFalseSnapshot.size,
        secondballotpassTrueSnapshot.size,
        secondballotpassFalseSnapshot.size,
      ]);
    }

    setMigrationData(data);
  };

  const getSuccessful = async () => {
    const querySnapshot = await fire
      .firestore()
      .collection(BAL_REG_COL)
      .where("ballotstatus", "==", BALLOT_SUCCESS)
      .get();
    const docs = querySnapshot.docs;
    const docData = docs.map((doc) => {
      const data = doc.data();
      return {
        Id: doc.id,
        Userid: data.userid,
        Name: data.name,
        Email: data.email,
        DOB: data.dob,
        Catholic: data.catholic ? "Yes" : "No",
        Citizenship: getCitizenship(data.citizenship),
        Parish: getParish(data.parish),
        "Balloting Parish": getParish(data.ballotingparish),
        Created: new Date(data.created).toLocaleString(),
        Role: data.role === "" ? "Individual" : data.role,
        GroupId:  data.groupid === "" ? "-" : data.groupid,
        Unaffiliated: data.unaffiliated ? "Yes" : "No",
        Mobile: data.mobile || "-",
        "First Ballot Pass": data.firstballotpass ? "Yes" : "No",
        "Second Ballot Pass": data.secondballotpass ? "Yes" : "No",
      };
    });
    setSuccessfulData(docData);
  };

  useEffect(() => {
    const checkRoleAndFetchStats = async () => {
      if (user) {
        const curUser = await getCurrentUser();
        const isSuperAdmin = await isSuperAdminRole(curUser);
        if (isSuperAdmin) {
          await getStats();
          setIsLoading(false);
          getSuccessful().then();
        } else {
          history.push("/home");
        }
      } else {
        history.push("/home");
      }
    };

    checkRoleAndFetchStats().then();
    getMigrationData().then();
  }, [user, history]);

  return (
    <>
      <Header type="backandlogo" smallpic={user.photoUrl} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="col-md">
            <h3 className="mb-4 mt-4 ml-3">PFS Balloting</h3>
            <div className="row px-2">
              <div className="ml-4">
                <select
                  className="form-control"
                  value={parish}
                  onChange={(e) => setParish(e.target.value)}
                  disabled={true}
                >
                  {/*<option value="0">All</option>*/}
                  {/*<option value="-1">Remaining</option>*/}
                  {/*{getParishes().map((parish) => (*/}
                  {/*  <option key={parish._id} value={`${parish._id}`}>*/}
                  {/*    {parish.name}*/}
                  {/*  </option>*/}
                  {/*))}*/}
                  <option value={SECOND_BALLOT_PARISH_VALUE}>
                    Second Ballot
                  </option>
                </select>
              </div>
              <div className="ml-4">
                <Button
                  variant="success"
                  disabled={true}
                  // onClick={async () => {
                  //   const runBalloting = fire
                  //     .functions("asia-east2")
                  //     .httpsCallable("runBalloting", { timeout: 540000 });
                  //   setDisabled(true);
                  //   if (parish === SECOND_BALLOT_PARISH_VALUE) {
                  //     console.log(`Running balloting for second ballot`);
                  //     await runBalloting();
                  //     await getStats();
                  //     console.log(`Finished balloting for second ballot`);
                  //   }
                  //   setDisabled(false);
                  // }}
                  // onClick={async () => {
                  //   const runBalloting = fire
                  //     .functions("asia-east2")
                  //     .httpsCallable("runBalloting", { timeout: 540000 });
                  //   setDisabled(true);
                  //   if (parish === SECOND_BALLOT_PARISH_VALUE) {
                  //     console.log(`Running balloting for second ballot`);
                  //     await runBalloting();
                  //     await getStats();
                  //     console.log(`Finished balloting for second ballot`);
                  //   }
                  //   setDisabled(false);
                  // }}
                  // onClick={async () => {
                  //   const runBalloting = fire.functions("asia-east2").httpsCallable("runBalloting");
                  //   setDisabled(true);

                  //   if (parish === "-1") {
                  //     for (const parishId of getParishes()) {
                  //       // Check that parish has not been run yet
                  //       if (stats.find((stat) => stat.id === `${parishId._id}`)) {
                  //         continue;
                  //       }
                  //       console.log(`Running balloting for ${parishId.name}`);
                  //       await runBalloting({ parish: `${parishId._id}` });
                  //       await getStats();
                  //       console.log(`Finished balloting for ${parishId.name}`);
                  //     }
                  //   } else if (parish === "0") {
                  //     for (const parishId of getParishes()) {
                  //       console.log(`Running balloting for ${parishId.name}`);
                  //       await runBalloting({ parish: `${parishId._id}` });
                  //       await getStats();
                  //       console.log(`Finished balloting for ${parishId.name}`);
                  //     }
                  //   } else {
                  //     console.log(`Running balloting for ${getParish(parish)}`);
                  //     await runBalloting({ parish: parish });
                  //     await getStats();
                  //     console.log(`Finished balloting for ${getParish(parish)}`);
                  //   }
                  //   setDisabled(false);
                  // }}
                >
                  Run Balloting
                </Button>
              </div>
              <div className="ml-2">
                <Button
                  variant="success"
                  // disabled={disabled}
                  disabled={true}
                  onClick={() => setShow(true)}
                >
                  Migrate Results
                </Button>
              </div>
            </div>
            <>
              <Modal
                show={show}
                backdrop="static"
                keyboard={false}
                onHide={() => setShow(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <div className="pl-1 largefontsize">
                      Are you sure you want to migrate?
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className="appearslowly text-dark defaultfontsize text-left mt-2 mb-3">
                    Type <b>MIGRATE</b> below if you are absolutely sure you
                    wish to proceed{" "}
                  </p>
                  <Input
                    disabled={disabled}
                    autoFocus={true}
                    className="pl-0 pb-0 mt-2 mb-3 text-center"
                    type="text"
                    name={`email`}
                    value={confirmText}
                    label=""
                    placeholder=""
                    onChange={(e) => setConfirmText(e.target.value)}
                  />
                  <div className="d-flex justify-content-between">
                    <Button variant="danger" onClick={() => setShow(false)}>
                      Cancel
                    </Button>
                    <Button
                      variant="success"
                      disabled={disabled || confirmText !== "MIGRATE"}
                      onClick={async () => {
                        const updateResults = fire
                          .functions("asia-east2")
                          .httpsCallable("updateResults", { timeout: 540000 });
                        setDisabled(true);
                        setConfirmText("");
                        if (parish === SECOND_BALLOT_PARISH_VALUE) {
                          console.log(`Updating results for second ballot`);
                          const result = await updateResults();
                          console.log(result?.data?.message);
                        }
                        setDisabled(false);
                        setShow(false);
                      }}
                      // onClick={async () => {
                      //   const updateResults = fire
                      //     .functions("asia-east2")
                      //     .httpsCallable("updateResults");
                      //   setDisabled(true);
                      //   setConfirmText("");
                      //   if (parish !== "0") {
                      //     console.log(`Updating results for ${parish}`);
                      //     const result = await updateResults({
                      //       parish: parish,
                      //     });
                      //     console.log(result?.data?.message);
                      //   } else {
                      //     for (const parish of getParishes()) {
                      //       if (
                      //         !stats.find((stat) => stat.id === `${parish._id}`)
                      //       ) {
                      //         continue;
                      //       }
                      //       console.log(`Updating results for ${parish.name}`);
                      //       const result = await updateResults({
                      //         parish: `${parish._id}`,
                      //       });
                      //       console.log(result?.data?.message);
                      //     }
                      //   }
                      //   setDisabled(false);
                      //   setShow(false);
                      // }}
                    >
                      Migrate Results
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </>
            <div className="row px-2">
              <Tabs className="ml-4 mt-4 mb-2">
                {stats.map((parish) => (
                  <Tab key={parish.id} eventKey={parish.id} title={parish.name}>
                    <div className="ml-4 mt-4 mb-2">
                      <h4>{parish.name}</h4>
                      <h6 className="mb-0">Band size: {parish.numSeats}</h6>
                      <h6 className="mb-0">
                        Seats Balloted: {parish.winnersList.length}
                      </h6>
                      {/*<h6 className="mb-0">Seats Waitlisted: {parish.waitlistList.length}</h6>*/}
                      <h6 className="mb-0">
                        Parishioners Participated:{" "}
                        {parish.parishionerStats.numBallots}
                      </h6>
                      <h6 className="mb-0">
                        Number Balloted Under this Parish:{" "}
                        {parish.ballotStats.numBallots}
                      </h6>

                      <h5 className="mb-0 mt-2">
                        Number of Flagged Ballots Under this Parish
                      </h5>
                      <h6 className="mb-0">
                        Total: {sumDict(parish.flaggedCounts) || "-"}
                      </h6>
                      <h6 className="mb-0">
                        Foreign individuals:{" "}
                        {parish.flaggedCounts[FOREIGNER] || "-"}
                      </h6>
                      <h6 className="mb-0">
                        Individuals with Foreign Telephones:{" "}
                        {parish.flaggedCounts[FOREIGN_TELEPHONE] || "-"}
                      </h6>
                      <h6 className="mb-0">
                        Individuals 11 and under:{" "}
                        {parish.flaggedCounts[YOUNG_INDIVIDUAL] || "-"}
                      </h6>
                      <h6 className="mb-0">
                        Individuals in groups with majority non-residents:{" "}
                        {parish.flaggedCounts[MAJORITY_NON_RESIDENT] || "-"}
                      </h6>
                      <h6 className="mb-0">
                        Individuals in groups with majority non-parish:{" "}
                        {parish.flaggedCounts[MAJORITY_NON_PARISH] || "-"}
                      </h6>
                      <h6 className="mb-0">
                        Individuals in groups with majority foreign telephone:{" "}
                        {parish.flaggedCounts[MAJORITY_FOREIGN_TELEPHONE] ||
                          "-"}
                      </h6>
                      <h6 className="mb-0">
                        Individuals in groups with all non-Catholic:{" "}
                        {parish.flaggedCounts[ALL_NC] || "-"}
                      </h6>
                      <h6 className="mb-0">
                        Individuals in groups with at least one child under 12
                        and no accompanying adult 21 and above:{" "}
                        {parish.flaggedCounts[YOUNG_GROUP] || "-"}
                      </h6>
                      <CSVLink
                        data={parish.winnersList}
                        className="btn btn-primary mt-2 w-15"
                        filename={`${parish.name}.csv`}
                      >
                        Download Winners
                      </CSVLink>
                      {parish.name === "All" && (
                        <>
                          <CSVLink
                            data={[
                              [
                                "Parish",
                                "Ballots",
                                "Rejected",
                                "Category",
                                "Individual",
                                "Grps 2",
                                "Pax 2",
                                "Grps 3",
                                "Pax 3",
                                "Grps 4",
                                "Pax 4",
                                "Grps 5",
                                "Pax 5",
                                "Grps 6",
                                "Pax 6",
                              ],
                            ].concat(
                              stats.slice(1).flatMap(getParishBreakdown)
                            )}
                            className="btn btn-primary mt-2 w-15 ml-2"
                            filename="stats.csv"
                          >
                            Download Stats
                          </CSVLink>
                          {migrationData.length > 0 ? (
                            <CSVLink
                              data={migrationData}
                              className="btn btn-primary mt-2 w-15 ml-2"
                              filename="ballot_statuses.csv"
                            >
                              Download Statuses
                            </CSVLink>
                          ) : (
                            <div className="btn btn-primary mt-2 w-15 ml-2 disabled">
                              Processing Statuses
                            </div>
                          )}
                          {successfulData.length > 0 ? (
                            <CSVLink
                              data={successfulData}
                              className="btn btn-primary mt-2 w-15 ml-2"
                              filename="current_successful_data.csv"
                            >
                              Download Successful
                            </CSVLink>
                          ) : (
                            <div className="btn btn-primary mt-2 w-15 ml-2 disabled">
                              Processing successful
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {getStatsCharts("Parishoner", parish.parishionerStats)}
                    {getStatsCharts("Balloters", parish.ballotStats)}
                    {getStatsCharts("Successful", parish.winnersStats)}
                    {getGroupCharts(parish)}
                    <div className="mt-4 ml-4">
                      <Table
                        columnProps={SUCCESSFUL_TABLE_COLUMNS}
                        dataProps={parish.winnersList}
                        sortByEnabled
                      />
                    </div>
                  </Tab>
                ))}
              </Tabs>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Balloting;
