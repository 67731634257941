import React from "react";
import loadable from "@loadable/component";
import CenterSpinner from "../../common/centerSpinner";

function AsyncPFSTicketManager({ ...rest }) {
  const AsyncLoad = loadable(() =>
    //  import("../page/accountManagerIntermediate")
    import("../page/pfsAdminDashboard.jsx")
  );
  console.log("Loading", AsyncPFSTicketManager.name);
  return <AsyncLoad {...rest} fallback={<CenterSpinner />} />;
}

export default AsyncPFSTicketManager;
