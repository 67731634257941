import React, { memo } from "react";
import { useSelector } from "react-redux";
import {
  BALLOT_STATUS_INVITED,
  BALLOT_STATUS_VOLUNTEER,
  BALLOT_STATUS_UNREGISTERED,
  BALLOT_STATUS_UNKNOWN,
  BALLOT_STATUS_REGISTERED,
  BALLOT_STATUS_BALLOTED_SUCCESS,
  BALLOT_STATUS_BALLOTED_UNSUCCESSFUL,
  BALLOT_STATUS_BALLOTED_REJECTED,
} from "../utils/papalMassConst";
import SpecialInvite from "./specialInvite";
import Volunteer from "./volunteerScreen";
import UnknowStatus from "./unknownStatus";
import ClosedRegistration from "./closedRegistration";
import RejectedBallot from "./rejectedBallot";
import BallotOutputSuccess from "./ballotOutputSuccess";

function FirstBallotRegistrationClosed({ img }) {
  const { ballotDetails, ballotStatus } = useSelector(
    (state) => state.papalMassBallot
  );

  switch (ballotStatus) {
    case BALLOT_STATUS_INVITED:
      return <SpecialInvite img={img} />;
    case BALLOT_STATUS_VOLUNTEER:
      return <Volunteer img={img} />;
    case BALLOT_STATUS_UNREGISTERED: //for those who registered but cancelled
    case BALLOT_STATUS_UNKNOWN: //for those who haven't registered for ballotting
    case BALLOT_STATUS_REGISTERED: //for those who registered for ballotting
    case BALLOT_STATUS_BALLOTED_UNSUCCESSFUL: //for those who registered for ballotting BUT unsuccessful
      return <ClosedRegistration />;
    case BALLOT_STATUS_BALLOTED_SUCCESS: //for those who registered for ballotting
      const firstballotpass = ballotDetails?.firstballotpass || false;

      if (!firstballotpass) {
        return <ClosedRegistration />;
      }
      return <BallotOutputSuccess />;

    case BALLOT_STATUS_BALLOTED_REJECTED:
      return <RejectedBallot />;
    default:
      return <UnknowStatus />;
  }
}

export default memo(FirstBallotRegistrationClosed);
