import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Moment from "react-moment";

import Header from "../../../common/header";

import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import Error from "@material-ui/icons/Error";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Spinner from "react-bootstrap/Spinner";

import AppGlobalContext from "../../../../AppGlobalContext";
import {
  useActiveRegistration,
  useGuardian,
  useParishesConfig,
  usePendingConfirmation,
  useWardSelection,
} from "../hooks/hooks";

import { useForm, Controller } from "react-hook-form";
import fire from "../../../../services/fire";
import { firestore } from "firebase";
import { isValidPage } from "../services/registrationStatus";
import { catchConfirmationFormsCollectionName } from "../services/collections";

import ForbiddenRoute from "../components/ForbiddenRoute";
import { getBase64 } from "../services/fileUtils";

/** @typedef {"baptism" | "confirmation"} GodparentSacramentName */
/** @typedef {"baptism" | "reconciliation" | "eucharist"} PrerequisiteSacramentName */

/**
 * @type {{name: PrerequisiteSacramentName, prompt: string}[]}
 */
const prerequisiteSacraments = [
  {
    name: "baptism",
    prompt: "Baptism",
  },
  {
    name: "reconciliation",
    prompt: "First Reconciliation",
  },
  {
    name: "eucharist",
    prompt: "First Holy Communion",
  },
];

/**
 * @type {{name: GodparentSacramentName, prompt: string}[]}
 */
const sacramentFields = [
  {
    name: "baptism",
    prompt: "Baptism",
  },
  {
    name: "confirmation",
    prompt: "Confirmation",
  },
];

const catchUpload = fire.functions("asia-east2").httpsCallable("catchUpload");

async function handleUpload(
  uploadpath,
  fileName,
  file,
  imageDataType,
  imageFileName,
  formField,
  setValue,
  setIsUploading
) {
  try {
    const base64File = await getBase64(file);

    const {
      data: { ok, error, message },
    } = await catchUpload({
      file: base64File,
      uploadpath,
      fileName,
      imageDataType,
    });

    if (ok) {
      const baptismCertUrl = `${uploadpath}/${fileName}`;
      const splitFormField = formField.split(".");
      const certType =
        splitFormField[1].charAt(0).toUpperCase() + splitFormField[1].slice(1);

      setValue(`${formField}.cert.path`, baptismCertUrl);
      setValue(`${formField}.cert.name`, imageFileName);

      alert(`${certType} Certificate ${message}`);

      setIsUploading("");
    } else {
      console.error("handleUpload::error:", error);
      alert(message);
      setIsUploading("");
    }
  } catch (e) {
    console.error("handleUpload::error:", e);
    alert("An error has occured");
    setIsUploading("");
  }
}

function ConfirmationDetails({ user, activeRegistrationState, ward }) {
  const blankForm = {
    isReceiving: "",
    confirmationName: "",
    printedConfirmationName: "",
    godfather: {
      name: "",
      baptism: {
        cert: {
          path: "",
          name: "",
        },
      },
      confirmation: {
        cert: {
          path: "",
          name: "",
        },
      },
    },
    godmother: {
      name: "",
      baptism: {
        cert: {
          path: "",
          name: "",
        },
      },
      confirmation: {
        cert: {
          path: "",
          name: "",
        },
      },
    },
    proxy: {
      canGodparentsAttendMass: "",
      name: "",
      sex: "",
      isProxyBaptised: "",
      isProxyConfirmed: "",
    },
  };

  const history = useHistory();
  /** @type {{ exists: boolean; data: typeof blankForm; }} */
  const pendingConfirmationState = usePendingConfirmation();
  const parishesConfig = useParishesConfig();

  const db = fire.firestore();
  const catchConfirmationFormsCollection = db.collection(
    catchConfirmationFormsCollectionName
  );

  const defaultValues = pendingConfirmationState.exists
    ? pendingConfirmationState.data
    : blankForm;

  // console.log(pendingConfirmationState); // For debug only

  /**
   * @param {firestore.CollectionReference<unknown>} catchConfirmationFormsCollection
   */
  async function handleSaveConfirmationDetails(
    catchConfirmationFormsCollection,
    confirmationData,
    ward,
    uid,
    callBack
  ) {
    if (!ward.uid || !uid) {
      return;
    }

    if (confirmationData.proxy?.canGodparentsAttendMass) {
      confirmationData.proxy.name = "";
      confirmationData.proxy.sex = "";
      confirmationData.proxy.isProxyBaptised = "";
      confirmationData.proxy.isProxyConfirmed = "";
    }

    const confirmationDoc = pendingConfirmationState.exists
      ? catchConfirmationFormsCollection.doc(pendingConfirmationState.data.id)
      : catchConfirmationFormsCollection.doc();

    const docData = {
      id: confirmationDoc.id,
      submittedAt: new Date(),
      submittedBy: uid,
      child: {
        name: ward.name,
        uid: ward.uid,
      },
      ...confirmationData,
    };

    await confirmationDoc.set(docData);

    if (typeof callBack === "function") {
      await callBack();
    }

    window.scrollTo(0, 0);
  }

  const {
    register,
    getValues,
    setValue,
    reset,
    handleSubmit,
    watch,
    control,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({ defaultValues });

  function monthDiff(d1, d2) {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  const registrationDatum = activeRegistrationState.data;
  const parish = parishesConfig.find(
    ({ id }) => id == registrationDatum?.selectedParishId
  );

  let savedProgramme;

  if (registrationDatum?.programmeType) {
    if (typeof registrationDatum?.programmeType === "string") {
      savedProgramme = parish?.programmes?.find(
        ({ id }) => id == registrationDatum?.programmeType
      );
    } else if (typeof registrationDatum?.programmeType !== "string") {
      savedProgramme = registrationDatum?.programmeType;
    }
  }

  const confirmationMonth = savedProgramme?.confirmationMonth || 0;
  // const catecheticalYear = savedProgramme?.catecheticalYear || 0;
  const catecheticalOpen = savedProgramme?.catecheticalOpen;
  const confirmationLevel = +(savedProgramme?.confirmationLevel || 0);
  const registrationLevel = +(registrationDatum?.level || 0);
  const registrationYear = registrationDatum.submittedAt.toDate().getFullYear();

  const now = new Date();

  const currentLevel =
    registrationLevel +
    catecheticalOpen.toDate().getFullYear() -
    registrationYear -
    +(
      registrationDatum.submittedAt.toDate() >
      new Date(catecheticalOpen.toDate()).setFullYear(registrationYear)
    );

  const confirmationCatecheticalYear =
    registrationYear -
    registrationLevel +
    Math.max(currentLevel, confirmationLevel); // If child is above the Confirmation age, allow them to apply this year
  const confirmationYear =
    confirmationCatecheticalYear +
    (catecheticalOpen.toDate().getMonth() < savedProgramme.confirmationMonth
      ? 0
      : 1);
  const confirmationDate = new Date(confirmationYear, confirmationMonth, 1);

  // console.log({
  //   currentLevel,
  //   confirmationLevel,
  //   submittedAt: registrationDatum.submittedAt.toDate(),
  //   catecheticalOpen: catecheticalOpen.toDate(),
  //   confirmationCatecheticalYear,
  //   confirmationYear,
  //   confirmationMonth: savedProgramme.confirmationMonth,
  // }); // For debug only

  const [formAvailableDate, deadline1Date, deadline2Date, deadline3Date] = [
    8, 5, 3, 1,
  ].map((m) => new Date(confirmationYear, confirmationMonth - m, 1)); // Negative months automatically subtract from the year

  const monthsFromConfirmation = monthDiff(now, confirmationDate);

  // console.log({ confirmationDate, now, monthsFromConfirmation }); // For debug only
  const currentPhase =
    monthsFromConfirmation <= 1
      ? 3 // PAST DEADLINE 3
      : monthsFromConfirmation <= 3
      ? 2 // PAST DEADLINE 2
      : monthsFromConfirmation <= 5
      ? 1 // PAST DEADLINE 1
      : monthsFromConfirmation <= 8
      ? 0 // AVAILABLE
      : -1; // UNAVAILABLE

  const modifyDate = [
    deadline1Date,
    deadline2Date,
    deadline3Date,
    confirmationDate,
  ][currentPhase];

  const callBack = useCallback(
    () =>
      history.push({
        pathname: "/catch",
        confirmationSuccess: true,
        modifyDate,
      }),
    [history]
  );

  function onSubmit() {
    const confirmationData = getValues();

    const gFaName = confirmationData.godfather?.name;
    const gFaBaptismCertPath = confirmationData.godfather?.baptism?.cert?.path;
    const gFaConfirmationCertPath =
      confirmationData.godfather?.confirmation?.cert?.path;

    const gMoName = confirmationData.godmother?.name;
    const gMoBaptismCertPath = confirmationData.godmother?.baptism?.cert?.path;
    const gMoConfirmationCertPath =
      confirmationData.godmother?.confirmation?.cert?.path;

    let canProceed = true,
      isGFaRequired = true,
      isGMoRequired = true;

    if (
      gMoName !== "" &&
      gMoBaptismCertPath !== "" &&
      gMoConfirmationCertPath !== ""
    ) {
      isGFaRequired = false;
    }

    if (
      gFaName !== "" &&
      gFaBaptismCertPath !== "" &&
      gFaConfirmationCertPath !== ""
    ) {
      isGMoRequired = false;
    }

    if (confirmationData.isReceiving) {
      if (gFaBaptismCertPath === "" && isGFaRequired) {
        canProceed = false;
        setError("godfather.baptism.cert.path");
      }

      if (gFaConfirmationCertPath === "" && isGFaRequired) {
        canProceed = false;
        setError("godfather.confirmation.cert.path");
      }

      if (gMoBaptismCertPath === "" && isGMoRequired) {
        canProceed = false;
        setError("godmother.baptism.cert.path");
      }

      if (gMoConfirmationCertPath === "" && isGMoRequired) {
        canProceed = false;
        setError("godmother.confirmation.cert.path");
      }

      if (confirmationData.proxy?.isProxyBaptised === false) {
        canProceed = false;
        setError("proxy.isProxyBaptised");
      }

      if (confirmationData.proxy?.isProxyConfirmed === false) {
        canProceed = false;
        setError("proxy.isProxyConfirmed");
      }

      if (canProceed) {
        clearErrors("godfather.baptism.cert.path");
        clearErrors("godfather.confirmation.cert.path");
        clearErrors("godmother.baptism.cert.path");
        clearErrors("godmother.confirmation.cert.path");
        clearErrors("proxy.isProxyBaptised");
        clearErrors("proxy.isProxyConfirmed");

        handleSaveConfirmationDetails(
          catchConfirmationFormsCollection,
          confirmationData,
          ward,
          user.uid,
          callBack
        );
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      clearErrors("godfather.baptism.cert.path");
      clearErrors("godfather.confirmation.cert.path");
      clearErrors("godmother.baptism.cert.path");
      clearErrors("godmother.confirmation.cert.path");
      clearErrors("proxy.isProxyBaptised");
      clearErrors("proxy.isProxyConfirmed");

      handleSaveConfirmationDetails(
        catchConfirmationFormsCollection,
        confirmationData,
        ward,
        user.uid,
        callBack
      );
    }
  }

  const isReceiving = watch("isReceiving");

  const godfatherName = watch("godfather.name");
  const godfatherBaptismCertFileName = watch("godfather.baptism.cert.name");
  const godfatherConfirmationCertFileName = watch(
    "godfather.confirmation.cert.name"
  );

  const godmotherName = watch("godmother.name");
  const godmotherBaptismCertFileName = watch("godmother.baptism.cert.name");
  const godmotherConfirmationCertFileName = watch(
    "godmother.confirmation.cert.name"
  );
  const canGodparentsAttendMass = watch("proxy.canGodparentsAttendMass");

  let isGodfatherRequired = true,
    isGodmotherRequired = true;

  if (
    godmotherName !== "" &&
    godmotherBaptismCertFileName !== "" &&
    godmotherConfirmationCertFileName !== ""
  ) {
    isGodfatherRequired = false;
  }

  if (
    godfatherName !== "" &&
    godfatherBaptismCertFileName !== "" &&
    godfatherConfirmationCertFileName !== ""
  ) {
    isGodmotherRequired = false;
  }

  const [isReceivingCount, setIsReceivingCount] = useState(0);

  useEffect(() => {
    if (isReceivingCount > 1) {
      if (isReceiving === false) {
        reset(undefined, { keepDefaultValues: true });
        setValue("isReceiving", isReceiving);
      }
    } else {
      setIsReceivingCount(isReceivingCount + 1);
    }
  }, [isReceiving]);

  const [isUploading, setIsUploading] = useState("");

  const godfatherBaptismRef = useRef();
  const godfatherConfirmationRef = useRef();
  const godmotherBaptismRef = useRef();
  const godmotherConfirmationRef = useRef();

  function godfatherBaptismHandleClick() {
    godfatherBaptismRef.current.click();
  }

  function godfatherConfirmationHandleClick() {
    godfatherConfirmationRef.current.click();
  }

  function godmotherBaptismHandleClick() {
    godmotherBaptismRef.current.click();
  }

  function godmotherConfirmationHandleClick() {
    godmotherConfirmationRef.current.click();
  }

  function onFileChange(e) {
    const formField = e.target.getAttribute("formfield");
    const splitFormField = formField.split(".");

    if (e.target.files && e.target.files.length > 0) {
      setIsUploading(formField);

      const file = e.target.files[0];
      const imageDataType = file.type;
      const imageFileName = file.name;
      const uploadpath = `user/${
        user.userid
      }/private/confirmation/${splitFormField[0].toLowerCase()}/${
        splitFormField[1]
      }`;
      let fileName = "";
      switch (imageDataType) {
        case "application/pdf":
          fileName = `${user.userid}${id}.pdf`;
          break;
        case "image/jpeg":
          fileName = `${user.userid}${id}.jpeg`;
          break;
        case "image/png":
          fileName = `${user.userid}${id}.png`;
          break;
        default:
      }
      if (file.size > 400000) {
        alert("You have exceeded the max file size of 400kb");
        setError(`${formField}.cert.path`);
        setIsUploading("");
      } else if (fileName) {
        // clearErrors(`${formField}.cert.path`);
        clearErrors("godfather.baptism.cert.path");
        clearErrors("godfather.confirmation.cert.path");
        clearErrors("godmother.baptism.cert.path");
        clearErrors("godmother.confirmation.cert.path");
        handleUpload(
          uploadpath,
          fileName,
          file,
          imageDataType,
          imageFileName,
          formField,
          setValue,
          setIsUploading
        );
      }
    } else {
      setError(`${formField}.cert.path`);
    }
  }

  if (!registrationDatum || !parish) {
    return <ForbiddenRoute />;
  }

  const { id, sacraments = {} } = registrationDatum;

  if (!!savedProgramme) {
    if (!savedProgramme?.isConfirmationPrep) {
      return (
        <>
          <Card className="flex-grow-1 d-flex flex-column">
            <Card.Body
              className="d-flex flex-column justify-content-center align-items-center py-5"
              style={{ gap: "1.5rem" }}
            >
              <h5>Invalid Programme</h5>
              <p className="text-wrap text-center">
                Your child's programme ({savedProgramme?.name}) is not a
                preparatory programme for Confirmation.
                <br />
                The Confirmation Form is not applicable for your child.
              </p>
              <hr className="w-100" />
              <p className="text-wrap text-center">
                If you believe this to be in error, please contact your
                Programme Coordinator at{" "}
                <a
                  target="_blank"
                  href={`mailto:${savedProgramme?.enquiryEmail}`}
                >
                  {savedProgramme?.enquiryEmail}
                </a>
                .
              </p>
              <Container>
                <Row>
                  <Col>
                    <Link to="/catch">
                      <Button
                        className="my-3 w-100"
                        variant="secondary"
                        size="lg"
                      >
                        Back to Menu
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </>
      );
    }
    if (sacraments.confirmation?.received) {
      return (
        <>
          <Card className="flex-grow-1 d-flex flex-column">
            <Card.Body
              className="d-flex flex-column justify-content-center align-items-center py-5"
              style={{ gap: "1.5rem" }}
            >
              <h5>Confirmation already received</h5>
              <p className="text-wrap">
                Your child has already received Confirmation. If you believe
                this to be in error, please contact your Programme Coordinator
                at{" "}
                <a
                  target="_blank"
                  href={`mailto:${savedProgramme?.enquiryEmail}`}
                >
                  {savedProgramme?.enquiryEmail}
                </a>
                .
              </p>
              <Container>
                <Row>
                  <Col>
                    <Link to="/catch">
                      <Button
                        className="my-3 w-100"
                        variant="secondary"
                        size="lg"
                      >
                        Back to Menu
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </>
      );
    }

    const missingSacraments = prerequisiteSacraments.filter(
      ({ name }) => !sacraments[name]?.received
    );

    if (missingSacraments.some(({ name }) => name !== "confirmation")) {
      return (
        <>
          <Card className="flex-grow-1 d-flex flex-column">
            <Card.Body
              className="d-flex flex-column justify-content-center align-items-center py-5"
              style={{ gap: "1.5rem" }}
            >
              <h5>Missing Prerequisites</h5>
              <span className="text-center text-wrap">
                The following Sacraments have not been received:
                <ListGroup as="ol">
                  {missingSacraments.map(({ name, prompt }) => (
                    <ListGroup.Item as="li" key={name} variant="danger">
                      {prompt}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </span>
              <hr className="w-100" />
              <p className="text-center text-wrap">
                If you believe this to be in error, please contact your
                Programme Coordinator at{" "}
                <a
                  target="_blank"
                  href={`mailto:${savedProgramme?.enquiryEmail}`}
                >
                  {savedProgramme?.enquiryEmail}
                </a>
                .
              </p>
              <Container>
                <Row>
                  <Col>
                    <Link to="/catch">
                      <Button
                        className="my-3 w-100"
                        variant="secondary"
                        size="lg"
                      >
                        Back to Menu
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </>
      );
    }

    // Condition order past this point is important, be careful when reordering
    if (currentPhase === -1)
      return (
        <>
          <Card className="flex-grow-1 d-flex flex-column">
            <Card.Body
              className="d-flex flex-column justify-content-center align-items-center py-5"
              style={{ gap: "1.5rem" }}
            >
              <h5>Form Not Yet Open</h5>
              <p className="text-wrap text-center">
                Your child is due to be confirmed on{" "}
                <Moment
                  className="font-weight-bold"
                  date={confirmationDate}
                  format="DD MMM YYYY"
                />
                .<br />
                The Confirmation Form will open on{" "}
                <Moment
                  className="font-weight-bold"
                  date={formAvailableDate}
                  format="DD MMM YYYY"
                />
                .
              </p>
              <hr className="w-100" />
              <p className="text-wrap text-center">
                If you believe this to be in error, please contact your
                Programme Coordinator at{" "}
                <a
                  target="_blank"
                  href={`mailto:${savedProgramme?.enquiryEmail}`}
                >
                  {savedProgramme?.enquiryEmail}
                </a>
                .
              </p>
              <Container>
                <Row>
                  <Col>
                    <Link to="/catch">
                      <Button
                        className="my-3 w-100"
                        variant="secondary"
                        size="lg"
                      >
                        Back to Menu
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </>
      );
    if (currentPhase !== 0 && !pendingConfirmationState.exists) {
      const nextFormAvailableDate = new Date(
        formAvailableDate.getTime()
      ).setFullYear(formAvailableDate.getFullYear() + 1);
      return (
        <>
          <Card className="flex-grow-1 d-flex flex-column">
            <Card.Body
              className="d-flex flex-column justify-content-center align-items-center py-5"
              style={{ gap: "1.5rem" }}
            >
              <h5>Deadline Passed</h5>
              <p className="text-wrap text-center">
                The deadline for submission has already passed. It will be
                available again on{" "}
                <Moment
                  className="font-weight-bold"
                  date={nextFormAvailableDate}
                  format="DD MMM YYYY"
                />
                .
              </p>
              <hr className="w-100" />
              <p className="text-wrap text-center">
                If you believe this to be in error, please contact your
                Programme Coordinator at{" "}
                <a
                  target="_blank"
                  href={`mailto:${savedProgramme?.enquiryEmail}`}
                >
                  {savedProgramme?.enquiryEmail}
                </a>
                .
              </p>
              <Container>
                <Row>
                  <Col>
                    <Link to="/catch">
                      <Button
                        className="my-3 w-100"
                        variant="secondary"
                        size="lg"
                      >
                        Back to Menu
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </>
      );
    }

    return (
      <>
        {(!!errors.godfather?.baptism?.cert?.path && isGodfatherRequired) ||
        (!!errors.godfather?.confirmation?.cert?.path && isGodfatherRequired) ||
        (!!errors.godmother?.baptism?.cert?.path && isGodmotherRequired) ||
        (!!errors.godmother?.confirmation?.cert?.path &&
          isGodmotherRequired) ? (
          <>
            <Card
              className="d-flex flex-column border-danger"
              style={{ backgroundColor: "#FFF4F3" }}
            >
              <Card.Body className="d-flex flex-column align-items-center py-4">
                <Container>
                  <h3>There is a problem with your submission</h3>
                  {!!errors.godfather?.baptism?.cert?.path &&
                  isGodfatherRequired ? (
                    <>
                      <u className="text-danger">
                        Godfather's Baptism Certificate is required
                      </u>
                      <br />
                    </>
                  ) : null}
                  {!!errors.godfather?.confirmation?.cert?.path &&
                  isGodfatherRequired ? (
                    <>
                      <u className="text-danger">
                        Godfather's Confirmation Certificate is required
                      </u>
                      <br />
                    </>
                  ) : null}
                  {!!errors.godmother?.baptism?.cert?.path &&
                  isGodmotherRequired ? (
                    <>
                      <u className="text-danger">
                        Godmother's Baptism Certificate is required
                      </u>
                      <br />
                    </>
                  ) : null}
                  {!!errors.godmother?.confirmation?.cert?.path &&
                  isGodmotherRequired ? (
                    <>
                      <u className="text-danger">
                        Godmother's Confirmation Certificate is required
                      </u>
                      <br />
                    </>
                  ) : null}
                  {!!errors.proxy?.isProxyBaptised ? (
                    <>
                      <u className="text-danger">
                        Proxy Godparent must have received the Sacrament of
                        Baptism
                      </u>
                      <br />
                    </>
                  ) : null}
                  {!!errors.proxy?.isProxyConfirmed ? (
                    <u className="text-danger">
                      Proxy Godparent must have received the Sacrament of
                      Confirmation
                    </u>
                  ) : null}
                </Container>
              </Card.Body>
            </Card>
            <br />
          </>
        ) : null}
        <Card
          className="d-flex flex-column"
          style={{ borderColor: "transparent" }}
        >
          <Card.Body className="d-flex flex-column align-items-center py-4">
            <Row className="w-100">
              <Alert variant="info" className="w-100">
                <Alert.Heading>Submission Deadline</Alert.Heading>
                {currentPhase === 0 && (
                  <>
                    Hello! Please fill and submit the Confirmation Form by{" "}
                    <Moment
                      className="font-weight-bold"
                      date={deadline1Date}
                      format="DD MMM YYYY"
                    />
                    .
                  </>
                )}
                {currentPhase === 1 && (
                  <>
                    Hello! From now until{" "}
                    <Moment
                      className="font-weight-bold"
                      date={deadline2Date}
                      format="DD MMM YYYY"
                    />
                    , you may still:
                    <ul>
                      <li>Amend Godparent or Proxy information</li>
                      <li>Opt your child out of Confirmation, if necessary</li>
                    </ul>
                  </>
                )}
                {currentPhase === 2 && (
                  <>
                    Hello! From now until{" "}
                    <Moment
                      className="font-weight-bold"
                      date={deadline3Date}
                      format="DD MMM YYYY"
                    />
                    , you may still:
                    <ul>
                      <li>Opt your child out of Confirmation, if necessary</li>
                    </ul>
                  </>
                )}
                {currentPhase === 3 && (
                  <>
                    Hello! As your child is due for Confirmation on{" "}
                    <Moment
                      className="font-weight-bold"
                      date={confirmationDate}
                      format="DD MMM YYYY"
                    />
                    , the form can no longer be amended. However, you may still
                    view the information below.
                    <hr />
                    For urgent matters, kindly contact your Programme
                    Coordinator at{" "}
                    <a
                      target="_blank"
                      href={`mailto:${savedProgramme?.enquiryEmail}`}
                    >
                      {savedProgramme?.enquiryEmail}
                    </a>
                    .
                  </>
                )}
              </Alert>
            </Row>
            <br />
            <Card className="w-100 my-2">
              <Card.Body>
                <h3>Confirmation Details</h3>
                <Form
                  className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
                  style={{ gap: "1.5rem" }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row className="w-100">
                    <Form.Group className="w-100">
                      <Form.Row className="align-items-center">
                        <Form.Label className="w-100 text-wrap">
                          Will your child be receiving the Sacrament of
                          Confirmation?
                        </Form.Label>
                        <Row className="w-100">
                          <Controller
                            control={control}
                            name="isReceiving"
                            rules={{
                              validate: (v) => v === true || v === false,
                            }}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <>
                                <Col xs="auto">
                                  <Form.Check
                                    disabled={
                                      (pendingConfirmationState.exists &&
                                        pendingConfirmationState.data
                                          .isReceiving === false) ||
                                      currentPhase >= 3
                                    }
                                    className="w-100"
                                    type="radio"
                                    label="Yes"
                                    id="isReceivingTrue"
                                    onBlur={onBlur}
                                    onChange={() => onChange(true)}
                                    checked={value === true}
                                    ref={ref}
                                    isInvalid={!!errors.isReceiving}
                                  />
                                </Col>
                                <Col xs="auto">
                                  <Form.Check
                                    disabled={currentPhase >= 3}
                                    className="w-100"
                                    type="radio"
                                    label="No"
                                    id="isReceivingFalse"
                                    onBlur={onBlur}
                                    onChange={() => onChange(false)}
                                    checked={value === false}
                                    ref={ref}
                                    isInvalid={!!errors.isReceiving}
                                  />
                                </Col>
                              </>
                            )}
                          />
                        </Row>
                      </Form.Row>
                      {isReceiving ? (
                        <>
                          <br />
                          <Form.Row className="align-items-center">
                            <Form.Label className="w-100 text-wrap">
                              Confirmation Name (which the presiding Bishop will
                              address the Confirmand by)
                            </Form.Label>
                            <Form.Control
                              size="lg"
                              className="w-100"
                              placeholder="E.g. Stephen"
                              disabled={currentPhase >= 1}
                              {...register("confirmationName", {
                                required: isReceiving,
                                validate: (val) =>
                                  /^(?!.*\b\w+\s+\w+\s+\w+\b).+$/.test(val),
                              })}
                              isInvalid={!!errors.confirmationName}
                            />
                          </Form.Row>
                          <br />
                          <Form.Row className="align-items-center">
                            <Form.Label className="w-100 text-wrap">
                              Full Name with Confirmation Name (to be printed on
                              the Confirmation Certificate)
                            </Form.Label>
                            <Form.Control
                              size="lg"
                              className="w-100"
                              placeholder="E.g. John Stephen Tan Ah Kow"
                              disabled={currentPhase >= 1}
                              {...register("printedConfirmationName", {
                                required: isReceiving,
                              })}
                              isInvalid={!!errors.printedConfirmationName}
                            />
                          </Form.Row>
                        </>
                      ) : null}
                      {isReceiving === false && (
                        <Alert variant="warning" className="w-100 mt-3 mb-0">
                          <Alert.Heading>Are you very sure?</Alert.Heading>
                          You are about to{" "}
                          <span className="font-weight-bold">
                            opt your child out from Confirmation
                          </span>
                          .<br />
                          After{" "}
                          {currentPhase === 0
                            ? "the deadline has passed"
                            : "submitting"}
                          , you will not be able to reapply until next year!
                          <hr />
                          If this was not your intention, please select "Back to
                          Menu".
                        </Alert>
                      )}
                    </Form.Group>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
            {isReceiving ? (
              <>
                <Card className="w-100 my-2">
                  <Card.Body>
                    <h3>
                      Godparent/Sponsor Details{" "}
                      <OverlayTrigger
                        key="top-1"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top-1">Cannot be parent</Tooltip>
                        }
                      >
                        <InfoOutlinedIcon
                          className="text-primary"
                          style={{ marginTop: "-20px" }}
                        />
                      </OverlayTrigger>
                    </h3>
                    <br />
                    <Form
                      className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
                      style={{ gap: "1.5rem" }}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="w-100">
                        <Form.Group className="w-100">
                          <Form.Row className="align-items-center">
                            <Form.Label className="w-100 text-wrap">
                              Godfather/Sponsor (M) Name (as in Baptism
                              Certificate)
                            </Form.Label>
                            <Form.Control
                              size="lg"
                              className="w-100"
                              disabled={currentPhase >= 2}
                              {...register("godfather.name", {
                                required: isReceiving && isGodfatherRequired,
                              })}
                              isInvalid={!!errors.godfather?.name}
                            />
                          </Form.Row>
                          <br />
                          <Form.Row className="align-items-center">
                            <Form.Label className="d-flex flex-row w-100 mb-0">
                              <b>Baptism Certificate</b>
                            </Form.Label>
                            <span className="text-muted">
                              Max file size is 400kb. Supported file types .jpg,
                              .png, and .pdf.
                            </span>
                            <Row className="w-100 mt-3 mx-0">
                              <Col className="px-0" xs="auto">
                                {godfatherBaptismCertFileName === "" ? (
                                  <Button
                                    variant="outline-primary"
                                    size="lg"
                                    className="w-100 d-flex align-items-center"
                                    onClick={godfatherBaptismHandleClick}
                                    disabled={currentPhase >= 2 || isUploading}
                                  >
                                    {isUploading === "godfather.baptism" ? (
                                      <>
                                        <Spinner
                                          className="mr-1"
                                          animation="border"
                                        />
                                        Uploading
                                      </>
                                    ) : (
                                      <>
                                        <ArrowUpwardOutlinedIcon className="mr-1" />{" "}
                                        Upload file
                                      </>
                                    )}
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={currentPhase >= 2}
                                    variant="secondary"
                                    size="lg"
                                    className="w-100 text-wrap text-dark"
                                    style={{
                                      backgroundColor: "#f3f3f3",
                                      borderColor: "#f3f3f3",
                                    }}
                                    onClick={() => {
                                      setValue(
                                        "godfather.baptism.cert.path",
                                        ""
                                      );
                                      setValue(
                                        "godfather.baptism.cert.name",
                                        ""
                                      );

                                      godfatherBaptismRef.current.value = "";
                                    }}
                                  >
                                    <span>{godfatherBaptismCertFileName}</span>
                                    <DeleteOutlinedIcon className="text-danger ml-1" />
                                  </Button>
                                )}
                              </Col>
                            </Row>
                            <input
                              disabled={currentPhase >= 2}
                              style={{ height: "0px" }}
                              id="file-input"
                              type="file"
                              formfield="godfather.baptism"
                              onChange={onFileChange}
                              accept="application/pdf,image/jpeg,image/png"
                              ref={godfatherBaptismRef}
                            />
                          </Form.Row>
                          {!!errors.godfather?.baptism?.cert?.path &&
                          isGodfatherRequired ? (
                            <Row className="align-items-center w-100 my-2 px-2 text-danger">
                              <Error
                                className="mr-1"
                                style={{ fontSize: "18px" }}
                              />
                              Baptism Certificate is required
                            </Row>
                          ) : null}
                          <br />
                          <Form.Row className="align-items-center">
                            <Form.Label className="d-flex flex-row w-100 mb-0">
                              <b>Confirmation Certificate</b>
                            </Form.Label>
                            <span className="text-muted">
                              Max file size is 400kb. Supported file types .jpg,
                              .png, and .pdf.
                            </span>
                            <Row className="w-100 mt-3 mx-0">
                              <Col className="px-0" xs="auto">
                                {godfatherConfirmationCertFileName === "" ? (
                                  <Button
                                    variant="outline-primary"
                                    size="lg"
                                    className="w-100 d-flex align-items-center"
                                    onClick={godfatherConfirmationHandleClick}
                                    disabled={currentPhase >= 2 || isUploading}
                                  >
                                    {isUploading ===
                                    "godfather.confirmation" ? (
                                      <>
                                        <Spinner
                                          className="mr-1"
                                          animation="border"
                                        />
                                        Uploading
                                      </>
                                    ) : (
                                      <>
                                        <ArrowUpwardOutlinedIcon className="mr-1" />{" "}
                                        Upload file
                                      </>
                                    )}
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={currentPhase >= 2}
                                    variant="secondary"
                                    size="lg"
                                    className="w-100 text-wrap text-dark"
                                    style={{
                                      backgroundColor: "#f3f3f3",
                                      borderColor: "#f3f3f3",
                                    }}
                                    onClick={() => {
                                      setValue(
                                        "godfather.confirmation.cert.path",
                                        ""
                                      );
                                      setValue(
                                        "godfather.confirmation.cert.name",
                                        ""
                                      );

                                      godfatherConfirmationRef.current.value =
                                        "";
                                    }}
                                  >
                                    <span>
                                      {godfatherConfirmationCertFileName}
                                    </span>
                                    <DeleteOutlinedIcon className="text-danger ml-1" />
                                  </Button>
                                )}
                              </Col>
                            </Row>
                            <input
                              disabled={currentPhase >= 2}
                              style={{ height: "0px" }}
                              id="file-input"
                              type="file"
                              formfield="godfather.confirmation"
                              onChange={onFileChange}
                              accept="application/pdf,image/jpeg,image/png"
                              ref={godfatherConfirmationRef}
                            />
                          </Form.Row>
                          {!!errors.godfather?.confirmation?.cert?.path &&
                          isGodfatherRequired ? (
                            <Row className="align-items-center w-100 my-2 px-2 text-danger">
                              <Error
                                className="mr-1"
                                style={{ fontSize: "18px" }}
                              />
                              Confirmation Certificate is required
                            </Row>
                          ) : null}
                          <hr />
                          <Form.Row className="align-items-center">
                            <Form.Label className="w-100 text-wrap">
                              Godmother/Sponsor (F) Name (as in Baptism
                              Certificate)
                            </Form.Label>
                            <Form.Control
                              disabled={currentPhase >= 2}
                              size="lg"
                              className="w-100"
                              {...register("godmother.name", {
                                required: isReceiving && isGodmotherRequired,
                              })}
                              isInvalid={!!errors.godmother?.name}
                            />
                          </Form.Row>
                          <br />
                          <Form.Row className="align-items-center">
                            <Form.Label className="d-flex flex-row w-100 mb-0">
                              <b>Baptism Certificate</b>
                            </Form.Label>
                            <span className="text-muted">
                              Max file size is 400kb. Supported file types .jpg,
                              .png, and .pdf.
                            </span>
                            <Row className="w-100 mt-3 mx-0">
                              <Col className="px-0" xs="auto">
                                {godmotherBaptismCertFileName === "" ? (
                                  <Button
                                    variant="outline-primary"
                                    size="lg"
                                    className="w-100 d-flex align-items-center"
                                    onClick={godmotherBaptismHandleClick}
                                    disabled={currentPhase >= 2 || isUploading}
                                  >
                                    {isUploading === "godmother.baptism" ? (
                                      <>
                                        <Spinner
                                          className="mr-1"
                                          animation="border"
                                        />
                                        Uploading
                                      </>
                                    ) : (
                                      <>
                                        <ArrowUpwardOutlinedIcon className="mr-1" />{" "}
                                        Upload file
                                      </>
                                    )}
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={currentPhase >= 2}
                                    variant="secondary"
                                    size="lg"
                                    className="w-100 text-wrap text-dark"
                                    style={{
                                      backgroundColor: "#f3f3f3",
                                      borderColor: "#f3f3f3",
                                    }}
                                    onClick={() => {
                                      setValue(
                                        "godmother.baptism.cert.path",
                                        ""
                                      );
                                      setValue(
                                        "godmother.baptism.cert.name",
                                        ""
                                      );

                                      godmotherBaptismRef.current.value = "";
                                    }}
                                  >
                                    <span>{godmotherBaptismCertFileName}</span>
                                    <DeleteOutlinedIcon className="text-danger ml-1" />
                                  </Button>
                                )}
                              </Col>
                            </Row>
                            <input
                              disabled={currentPhase >= 2}
                              style={{ height: "0px" }}
                              id="file-input"
                              type="file"
                              formfield="godmother.baptism"
                              onChange={onFileChange}
                              accept="application/pdf,image/jpeg,image/png"
                              ref={godmotherBaptismRef}
                            />
                          </Form.Row>
                          {!!errors.godmother?.baptism?.cert?.path &&
                          isGodmotherRequired ? (
                            <Row className="align-items-center w-100 my-2 px-2 text-danger">
                              <Error
                                className="mr-1"
                                style={{ fontSize: "18px" }}
                              />
                              Baptism Certificate is required
                            </Row>
                          ) : null}
                          <br />
                          <Form.Row className="align-items-center">
                            <Form.Label className="d-flex flex-row w-100 mb-0">
                              <b>Confirmation Certificate</b>
                            </Form.Label>
                            <span className="text-muted">
                              Max file size is 400kb. Supported file types .jpg,
                              .png, and .pdf.
                            </span>
                            <Row className="w-100 mt-3 mx-0">
                              <Col className="px-0" xs="auto">
                                {godmotherConfirmationCertFileName === "" ? (
                                  <Button
                                    variant="outline-primary"
                                    size="lg"
                                    className="w-100 d-flex align-items-center"
                                    onClick={godmotherConfirmationHandleClick}
                                    disabled={currentPhase >= 2 || isUploading}
                                  >
                                    {isUploading ===
                                    "godmother.confirmation" ? (
                                      <>
                                        <Spinner
                                          className="mr-1"
                                          animation="border"
                                        />
                                        Uploading
                                      </>
                                    ) : (
                                      <>
                                        <ArrowUpwardOutlinedIcon className="mr-1" />{" "}
                                        Upload file
                                      </>
                                    )}
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={currentPhase >= 2}
                                    variant="secondary"
                                    size="lg"
                                    className="w-100 text-wrap text-dark"
                                    style={{
                                      backgroundColor: "#f3f3f3",
                                      borderColor: "#f3f3f3",
                                    }}
                                    onClick={() => {
                                      setValue(
                                        "godmother.confirmation.cert.path",
                                        ""
                                      );
                                      setValue(
                                        "godmother.confirmation.cert.name",
                                        ""
                                      );

                                      godmotherConfirmationRef.current.value =
                                        "";
                                    }}
                                  >
                                    <span>
                                      {godmotherConfirmationCertFileName}
                                    </span>
                                    <DeleteOutlinedIcon className="text-danger ml-1" />
                                  </Button>
                                )}
                              </Col>
                            </Row>
                            <input
                              disabled={currentPhase >= 2}
                              style={{ height: "0px" }}
                              id="file-input"
                              type="file"
                              formfield="godmother.confirmation"
                              onChange={onFileChange}
                              accept="application/pdf,image/jpeg,image/png"
                              ref={godmotherConfirmationRef}
                            />
                          </Form.Row>
                          {!!errors.godmother?.confirmation?.cert?.path &&
                          isGodmotherRequired ? (
                            <Row className="align-items-center w-100 my-2 px-2 text-danger">
                              <Error
                                className="mr-1"
                                style={{ fontSize: "18px" }}
                              />
                              Confirmation Certificate is required
                            </Row>
                          ) : null}
                          <hr />
                          <Row className="w-100 mx-0">
                            <Form.Row className="align-items-center">
                              <Form.Label className="w-100 text-wrap">
                                Is at least one Godparent/Sponsor able to attend
                                the Confirmation Mass?
                              </Form.Label>
                              <Controller
                                control={control}
                                name="proxy.canGodparentsAttendMass"
                                rules={{
                                  validate: (v) => v === true || v === false,
                                }}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <>
                                    <Col xs="auto">
                                      <Form.Check
                                        disabled={currentPhase >= 2}
                                        className="w-100"
                                        type="radio"
                                        label="Yes"
                                        id="canGodparentsAttendMassTrue"
                                        onBlur={onBlur}
                                        onChange={() => onChange(true)}
                                        checked={value === true}
                                        ref={ref}
                                        isInvalid={
                                          !!errors.proxy
                                            ?.canGodparentsAttendMass
                                        }
                                      />
                                    </Col>
                                    <Col xs="auto">
                                      <Form.Check
                                        disabled={currentPhase >= 2}
                                        className="w-100"
                                        type="radio"
                                        label="No"
                                        id="canGodparentsAttendMassFalse"
                                        onBlur={onBlur}
                                        onChange={() => onChange(false)}
                                        checked={value === false}
                                        ref={ref}
                                        isInvalid={
                                          !!errors.proxy
                                            ?.canGodparentsAttendMass
                                        }
                                      />
                                    </Col>
                                  </>
                                )}
                              />
                            </Form.Row>
                          </Row>
                        </Form.Group>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
                <Card className="w-100 my-2">
                  <Card.Body>
                    <h3>
                      Proxy Godparent/Sponsor{" "}
                      <OverlayTrigger
                        key="top-2"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top-2">
                            Can be a parent or family member.
                          </Tooltip>
                        }
                      >
                        <InfoOutlinedIcon
                          className="text-primary"
                          style={{ marginTop: "-20px" }}
                        />
                      </OverlayTrigger>
                    </h3>
                    <Form
                      className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
                      style={{ gap: "1.5rem" }}
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="w-100">
                        <Alert
                          variant="primary"
                          className="w-100 d-flex justify-content-start align-items-center px-2"
                        >
                          <InfoOutlinedIcon className="text-primary mr-1" />
                          <span className="text-dark">
                            If the Godparents/Sponsors cannot be physically
                            present for the Confirmation Mass, a proxy must be
                            present in their stead.
                          </span>
                        </Alert>
                        <Form.Group className="w-100">
                          {!canGodparentsAttendMass &&
                          canGodparentsAttendMass !== "" ? (
                            <>
                              <br />
                              <Form.Row className="align-items-center">
                                <Form.Label className="w-100 text-wrap">
                                  Proxy Godparent/Sponsor Name (as in Baptism
                                  Certificate)
                                </Form.Label>
                                <Form.Control
                                  disabled={currentPhase >= 2}
                                  size="lg"
                                  className="w-100"
                                  {...register("proxy.name", {
                                    required:
                                      typeof canGodparentsAttendMass ===
                                      "boolean"
                                        ? !canGodparentsAttendMass
                                        : false,
                                  })}
                                  isInvalid={!!errors.proxy?.name}
                                />
                              </Form.Row>
                              <br />
                              <Form.Row className="align-items-center">
                                <Form.Label className="w-100">
                                  Proxy Godparent/Sponsor Sex
                                </Form.Label>
                                <Form.Check
                                  disabled={currentPhase >= 2}
                                  className="w-100"
                                  type="radio"
                                  name="sex"
                                  label="Male"
                                  id="Male"
                                  value="Male"
                                  {...register("proxy.sex", {
                                    required:
                                      typeof canGodparentsAttendMass ===
                                      "boolean"
                                        ? !canGodparentsAttendMass
                                        : false,
                                  })}
                                  isInvalid={!!errors.proxy?.sex}
                                />
                                <Form.Check
                                  disabled={currentPhase >= 2}
                                  className="w-100"
                                  type="radio"
                                  name="sex"
                                  label="Female"
                                  id="Female"
                                  value="Female"
                                  {...register("proxy.sex", {
                                    required:
                                      typeof canGodparentsAttendMass ===
                                      "boolean"
                                        ? !canGodparentsAttendMass
                                        : false,
                                  })}
                                  isInvalid={!!errors.proxy?.sex}
                                />
                              </Form.Row>
                              <br />
                              <Form.Row className="align-items-center">
                                <Form.Label className="w-100 text-wrap">
                                  Has the Proxy Godparent/Sponsor received
                                  Baptism?
                                </Form.Label>
                                <Row className="w-100">
                                  <Controller
                                    control={control}
                                    name="proxy.isProxyBaptised"
                                    rules={{
                                      validate: (v) =>
                                        v === true || v === false,
                                    }}
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => (
                                      <>
                                        <Col xs="auto">
                                          <Form.Check
                                            disabled={currentPhase >= 2}
                                            className="w-100"
                                            type="radio"
                                            label="Yes"
                                            id="isProxyBaptisedTrue"
                                            onBlur={onBlur}
                                            onChange={() => onChange(true)}
                                            checked={value === true}
                                            ref={ref}
                                            isInvalid={
                                              !!errors.proxy?.isProxyBaptised
                                            }
                                          />
                                        </Col>
                                        <Col xs="auto">
                                          <Form.Check
                                            disabled={currentPhase >= 2}
                                            className="w-100"
                                            type="radio"
                                            label="No"
                                            id="isProxyBaptisedFalse"
                                            onBlur={onBlur}
                                            onChange={() => onChange(false)}
                                            checked={value === false}
                                            ref={ref}
                                            isInvalid={
                                              !!errors.proxy?.isProxyBaptised
                                            }
                                          />
                                        </Col>
                                      </>
                                    )}
                                  />
                                </Row>
                              </Form.Row>
                              <br />
                              <Form.Row className="align-items-center">
                                <Form.Label className="w-100 text-wrap">
                                  Has the Proxy Godparent/Sponsor received
                                  Confirmation?
                                </Form.Label>
                                <Row className="w-100">
                                  <Controller
                                    control={control}
                                    name="proxy.isProxyConfirmed"
                                    rules={{
                                      validate: (v) =>
                                        v === true || v === false,
                                    }}
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => (
                                      <>
                                        <Col xs="auto">
                                          <Form.Check
                                            disabled={currentPhase >= 2}
                                            className="w-100"
                                            type="radio"
                                            label="Yes"
                                            id="isProxyConfirmedTrue"
                                            onBlur={onBlur}
                                            onChange={() => onChange(true)}
                                            checked={value === true}
                                            ref={ref}
                                            isInvalid={
                                              !!errors.proxy?.isProxyConfirmed
                                            }
                                          />
                                        </Col>
                                        <Col xs="auto">
                                          <Form.Check
                                            disabled={currentPhase >= 2}
                                            className="w-100"
                                            type="radio"
                                            label="No"
                                            id="isProxyConfirmedFalse"
                                            onBlur={onBlur}
                                            onChange={() => onChange(false)}
                                            checked={value === false}
                                            ref={ref}
                                            isInvalid={
                                              !!errors.proxy?.isProxyConfirmed
                                            }
                                          />
                                        </Col>
                                      </>
                                    )}
                                  />
                                </Row>
                              </Form.Row>
                            </>
                          ) : null}
                        </Form.Group>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </>
            ) : null}
          </Card.Body>
        </Card>
        <Container className="px-0 mt-4">
          <Row>
            <Col xs={6}>
              <Link to="/catch" className="col">
                <Button variant="secondary" size="lg" className="w-100">
                  Back to Menu
                </Button>
              </Link>
            </Col>
            <Col xs={6}>
              <Button
                variant="primary"
                size="lg"
                className="w-100"
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty || isUploading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  return <ForbiddenRoute />;
}

export default function ConfirmationForm() {
  const { user } = useContext(AppGlobalContext);
  const activeRegistrationState = useActiveRegistration();
  const { isGuardian, wardsData } = useGuardian();
  const { selectedWardIndex } = useWardSelection();

  const statusName = activeRegistrationState.status;
  const ward = wardsData[selectedWardIndex];

  const isAccessingValidPage = isValidPage({
    isGuardian: isGuardian,
    statusName,
    page: "confirmation",
  });

  return (
    <>
      <div
        className="d-flex flex-column"
        style={{ minHeight: "calc(100vh - 4.5rem)" }}
      >
        <Header type="backandlogo" smallpic={user.photoURL} />
        <Container className="my-4 flex-grow-1 d-flex flex-column px-4">
          <h2 className="mb-2">Confimation Form</h2>
          {isAccessingValidPage ? (
            <ConfirmationDetails
              user={user}
              activeRegistrationState={activeRegistrationState}
              ward={ward}
            />
          ) : (
            <Card className="flex-grow-1 d-flex flex-column">
              <Card.Body
                className="d-flex flex-column justify-content-center align-items-center py-5"
                style={{ gap: "1.5rem" }}
              >
                <ForbiddenRoute />
              </Card.Body>
            </Card>
          )}
        </Container>
      </div>
    </>
  );
}
