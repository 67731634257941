import React from "react";
import ReloadModal from "../../common/reloadModal";
import Withdrawn from "./withdrawn";

function RejectedBallot() {
  return (
    <div className="mt-4 row justify-content-center mx-auto">
      <main className="container">
        <ReloadModal />
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow px-3">
            <Withdrawn />
          </div>
        </div>
      </main>
    </div>
  );
}

export default RejectedBallot;
