import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import SpinnerText from "../../common/spinnerwithText";
import { setSelectedItem, setShowConfirmModal } from "../redux/papalMassBallot";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

function SelectLeaderModal({ onLeaveGroup = () => {} }) {
  const dispatch = useDispatch();
  const { showConfirmModal, processing, acceptList, selectedItem } =
    useSelector((state) => state.papalMassBallot);

  // console.log(selectedItem);
  const handleSelect = (i) => {
    //select
    dispatch(setSelectedItem(i));
  };

  const handleBack = () => {
    //handle back
    dispatch(setShowConfirmModal(false));
  };

  return (
    <Modal
      show={showConfirmModal}
      animation={false}
      centered
      backdrop="static"
      keyboard={false}
    >
      <div className="text-center align-items-center m-4">
        <WarningRoundedIcon
          className="text-danger appearslowly"
          style={{ fontSize: "100px" }}
        />
        <p className="appearslowly text-dark font-weight-bold defaultfontsize text-center mt-2 mb-3">
          Please assign a new group leader before leaving this group.
        </p>

        <p className="appearslowly text-danger font-weight-bold text-left my-2">
          Please select one.
        </p>

        {acceptList.map((i) => {
          // console.log(i);
          // console.log(selectedItem);
          //get the name
          const email = i?.email; //`${BALLOT_EMAIL_PREFIX}${i?.id}`;
          const name = i?.name || "";
          const isSelected = i?.userid === selectedItem?.userid;
          // console.log(isSelected);
          return (
            <button
              variant=""
              key={email}
              onClick={() => handleSelect(i)}
              className={`btn ${
                isSelected ? "btn-primary" : "btn-outline-primary"
              } btn-block my-1 py-1 text-left`}
            >
              <div className="font-weight-bold">{name}</div>
              <div className="biggerfontsize">{email}</div>
            </button>
          );
        })}
        <p className="appearslowly text-dark biggersize text-left my-4">
          You will still be registered for <b>Individual Balloting</b>.
        </p>
        <p className="appearslowly text-dark biggersize text-left mt-0 mb-3">
          Any invitations you have sent out, which have yet to be accepted or
          declined, will be automatically withdrawn.
        </p>

        <div>
          <Button
            disabled={processing || !selectedItem}
            variant={"danger"}
            className="btn-lg mt-3 px-4"
            onClick={onLeaveGroup}
          >
            {!processing ? (
              <div className=" font-weight-bold">Select & Leave Group</div>
            ) : (
              <SpinnerText text={"Processing..."} />
            )}
          </Button>
        </div>
        <div>
          <Button
            disabled={processing}
            variant={"link"}
            className="btn-lg mt-3 biggerfontsize"
            onClick={handleBack}
          >
            Go Back
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default SelectLeaderModal;
