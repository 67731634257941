import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import { useSelector, useDispatch } from "react-redux";
import {
  setAcceptList,
  setInviteList,
  setProcessing,
  setScreen,
} from "../redux/papalMassBallot";
import {
  BALLOT_SCREEN_GROUP_CREATE_INIT,
  BALLOT_SCREEN_INDIVIDUAL_SUCCESS,
} from "../utils/papalMassConst";
import YoutubeTutorial from "./youtubeTutorial";
import ReloadModal from "../../common/reloadModal";

function NoGroupInvite() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser, showOptOut } = useSelector(
    (state) => state.papalMassBallot
  );

  const handleCreateGroup = async () => {
    dispatch(setProcessing(true));
    dispatch(setProcessing(false));
    dispatch(setAcceptList([]));
    dispatch(setInviteList([]));
    dispatch(setScreen(BALLOT_SCREEN_GROUP_CREATE_INIT));
  };

  const handleBack = async () => {
    dispatch(setScreen(BALLOT_SCREEN_INDIVIDUAL_SUCCESS));
  };

  return (
    <div className="mt-4 row justify-content-center mx-auto appearslowly">
      <ReloadModal />
      <main className="container">
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-4 text-center">
            <div className="mt-2 mx-auto justify-content-center d-flex text-center align-items-center text-info">
              <InfoRoundedIcon
                className={`text-info appearslowly bounce`}
                style={{ fontSize: "100px" }}
              />
            </div>
            <p className="appearslowly text-dark defaultfontsize  font-weight-bold  text-center my-2">
              You have not received any invites to join a group ballot.
            </p>
            <p className="appearslowly text-left my-3  alert alert-primary">
              Balloting as a group will allow all members of the group to be
              seated together at Mass. The balloting outcome applies to all
              group members.
            </p>
            <p className="appearslowly text-left my-2">
              Didn't find the invite? Ensure the group leader has entered the
              correct email address linked to your myCatholicSG account.
            </p>
            <div className="text-center appearslowly mb-3">
              <div className="">
                <Button
                  variant="primary"
                  className="btn-lg mt-4 px-5"
                  onClick={handleBack}
                >
                  <div className="mx-4 font-weight-bold">Go Back</div>
                </Button>
              </div>
              <div className="">
                <Button
                  variant={"link"}
                  className="btn-lg mt-4 biggerfontsize"
                  onClick={handleCreateGroup}
                >
                  <div className="">Create My Own Group</div>
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-0">
            <YoutubeTutorial />
          </div>
        </div>
      </main>
    </div>
  );
}

export default NoGroupInvite;
