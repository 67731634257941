// Redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import papalMassBallotReducer from "./papalMassBallot";

export default configureStore({
  reducer: {
    papalMassBallot: papalMassBallotReducer,
    // other reducers
  },
});
