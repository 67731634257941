import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import SpinnerText from "../../common/spinnerwithText";
import { setShowConfirmModal } from "../redux/papalMassBallot";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

function LeaveGroupModal({ onLeaveGroup = () => {} }) {
  const dispatch = useDispatch();
  const { showConfirmModal, processing } = useSelector(
    (state) => state.papalMassBallot
  );

  return (
    <Modal
      show={showConfirmModal}
      animation={false}
      centered
      backdrop="static"
      keyboard={false}
    >
      <div className="text-center align-items-center d-flex justify-content-center flex-column m-4">
        <WarningRoundedIcon
          className="text-danger appearslowly"
          style={{ fontSize: "100px" }}
        />
        <p className="appearslowly text-dark font-weight-bold defaultfontsize text-center mt-2 mb-3">
          Are you sure you want to leave this group?
        </p>
        <div className="px-2">
          <p className="appearslowly text-dark biggersize text-left mt-2 mb-3  alert alert-primary">
            You will still be registered for <b>Individual Balloting</b>.
          </p>
          <p className="px-0 appearslowly text-dark biggersize text-left mt-0 mb-3">
            Any unanswered invitations you have sent out will be automatically
            withdrawn.
          </p>
        </div>

        <Button
          disabled={processing}
          variant={"danger"}
          className="btn-lg mt-3 px-4"
          onClick={onLeaveGroup}
        >
          {!processing ? (
            <div className=" font-weight-bold">Yes, I am sure</div>
          ) : (
            <SpinnerText text={"Processing..."} />
          )}
        </Button>
        <Button
          disabled={processing}
          variant={"link"}
          className="btn-lg mt-3 biggerfontsize"
          onClick={() => dispatch(setShowConfirmModal(false))}
        >
          Go Back
        </Button>
      </div>
    </Modal>
  );
}

export default LeaveGroupModal;
