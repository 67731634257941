import React from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  setShowModal,
  setSelectedItem,
  setProcessing,
  setScreen,
  setAgree,
} from "../redux/papalMassBallot";
import {
  BALLOT_SCREEN_GROUP_CREATE_INIT,
  BALLOT_SCREEN_INDIVIDUAL_SUCCESS,
} from "../utils/papalMassConst";
import ConfirmJoinGroupModal from "./confirmJoinGroupModal";
import { getParishCompleteNameById } from "../../../services/parish";
import ReloadModal from "../../common/reloadModal";

function SelectInvitation() {
  const dispatch = useDispatch();
  const { invitationList } = useSelector((state) => state.papalMassBallot);

  const handleCreateGroup = async () => {
    dispatch(setProcessing(true));
    dispatch(setProcessing(false));
    dispatch(setScreen(BALLOT_SCREEN_GROUP_CREATE_INIT));
  };

  const handleBack = async () => {
    dispatch(setScreen(BALLOT_SCREEN_INDIVIDUAL_SUCCESS));
  };

  const handleSelect = async (i) => {
    //select
    dispatch(setSelectedItem(i));
    dispatch(setShowModal(true));
    dispatch(setAgree(false));
  };

  return (
    <div className="mt-4 row justify-content-center mx-auto appearslowly">
      <ReloadModal />
      <main className="container">
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-4 text-center pb-4">
            <p className="appearslowly text-dark defaultfontsize font-weight-bold text-center mt-2 mb-4">
              You have been invited to join the following Group(s):
            </p>
            <p className="appearslowly text-danger font-weight-bold text-left my-2">
              Please select one.
            </p>

            {invitationList.map((i) => {
              // console.log(i);
              //get the name
              const email = i?.email || ""; //`${BALLOT_EMAIL_PREFIX}${i?.id}`;
              const name = i?.name || "";
              const isSelected = false; // i?.id === selectedItem?.id;
              return (
                <div
                  key={email}
                  onClick={() => handleSelect(i)}
                  className={`btn btn-lg btn-block ${
                    isSelected ? "btn-primary" : "btn-outline-primary"
                  } my-1 py-1 text-left`}
                >
                  <div className="font-weight-bold">{name}</div>
                  <div className="biggerfontsize">{email}</div>
                  <div className="normalsmallfontsize">
                    {getParishCompleteNameById(i?.parish)}
                  </div>
                </div>
              );
            })}

            <p className="appearslowly text-left mt-3 mb-2 ">
              Balloting as a group will allow all members of the group to be
              seated together at Mass. The balloting outcome applies to all
              group members.
            </p>
            <p className="appearslowly text-left mt-3 mb-2 ">
              Didn't find the invite? Ensure the group leader has entered the
              correct email address linked to your myCatholicSG account.
            </p>
            <div className="">
              <Button
                variant="primary"
                className="btn-lg mt-4 px-5"
                onClick={handleBack}
              >
                <div className="mx-4 font-weight-bold">Go Back</div>
              </Button>
            </div>
            <div className="">
              <Button
                variant={"link"}
                className="mt-4 biggerfontsize"
                onClick={handleCreateGroup}
              >
                <div className="">Create My Own Group</div>
              </Button>
            </div>
          </div>
        </div>
      </main>
      <ConfirmJoinGroupModal />
    </div>
  );
}

export default SelectInvitation;
