import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setAgree,
  setInviteList,
  setScreen,
  setShowModal,
} from "../redux/papalMassBallot";
import {
  BALLOT_MAX_MEMBERS,
  BALLOT_SCREEN_GROUP_HAVE_INVITE,
  BALLOT_SCREEN_GROUP_NO_INVITE,
} from "../utils/papalMassConst";
import InviteEntry from "./inviteEntry";
import ConfirmCreateGroupModal from "./confirmCreateGroupModal";
import ReloadModal from "../../common/reloadModal";

function InitGroupInivte() {
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const { inviteList, invitationList, currentUser } = useSelector(
    (state) => state.papalMassBallot
  );

  const handleBack = async () => {
    if (invitationList?.length > 0) {
      dispatch(setScreen(BALLOT_SCREEN_GROUP_HAVE_INVITE));
    } else {
      dispatch(setScreen(BALLOT_SCREEN_GROUP_NO_INVITE));
    }
  };

  const handleCreateGroup = async (inputs) => {
    dispatch(setInviteList(inputs));
    dispatch(setShowModal(true));
    dispatch(setAgree(false));
  };

  return (
    <div className="mt-4 row justify-content-center mx-auto appearslowly">
      <ReloadModal />
      <main className="container">
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-4 text-center">
            <InviteEntry
              mode="create"
              limit={BALLOT_MAX_MEMBERS}
              entries={inviteList}
              onSubmit={handleCreateGroup}
              setProcess={(i) => setProcessing(i)}
              submitText="Create Group"
            />
            <ConfirmCreateGroupModal />
            <div className="text-center appearslowly mb-0">
              <div className="">
                <Button
                  disabled={processing}
                  variant={"link"}
                  className="btn-lg mt-0 biggerfontsize"
                  onClick={handleBack}
                >
                  <div className=" ">Go Back</div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default InitGroupInivte;
