import React, { useState } from "react";
import { Button } from "react-bootstrap";
import SpinnerText from "../../common/spinnerwithText";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setShowModal,
  setShowConfetti,
  setScreen,
} from "../redux/papalMassBallot";
import { updateUserBallotState } from "../service/ballot";
import { BALLOT_SCREEN_INIT, BALLOT_USER_INIT } from "../utils/papalMassConst";
import { Sleep } from "../../../utils/sleep";

function CancelBallot() {
  const [processing, setProcessing] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser, statusText } = useSelector(
    (state) => state.papalMassBallot
  );

  const updateHandler = async () => {
    setProcessing(true);
    try {
      await Sleep(500);
      await updateUserBallotState(currentUser?.userid, BALLOT_USER_INIT);
      dispatch(setShowConfetti(false));
      dispatch(setShowModal(false));
      dispatch(setScreen(BALLOT_SCREEN_INIT));
      history.push("/home");
    } catch (error) {
      //TODO: set to proper state and screen
      console.error("Failed to update user ballot status:", error);
    }
  };

  return (
    <div className="mt-4 row justify-content-center mx-auto appearslowly">
      <main className="container">
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-4 text-center">
            <div className="text-center">
              <ErrorRoundedIcon
                className="text-warning appearslowly bounce"
                style={{ fontSize: "100px" }}
              />
            </div>
            <p className="appearslowly text-dark defaultfontsize  font-weight-bold  text-center mt-2 mb-3">
              Your ballot registration has been cancelled.
            </p>
            <p className="appearslowly text-center mt-0 mb-3">{statusText}</p>
            <div className="text-center appearslowly pb-4">
              <div className="">
                <Button
                  disabled={processing}
                  variant="primary"
                  className="btn-lg mt-4 px-5"
                  onClick={updateHandler}
                >
                  {!processing ? (
                    <div className="mx-5 font-weight-bold">Done</div>
                  ) : (
                    <SpinnerText text={"Please wait..."} />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default CancelBallot;
