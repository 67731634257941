import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  BALLOT_SCREEN_GROUP_JOIN_SUCCESS,
  BALLOT_USER_JOIN_GROUP_SELECTED,
  PFS_REG_JOIN_GROUP_BALLOT,
  PFS_REG_JOIN_GROUP_BALLOT_NOK,
  PFS_REG_JOIN_GROUP_BALLOT_OK,
} from "../utils/papalMassConst";
import {
  setAgree,
  setErrorProcessing,
  setInvitationList,
  setMemberList,
  setProcessing,
  setScreen,
  setSelectedItem,
  setShowConfetti,
  setShowModal,
  setShowOptOut,
  setStatusText,
} from "../redux/papalMassBallot";
import SpinnerText from "../../common/spinnerwithText";
import {
  getFormattedDate,
  getUserObjToLog,
  surfaceLeader,
} from "../utils/utils";
import { logAction } from "../../../services/log";
import {
  getGroupDetails,
  joinGroup,
  updateUserBallotState,
} from "../service/ballot";
import { getParishCompleteNameById } from "../../../services/parish";
// import ErrorProcess from "./errorProcess";

function ConfirmJoinGroupModal() {
  const dispatch = useDispatch();
  const {
    currentUser,
    showModal,
    processing,
    agree,
    selectedItem,
    // errorProcessing,
  } = useSelector((state) => state.papalMassBallot);

  if (selectedItem === null) {
    return null;
  }
  // console.log(selectedItem);
  const doJoinGroup = async () => {
    const uid = currentUser?.userid;
    const userObj = getUserObjToLog(currentUser);
    dispatch(setProcessing(true));
    await logAction(uid, PFS_REG_JOIN_GROUP_BALLOT, userObj);
    try {
      const result = await joinGroup(uid, selectedItem.userid);
      // console.log(result);
      //if error, show error
      if (result?.data?.status === 0 || result?.data?.status === -1) {
        dispatch(setErrorProcessing(true));
        return;
      }
      const group = await getGroupDetails(uid);
      // console.log(group);
      dispatch(setMemberList(surfaceLeader(group?.data?.group)));
      await updateUserBallotState(uid, BALLOT_USER_JOIN_GROUP_SELECTED);
      const out = await logAction(uid, PFS_REG_JOIN_GROUP_BALLOT_OK, userObj);
      const date = getFormattedDate(new Date(out?.created));
      dispatch(setStatusText(date));
      //let's clear the invitationlist
      dispatch(setInvitationList([]));
      dispatch(setProcessing(false));
      dispatch(setShowConfetti(true));
      dispatch(setShowModal(false));
      dispatch(setShowOptOut(false));
      dispatch(setSelectedItem(null));
      dispatch(setScreen(BALLOT_SCREEN_GROUP_JOIN_SUCCESS));
    } catch (error) {
      await logAction(uid, PFS_REG_JOIN_GROUP_BALLOT_NOK, {
        ...userObj,
        error: error.message,
      });
      //TODO: set to proper state and screen
      console.error("Failed to update user ballot status:", error);
    }
  };

  return (
    <Modal
      show={showModal}
      animation={false}
      centered
      backdrop="static"
      keyboard={false}
    >
      {/* {!errorProcessing ? ( */}
      <div className="text-left align-items-center d-flex justify-content-center flex-column m-4">
        <p className="appearslowly text-dark defaultfontsize  font-weight-bold  text-left mt-2 mb-3">
          You are joining:
        </p>
        <div
          key={selectedItem?.email}
          className="roundsmallcornernocolor primaryframecolor my-1 p-2 text-left btn-block"
        >
          <div className="text-primary font-weight-bold">
            {selectedItem?.name}
          </div>
          <div className="text-primary biggerfontsize">
            {selectedItem?.email}
          </div>
          <div className="text-primary normalsmallfontsize">
            {getParishCompleteNameById(selectedItem?.parish)}
          </div>
        </div>
        <p className="appearslowly text-dark biggestfontsize  text-left mt-3 mb-3">
          By joining this group, all other group invites will be automatically
          declined.
        </p>

        <div className="form-group col-sm-12 text-center px-2">
          <div
            className="mx-0 text-left custom-control-lg custom-control custom-checkbox"
            style={{ zIndex: "0" }}
          >
            <input
              disabled={processing}
              className="custom-control-input"
              value={agree}
              id="agree"
              name="agree"
              type="checkbox"
              onChange={(e) => dispatch(setAgree(!agree))}
              defaultChecked={agree}
            />
            <label className="custom-control-label" htmlFor="agree">
              <span className="pointer font-weight-bold">I understand.</span>
            </label>
          </div>
        </div>
        <Button
          disabled={!agree || processing}
          variant={"primary"}
          className="btn-lg mt-3 px-4"
          onClick={doJoinGroup}
        >
          {!processing ? (
            <div className="mx-5 font-weight-bold">Join Group</div>
          ) : (
            <SpinnerText text={"Processing..."} />
          )}
        </Button>
        <Button
          disabled={processing}
          variant={"link"}
          className="btn-lg mt-3 biggerfontsize"
          onClick={() => {
            dispatch(setAgree(false));
            dispatch(setShowModal(false));
          }}
        >
          Go Back
        </Button>
      </div>
      {/* ) : (
        <ErrorProcess />
      )} */}
    </Modal>
  );
}

export default ConfirmJoinGroupModal;
