import React from "react";
import YoutubeWrapper from "../../common/youtubeWrapper";

function YoutubeTutorial() {
  return (
    <div className="m-0">
      <YoutubeWrapper
        titleCss="m-2"
        className="text-left mb-0"
        title="How to register as an individual"
        youtubeid="Q0UTCXI_EBM"
        frameid="pfs_ballot_individual"
        onPlay={() => {}}
      />
      <YoutubeWrapper
        titleCss="m-2"
        className="text-left mb-0"
        title="How to do group registration"
        youtubeid="9FIwSYOeymE"
        frameid="pfs_ballot_group"
        onPlay={() => {}}
      />
    </div>
  );
}

export default YoutubeTutorial;
