import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import InviteEntry from "./inviteEntry";
import { setInviteList, setShowInviteModal } from "../redux/papalMassBallot";
import { BALLOT_MAX_MEMBERS } from "../utils/papalMassConst";

export default function InviteEntryModal() {
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const { showInviteModal, inviteList, acceptList } = useSelector(
    (state) => state.papalMassBallot
  );

  const handleSubmit = async (inputs) => {
    //TODO: submit
    setProcessing(true);
    const newEmailList = [...inviteList, ...inputs];
    dispatch(setInviteList(newEmailList));
    dispatch(setShowInviteModal(false));
  };

  const handleBack = () => {
    dispatch(setShowInviteModal(false));
  };

  return (
    <Modal
      show={showInviteModal}
      animation={false}
      centered
      backdrop="static"
      keyboard={false}
    >
      <div className="p-4">
        <InviteEntry
          mode="add"
          limit={BALLOT_MAX_MEMBERS - inviteList?.length - acceptList?.length}
          entries={[]}
          setProcess={(i) => setProcessing(i)}
          onSubmit={handleSubmit}
          submitText="Submit"
          checkExisting={true}
        />
        <div className="text-center appearslowly">
          <Button
            disabled={processing}
            variant={"link"}
            className="btn-lg mt-0 biggerfontsize"
            onClick={handleBack}
          >
            <div className=" ">Go Back</div>
          </Button>
        </div>
      </div>
    </Modal>
  );
}
