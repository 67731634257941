import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ReloadModal from "../../common/reloadModal";
import DirectionsRunRoundedIcon from "@material-ui/icons/DirectionsRunRounded";

function UnknowStatus() {
  const history = useHistory();
  // console.log(ballotDetails);

  return (
    <div className="mt-4 row justify-content-center mx-auto">
      <main className="container">
        <ReloadModal />
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-4">
            <>
              <div className="text-center">
                <DirectionsRunRoundedIcon
                  className="text-warning appearslowly bounce"
                  style={{ fontSize: "100px" }}
                />
              </div>
              <p className="appearslowly font-weight-bold defaultfontsize text-center mt-4 mb-4">
                {/* Seems like we are unable to determine your current status. */}
                We are currently processing the ballot registration.
              </p>
              {/* <p className="mt-1 alert alert-primary">
                Please contact <b>mycatholicsg@catholic.org.sg</b> for
                assistance.
              </p> */}
              <p className="appearslowly normalsmallfontsize text-center mt-3 mb-3">
                Thank you very much for your patience and God bless!
              </p>
            </>

            <div className="appearslowly text-center">
              <div className="">
                <Button
                  variant="primary"
                  onClick={() => history.push("/home")}
                  className="px-5 btn-lg mt-4 biggerfontsize"
                >
                  Done
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default UnknowStatus;
