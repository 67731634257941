import React, { useEffect, useContext, useRef, useState } from "react";
import fire from "../../../../../services/fire";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";

import AppGlobalContext from "../../../../../AppGlobalContext";

import {
  useWardSelection,
  useGuardian,
  useRegistrationDetails,
  useParishesConfig,
} from "../../hooks/hooks";
import { registrationCollectionName } from "../../services/collections";
import detectParish from "../../services/detectParish";
import { registrationStatusNameToStatus } from "../../services/registrationStatus";

import SaveDraftConfirmationModal from "./SaveDraftConfirmationModal";

function handleSaveData(
  registrationCollection,
  registrationDetails,
  registrationData,
  ward,
  uid,
  setRegistrationStep,
  jumpTo,
  fromReview,
  setFromReview
) {
  if (!uid) {
    return () => {};
  }

  let registrationDoc;

  if (registrationDetails.id) {
    registrationDoc = registrationCollection.doc(registrationDetails.id);

    let outsideBoundaryReason = "";

    if (registrationDetails.boundary != registrationDetails.selectedParishId) {
      outsideBoundaryReason = registrationDetails.outsideBoundaryReason || "";
    } else if (
      registrationDetails.boundary == registrationDetails.selectedParishId
    ) {
      outsideBoundaryReason = "";
    }

    if (!registrationDetails.selectedParishId) {
      outsideBoundaryReason = "";
    }

    const docData = {
      ...registrationDetails,
      ...registrationData,
      outsideBoundaryReason: outsideBoundaryReason,
    };

    registrationDoc.update(docData).then(() => {
      if (jumpTo > 0) {
        if (fromReview) {
          setRegistrationStep(7);
          setFromReview(false);
        } else {
          setRegistrationStep(jumpTo);
        }

        window.scrollTo(0, 0);
      }
    });
  } else {
    registrationDoc = registrationCollection.doc();

    const docData = {
      id: registrationDoc.id,
      status: registrationStatusNameToStatus("unregistered"),
      submittedAt: new Date(),
      submittedBy: uid,
      child: {
        name: ward.name,
        uid: ward.uid,
      },
      payments: [],
      ...registrationData,
    };

    registrationDoc.set(docData).then(() => {
      if (jumpTo > 0) {
        if (fromReview) {
          setRegistrationStep(7);
          setFromReview(false);
        } else {
          setRegistrationStep(jumpTo);
        }

        window.scrollTo(0, 0);
      }
    });
  }
}

export default function AddressDetails({
  registrationStep,
  setRegistrationStep,
  fromReview,
  setFromReview,
}) {
  const registrationDetails = useRegistrationDetails();
  const parishesConfig = useParishesConfig();

  const { user } = useContext(AppGlobalContext);
  const { selectedWardIndex } = useWardSelection();
  const { wardsData } = useGuardian();

  const ward = wardsData[selectedWardIndex];

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      postalCode: "",
      roadName: "",
      blkNo: "",
      isAddressLanded: false,
      floorNumber: "",
      unitNumber: "",
      boundary: "",
    },
  });

  const inputPostalCode = watch("postalCode");
  const boundary = watch("boundary");
  const isAddressLanded = watch("isAddressLanded");

  const [inputCount, setInputCount] = useState(0);

  useEffect(() => {
    if (inputCount > 1) {
      setValue("roadName", "");
      setValue("blkNo", "");
      setValue("boundary", "");
      setValue("isAddressLanded", false);

      clearErrors("postalCode");
    } else {
      setInputCount(inputCount + 1);
    }
  }, [inputPostalCode]);

  const effectTriggeredRef = useRef(false);

  useEffect(() => {
    if (!effectTriggeredRef.current && registrationDetails.id) {
      effectTriggeredRef.current = true;
      reset({
        postalCode: registrationDetails.postalCode || "",
        roadName: registrationDetails.roadName || "",
        blkNo: registrationDetails.blkNo || "",
        isAddressLanded: registrationDetails.isAddressLanded ?? false,
        floorNumber: registrationDetails.floorNumber || "",
        unitNumber: registrationDetails.unitNumber || "",
        boundary: registrationDetails.boundary || "",
      });
    }

    return () => {
      effectTriggeredRef.current = false;
      reset();
    };
  }, [registrationDetails]);

  useEffect(() => {
    if (inputCount > 1) {
      setValue("floorNumber", "");
      setValue("unitNumber", "");
    }
  }, [isAddressLanded]);

  function onSubmit() {
    const registrationData = getValues();

    handleSaveData(
      registrationCollection,
      registrationDetails,
      registrationData,
      ward,
      user.uid,
      setRegistrationStep,
      registrationStep + 1,
      fromReview,
      setFromReview
    );
  }

  function onSaveDraft() {
    const registrationData = getValues();

    handleSaveData(
      registrationCollection,
      registrationDetails,
      registrationData,
      ward,
      user.uid,
      setRegistrationStep,
      0,
      fromReview,
      setFromReview
    );
  }

  async function getAddress() {
    const boundaries = await detectParish(inputPostalCode);

    if (boundaries !== -1) {
      if (boundaries.parishId) {
        setValue("roadName", boundaries.loc.ROAD_NAME);
        setValue("blkNo", boundaries.loc.BLK_NO);
        setValue("boundary", boundaries.parishId);

        clearErrors("postalCode");

        return () => {};
      }
    }

    setValue("roadName", "");
    setValue("blkNo", "");
    setValue("boundary", "");

    setError("postalCode");
  }

  let homeParish = "";

  if (boundary) {
    const boundayParish = parishesConfig.find(({ id }) => id == boundary);

    if (boundayParish) {
      homeParish = boundayParish.parish;
    }
  }

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <div
              className="position-absolute p-2 border-bottom border-left"
              style={{
                right: "0px",
                top: "0px",
                borderBottomLeftRadius: "0.25rem",
                backgroundColor: "#dee2e6",
              }}
            >
              2&nbsp;<span className="text-muted">of 4</span>
            </div>
            <h3>Child's Address</h3>
          </Container>
          <br />
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100 mb-0">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">
                    Enter your child's residential address
                  </Form.Label>
                  <Row className="w-100 mx-0">
                    <Col className="px-0 pr-sm-2" xs={12} sm={6}>
                      <Form.Control
                        size="lg"
                        className="w-100"
                        type="number"
                        maxLength={6}
                        placeholder="Enter postal code"
                        {...register("postalCode", { required: true })}
                        isInvalid={!!errors.postalCode}
                      />
                    </Col>
                    <Col className="px-0 pl-sm-2 mt-3 mt-sm-0" xs="auto">
                      <Button
                        variant={
                          inputPostalCode.length === 6 || boundary
                            ? "outline-primary"
                            : "primary"
                        }
                        size="lg"
                        className="w-100"
                        disabled={inputPostalCode.length !== 6}
                        onClick={getAddress}
                      >
                        {boundary ? "Edit Address" : "Get Address"}
                      </Button>
                    </Col>
                    {boundary ? (
                      <>
                        <Col className="px-0 pr-sm-2 mt-3" xs={12} sm={6}>
                          <Form.Control
                            size="lg"
                            className="w-100"
                            placeholder="Block no"
                            {...register("blkNo", { required: false })}
                            isInvalid={!!errors.blkNo}
                            disabled={true}
                          />
                        </Col>
                        <Col className="px-0 pl-sm-2 mt-3" xs={12} sm={6}>
                          <Form.Control
                            size="lg"
                            className="w-100"
                            placeholder="Street Name"
                            {...register("roadName", { required: false })}
                            isInvalid={!!errors.roadName}
                            disabled={true}
                          />
                        </Col>
                        <Col className="px-0 pr-sm-2 mt-3" xs={12} sm={6}>
                          <Form.Control
                            size="lg"
                            className="w-100"
                            placeholder="Please enter floor number"
                            {...register("floorNumber", {
                              required: isAddressLanded === false,
                            })}
                            isInvalid={!!errors.floorNumber}
                            disabled={isAddressLanded === true}
                          />
                        </Col>
                        <Col className="px-0 pl-sm-2 mt-3" xs={12} sm={6}>
                          <Form.Control
                            size="lg"
                            className="w-100"
                            placeholder="Please enter unit number"
                            {...register("unitNumber", {
                              required: isAddressLanded === false,
                            })}
                            isInvalid={!!errors.unitNumber}
                            disabled={isAddressLanded === true}
                          />
                        </Col>
                        <Col className="px-sm-2 mt-3" xs={12}>
                          <Form.Row className="align-items-center">
                            <Row className="w-100 mx-0">
                              <Form.Check
                                className="w-100"
                                type="checkbox"
                                name="isAddressLanded"
                                label="This address is a landed property (e.g. Bungalow/Semi-detached/Terrace)"
                                id="isAddressLanded"
                                {...register("isAddressLanded", {
                                  required: false,
                                })}
                                isInvalid={!!errors.isAddressLanded}
                              />
                            </Row>
                          </Form.Row>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Form.Row>
                {boundary ? (
                  <>
                    <br />
                    Based on your address, your home parish is{" "}
                    <span className="font-weight-bold">{homeParish}</span>.<br />
                    If you are registering with a different parish, you may
                    still proceed with your registration.
                  </>
                ) : (
                  ""
                )}
                <br />
                <input className="w-0 h-0 p-0 m-0 border-0" />
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Container className="px-0 mt-4">
        <Row>
          <Col xs={12} sm="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => {
                setRegistrationStep(registrationStep - 1);

                window.scrollTo(0, 0);
              }}
            >
              Back
            </Button>
          </Col>
          <Col className="d-none d-sm-flex" />
          <Col className="mt-3 mt-sm-0" xs="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => setShowModal(true)}
            >
              Save Draft
            </Button>
          </Col>
          <Col className="col mt-3 mt-sm-0" sm="auto">
            <Button
              variant="primary"
              size="lg"
              className="w-100"
              onClick={handleSubmit(onSubmit)}
              disabled={!boundary}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Container>
      {registrationDetails.id && registrationDetails.programmeType ? (
        <Container className="px-0 mt-4 text-wrap text-center">
          If you have enquiries, please contact your Parish Catechist
          Coordinator at&nbsp;
          <span className="text-primary" style={{ cursor: "pointer" }}>
            {registrationDetails.programmeType.enquiryEmail}
          </span>
        </Container>
      ) : (
        <></>
      )}
      <SaveDraftConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSaveDraft={onSaveDraft}
      />
    </>
  );
}
