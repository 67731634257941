import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import Input from "../../common/input";
import SpinnerText from "../../common/spinnerwithText";
import { declineInvitation, updateFirstBallotSeen } from "../service/ballot";
import { useDispatch, useSelector } from "react-redux";
import { setErrorProcessing } from "../redux/papalMassBallot";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ReloadModal from "../../common/reloadModal";
import { BALLOT_PHASE_4 } from "../utils/papalMassConst";
import JourneyWithPope from "./journeyWithPope";
import ConfettiExplosion from "react-confetti-explosion";

const largeProps = {
  force: 0.8,
  duration: 3000,
  particleCount: 200,
  width: 1600,
  // colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
};

function SpecialInvite({ img, showDecline = true }) {
  const [showModal, setShowModal] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [processing, setProcessing] = useState(false);
  const [done, setDone] = useState(false);
  const { currentUser, phase } = useSelector((state) => state.papalMassBallot);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = ({ currentTarget: input }) => {
    setConfirmText(input.value);
  };

  const doDecline = async () => {
    setProcessing(true);
    //decline
    const uid = currentUser?.userid;
    const result = await declineInvitation(uid);
    if (result?.data?.status === 0 || result?.data?.status === -1) {
      dispatch(setErrorProcessing(true));
      return;
    } else {
      //success
      if (phase === BALLOT_PHASE_4) {
        await updateFirstBallotSeen(
          currentUser?.userid,
          currentUser?.email,
          true
        );
      }
      setDone(true);
    }
    setProcessing(false);
  };

  return (
    <div className="mt-4 row justify-content-center mx-auto">
      <ReloadModal />
      <main className="container">
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow px-4 pt-3 pb-4">
            <div className="d-flex justify-content-center align-items-center">
              <ConfettiExplosion zIndex={10000} {...largeProps} />
            </div>
            {img}
            {/* <div className="mt-2 pb-0 mb-0 bg-white">
              <hr style={{ borderTop: "2px dashed #f0f0ef" }} />
            </div> */}
            <div className="mt-4 mx-auto justify-content-center d-flex text-center align-items-center">
              <DraftsOutlinedIcon
                className={` appearslowly text-success`}
                style={{ fontSize: "60px" }}
              />
            </div>

            <p className="appearslowly font-weight-bold defaultfontsize text-center my-3">
              You have been invited to attend the Papal Mass!
            </p>

            <p className="appearslowly normalsmallfontsize text-center my-2">
              We look forward to seeing you at the Papal Mass.
            </p>
            <p className="appearslowly normalsmallfontsize text-center my-3">
              God bless you{" "}
              <span role="img" aria-label="pray">
                🙏
              </span>
            </p>

            <div className="appearslowly text-center">
              <div className="">
                <Button
                  variant="primary"
                  onClick={() => history.push("/home")}
                  className="btn-lg my-4 biggerfontsize px-5"
                >
                  <div className="mx-4">Done</div>
                </Button>
              </div>
              {showDecline && (
                <div className="">
                  <Button
                    variant="link"
                    onClick={() => setShowModal(true)}
                    className="btn-lg mt-0 biggerfontsize text-danger"
                  >
                    Decline Invitation
                  </Button>
                </div>
              )}
            </div>
          </div>
          <JourneyWithPope code="inv" />
        </div>
      </main>
      <Modal
        show={showModal}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
      >
        {!done && (
          <div className="text-center align-items-center d-flex justify-content-center flex-column m-4">
            <p className="font-weight-bold appearslowly text-dark defaultfontsize text-left mt-2 mb-3">
              Are you sure you want to decline the invitation?
            </p>
            <p className="appearslowly text-dark defaultfontsize text-left mt-2 mb-3">
              You will lose your invited spot for the upcoming Papal Mass. You
              will still be able to participate in the ballot but will not be
              guaranteed a spot.
            </p>
            <p className="appearslowly text-dark defaultfontsize text-left mt-2 mb-3">
              Type <b>DECLINE</b> below if you are absolutely sure you wish to
              proceed{" "}
            </p>
            <Input
              disabled={processing}
              autoFocus={true}
              css="pl-0 pb-0 mt-2 mb-3 text-center"
              type="text"
              name={`email`}
              value={confirmText}
              label=""
              placeholder=""
              onChange={handleChange}
            />
            <Button
              disabled={processing || confirmText !== "DECLINE"}
              variant={"danger"}
              className="btn-lg mt-3 px-4"
              onClick={doDecline}
            >
              {!processing ? (
                <div className=" font-weight-bold">Decline Invitation</div>
              ) : (
                <SpinnerText text={"Processing..."} />
              )}
            </Button>
            <Button
              disabled={processing}
              variant={"link"}
              className="btn-lg mt-3 biggerfontsize"
              onClick={() => setShowModal(false)}
            >
              Go Back
            </Button>
          </div>
        )}
        {done && (
          <div className="text-center align-items-center d-flex justify-content-center flex-column m-4">
            <div className="appearslowly text-center">
              <CheckCircleOutlineIcon
                style={{ fontSize: "100px", color: "green" }}
              />
              <h3 className="my-4">
                You have successfully declined the invitation!
              </h3>
              <div className="">
                <Button
                  variant="primary"
                  onClick={() => history.push("/home")}
                  className="btn-lg my-4 biggerfontsize px-5"
                >
                  <div className="mx-4">Done</div>
                </Button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default memo(SpecialInvite);
