/**
 * Converts a 1D array into a csv row.
 * @param {string[]} row The row array to be processed.
 * @returns {string} The csv row string.
 */
function arrayRowToCsvRow(row) {
  return row.map((val) => `"${val.replace('"', "'")}"`).join(",");
}

/**
 * Converts a 2D array into a csv.
 * @param {string[][]} rows The array to be processed.
 * @param {string[]} [headerRow] (Optional) The array containing headers for the csv file.
 * @return {string} The csv string.
 */
function arrayToCsv(rows, headerRow) {
  let outStr = "";
  if (
    headerRow != null &&
    !(rows.length > 0 && headerRow.length !== rows[0].length)
  )
    outStr += arrayRowToCsvRow(headerRow) + "\n";
  outStr += rows.map(arrayRowToCsvRow).join("\n");
  return outStr;
}

/**
 * Converts a 2D array into a csv, and generates a download link.
 * @param {string} fileName Name to assign to the file.
 * @param {string[][]} rows The array to be processed.
 * @param {string[]} [headerRow] (Optional) The array containing headers for the csv file.
 * @return {HTMLAnchorElement} The element corresponding to the csv download link. Call the `click()` method to download.
 */
export function getCsvDownloadLink(fileName, rows, headerRow) {
  const csvContent = arrayToCsv(rows, headerRow);

  const link = document.createElement("a");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute("download", fileName);

  return link;
}
