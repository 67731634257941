import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import PapalMassBallot from "./page/papalMassBallot";

function MainPapalMassBallot() {
  return (
    <Provider store={store}>
      <PapalMassBallot />
    </Provider>
  );
}

export default MainPapalMassBallot;
