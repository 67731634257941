import React, { memo } from "react";
import { useSelector } from "react-redux";
import {
  BALLOT_SCREEN_SPECIAL_INVITE,
  BALLOT_SCREEN_VOLUNTEER,
  BALLOT_SCREEN_GROUP_NO_INVITE,
  BALLOT_SCREEN_GROUP_CREATE_INIT,
  BALLOT_SCREEN_INDIVIDUAL_CANCEL,
  BALLOT_SCREEN_INDIVIDUAL_SUCCESS,
  BALLOT_SCREEN_INIT,
  BALLOT_SCREEN_GROUP_CREATE_SUCCESS,
  BALLOT_SCREEN_GROUP_HAVE_INVITE,
  BALLOT_SCREEN_GROUP_JOIN_SUCCESS,
} from "../utils/papalMassConst";
import SpecialInvite from "./specialInvite";
import Volunteer from "./volunteerScreen";
import InitIndividual from "./initIndividual";
import IndividualSuccess from "./invidualSuccess";
import CancelBallot from "./cancelBallot";
import NoGroupInvite from "./noGroupInvite";
import InitGroupInivte from "./initGroupInvite";
import GroupSuccess from "./groupSuccess";
import SelectInvitation from "./selectInvitation";
import JoinGroupSuccess from "./joinGroupSuccess";

function GetUserScreen({ img }) {
  const { screen } = useSelector((state) => state.papalMassBallot);

  /*
    Screens:
    0 - init
    1 - individual
    2 - individual success
    3 - individual cancel
    4 - group
    5 - group success
    6 - group cancel
    7 - 
    ???
    */
  switch (screen) {
    case BALLOT_SCREEN_SPECIAL_INVITE:
      return <SpecialInvite img={img} />;
    case BALLOT_SCREEN_VOLUNTEER:
      return <Volunteer img={img} />;
    case BALLOT_SCREEN_INIT:
      return <InitIndividual img={img} />;
    case BALLOT_SCREEN_INDIVIDUAL_SUCCESS:
      return <IndividualSuccess />;
    case BALLOT_SCREEN_INDIVIDUAL_CANCEL:
      return <CancelBallot />;
    case BALLOT_SCREEN_GROUP_NO_INVITE:
      return <NoGroupInvite />;
    case BALLOT_SCREEN_GROUP_CREATE_INIT:
      return <InitGroupInivte />;
    case BALLOT_SCREEN_GROUP_CREATE_SUCCESS:
      return <GroupSuccess />;
    case BALLOT_SCREEN_GROUP_HAVE_INVITE:
      return <SelectInvitation />;
    case BALLOT_SCREEN_GROUP_JOIN_SUCCESS:
      return <JoinGroupSuccess />;
    default:
      return <InitIndividual img={img} />;
  }
}

export default memo(GetUserScreen);
