import { getDefaultRandomId } from "../../../utils/utils";
import {
  BALLOT_EMAIL_PREFIX,
  BALLOT_ROLE_LEADER,
  BALLOT_ROLE_MEMBER,
  BALLOT_SCREEN_SPECIAL_INVITE,
  BALLOT_SCREEN_GROUP_CREATE_SUCCESS,
  BALLOT_SCREEN_GROUP_JOIN_SUCCESS,
  BALLOT_SCREEN_VOLUNTEER,
  // BALLOT_SCREEN_INDIVIDUAL_CANCEL,
  BALLOT_SCREEN_INDIVIDUAL_SUCCESS,
  BALLOT_SCREEN_INIT,
  BALLOT_STATUS_UNREGISTERED,
  BALLOT_STATUS_INVITED,
  BALLOT_STATUS_REGISTERED,
  BALLOT_STATUS_VOLUNTEER,
  // BALLOT_USER_GROUP_CREATED,
  // BALLOT_USER_INDIVIDUAL_CANCELLED,
  // BALLOT_USER_INDIVIDUAL_SELECTED,
  // BALLOT_USER_INIT,
  // BALLOT_USER_JOIN_GROUP_SELECTED,
  // BALLOT_USER_LEAVE_GROUP_CREATED,
  // BALLOT_USER_LEAVE_JOINED_GROUP,
  // BALLOT_USER_SPECIAL_INVITE,
  // BALLOT_USER_VOLUNTEER,
} from "./papalMassConst";

export function getUserScreen(user) {
  if (!user) {
    return BALLOT_SCREEN_INIT;
  }

  if (user?.data?.status === 1) {
    const userData = user.data.user;

    if (!userData || userData?.ballotstatus === BALLOT_STATUS_UNREGISTERED)
      return BALLOT_SCREEN_INIT;

    if (userData?.ballotstatus === BALLOT_STATUS_INVITED)
      return BALLOT_SCREEN_SPECIAL_INVITE;

    if (userData?.ballotstatus === BALLOT_STATUS_VOLUNTEER)
      return BALLOT_SCREEN_VOLUNTEER;

    if (
      userData?.ballotstatus === BALLOT_STATUS_REGISTERED &&
      userData?.group === true
    ) {
      if (userData?.role === BALLOT_ROLE_MEMBER)
        return BALLOT_SCREEN_GROUP_JOIN_SUCCESS;
      if (userData?.role === BALLOT_ROLE_LEADER)
        return BALLOT_SCREEN_GROUP_CREATE_SUCCESS;
    }

    if (
      userData?.ballotstatus === BALLOT_STATUS_REGISTERED &&
      userData?.group === false
    ) {
      return BALLOT_SCREEN_INDIVIDUAL_SUCCESS;
    }
  }

  return BALLOT_SCREEN_INIT;

  //this will contain the logic to identify the screen
  // if (
  //   !user.hasOwnProperty("ballotstatus") ||
  //   user.ballotstatus === BALLOT_USER_INIT
  // ) {
  //   return BALLOT_SCREEN_INIT;
  // }
  //
  // switch (user.ballotstatus) {
  //   case BALLOT_USER_SPECIAL_INVITE:
  //     return BALLOT_SCREEN_SPECIAL_INVITE;
  //   case BALLOT_USER_INDIVIDUAL_SELECTED:
  //     return BALLOT_SCREEN_INDIVIDUAL_SUCCESS;
  //   case BALLOT_USER_INDIVIDUAL_CANCELLED:
  //     return BALLOT_SCREEN_INDIVIDUAL_CANCEL;
  //   case BALLOT_USER_GROUP_CREATED:
  //     return BALLOT_SCREEN_GROUP_CREATE_SUCCESS;
  //   case BALLOT_USER_LEAVE_GROUP_CREATED:
  //     return BALLOT_SCREEN_INDIVIDUAL_SUCCESS;
  //   case BALLOT_USER_VOLUNTEER:
  //     return BALLOT_SCREEN_VOLUNTEER;
  //   case BALLOT_USER_JOIN_GROUP_SELECTED:
  //     return BALLOT_SCREEN_GROUP_JOIN_SUCCESS;
  //   case BALLOT_USER_LEAVE_JOINED_GROUP:
  //     return BALLOT_SCREEN_INDIVIDUAL_SUCCESS;
  //   default:
  //     return BALLOT_SCREEN_INIT;
  // }
}

export function getUserObjToLog(user = null) {
  if (!user) {
    return {};
  }
  return {
    email: user?.email || "",
    fullname: user?.fullname || "",
    mobile: user?.mobile || "",
    parish: user?.parish || "",
    userid: user?.userid || "",
    //what else???
  };
}

export function getUserBallotObject(user = null) {
  if (!user) {
    return {};
  }
  return {
    email: user?.email || "",
    fullname: user?.fullname || "",
    mobile: user?.mobile || "",
    parish: user?.parish || "",
    userid: user?.userid || "",
    dob: user?.dob || "",
    catholic: user?.catholic || false,
    citizenship: user?.citizenship || "",
    created: user?.created || new Date().getTime(),
    ballotstatus: user?.ballotstatus || BALLOT_STATUS_UNREGISTERED,
    //what else???
  };
}

export function getFormattedDate(date = new Date().getTime()) {
  const ts = new Date(date);
  const formattedDate = ts.toLocaleString("en-GB", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return formattedDate;
}

export function getUserObject(user) {
  const id = getDefaultRandomId();
  return {
    id: id,
    email: user?.email || "",
    [`${BALLOT_EMAIL_PREFIX}${id}`]: user?.email || "",
    name: user?.fullname || "",
    parish: user?.parish || "",
    role: "",
    userid: user?.userid || "",
  };
}

export function setUserAsLeader(user) {
  return {
    ...user,
    role: BALLOT_ROLE_LEADER,
  };
}

export function getEmailsFromInviteList(inviteList) {
  const emails = [];
  for (let i = 0; i < inviteList.length; i++) {
    const entry = inviteList[i];
    const email = `${BALLOT_EMAIL_PREFIX}${entry.id}`;
    emails.push(entry[email]?.toLowerCase()?.trim());
  }
  // console.log(emails);
  return emails;
}

export function invitesToList(invites) {
  const list = [];
  for (let i = 0; i < invites.length; i++) {
    const entry = invites[i];
    //don't use inviteDate because they are not unique
    const id = Math.floor(Math.random() * 10000000); //entry?.inviteDate;
    const email = `${BALLOT_EMAIL_PREFIX}${id}`;
    list.push({ [email]: entry?.email || "", id: id || "" });
  }
  return list;
}

export function surfaceLeader(group) {
  if (!group) {
    return [];
  }
  //find the leader and put at the first entry
  const sortedGroup = group?.slice().sort((a, b) => {
    if (a.role === BALLOT_ROLE_LEADER) {
      return -1;
    }
    if (b.role === BALLOT_ROLE_LEADER) {
      return 1;
    }
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return sortedGroup;
}

export function extractTimestamp(ts) {
  const timestamp = ts;
  let date;
  if (typeof timestamp === "number" && timestamp % 1 === 0) {
    // timestamp is a whole number, treat it as milliseconds
    date = new Date(timestamp);
  } else {
    // timestamp is probably a string and looks like a date
    //assume it is formatted to be {_seconds: 1722678459, _nanoseconds: 55000000}
    date = new Date(timestamp?._seconds * 1000);
  }
  return date;
}
