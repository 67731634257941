import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ConfettiExplosion from "react-confetti-explosion";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import SpinnerText from "../../common/spinnerwithText";
import { useSelector, useDispatch } from "react-redux";
import {
  setShowModal,
  setScreen,
  setShowConfetti,
  setProcessing,
  setShowOptOut,
  setHeaderNotificationText,
  setInvitationList,
  setErrorProcessing,
  setStatusText,
} from "../redux/papalMassBallot";
import { getFormattedDate, getUserObjToLog } from "../utils/utils";
import {
  getUserGroupInvites,
  unRegisterUserBallot,
  updateUserBallotState,
} from "../service/ballot";
import { logAction } from "../../../services/log";
import {
  BALLOT_SCREEN_GROUP_HAVE_INVITE,
  BALLOT_SCREEN_GROUP_NO_INVITE,
  BALLOT_SCREEN_INDIVIDUAL_CANCEL,
  BALLOT_SIMULATE,
  BALLOT_USER_INDIVIDUAL_CANCELLED,
  PFS_REG_CANCEL_BALLOT,
  PFS_REG_CANCEL_BALLOT_OK,
} from "../utils/papalMassConst";
import { isEnablePFSGroupBallot } from "../../../services/settings";
import YoutubeTutorial from "./youtubeTutorial";
import ReloadModal from "../../common/reloadModal";

const largeProps = {
  force: 0.8,
  duration: 3000,
  particleCount: 200,
  width: 1600,
  // colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
};

function IndividualSuccess() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    currentUser,
    showModal,
    showConfetti,
    processing,
    showOptOut,
    invitationList,
    headerNotificationText,
    statusText,
    ballotDetails,
  } = useSelector((state) => state.papalMassBallot);
  const [instruction, setInstruction] = useState(false);

  const handleCancelBallot = async () => {
    const uid = currentUser?.userid;
    const userObj = getUserObjToLog(currentUser);
    dispatch(setProcessing(true));
    await logAction(uid, PFS_REG_CANCEL_BALLOT, userObj);
    try {
      const result = await unRegisterUserBallot(currentUser);
      // console.log(result);
      //if error, show error
      if (result?.data?.status === 0 || result?.data?.status === -1) {
        dispatch(setErrorProcessing(true));
        return;
      }
      await updateUserBallotState(uid, BALLOT_USER_INDIVIDUAL_CANCELLED);
      const out = await logAction(uid, PFS_REG_CANCEL_BALLOT_OK, userObj);
      const formattedDate = getFormattedDate(new Date(out?.created));
      dispatch(setStatusText(formattedDate));
      dispatch(setProcessing(false));
      dispatch(setScreen(BALLOT_SCREEN_INDIVIDUAL_CANCEL));
    } catch (error) {
      await logAction(uid, PFS_REG_CANCEL_BALLOT_OK, {
        ...userObj,
        error: error.message,
      });
      //TODO: set to proper state and screen
      console.error("Failed to update user ballot status:", error);
    }
  };

  const handleGroupOptions = async () => {
    const uid = currentUser?.userid;
    dispatch(setShowConfetti(false));
    dispatch(setProcessing(true));
    let invites = [];
    if (!BALLOT_SIMULATE) {
      const result = await getUserGroupInvites(uid);
      // console.log(result);
      // invites = await getUserGroupInvites(uid);
      if (result?.data?.status === 1) {
        //TODO: update status
        //success
        invites = result?.data?.invitations;
      }
    } else {
      invites = invitationList;
    }
    // console.log(invites);
    if (invites?.length > 0) {
      //show invite
      dispatch(setProcessing(false));
      dispatch(setInvitationList(invites));
      dispatch(setScreen(BALLOT_SCREEN_GROUP_HAVE_INVITE));
    } else {
      //show no invites
      dispatch(setProcessing(false));
      dispatch(setScreen(BALLOT_SCREEN_GROUP_NO_INVITE));
    }
  };

  return (
    <div className="mt-4 row justify-content-center mx-auto appearslowly">
      <ReloadModal />
      <main className="container">
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-4 text-center">
            <div className="text-center">
              {showConfetti && (
                <div className="d-flex justify-content-center align-items-center">
                  <ConfettiExplosion zIndex={10000} {...largeProps} />
                </div>
              )}
              <div
                className="mt-2 mx-auto justify-content-center d-flex text-center align-items-center"
                style={{
                  color: "#fff",
                  width: "90px",
                  height: "90px",
                  border: "2px solid #28a745",
                  backgroundColor: "#28a745",
                  borderRadius: "50%",
                }}
              >
                <CheckRoundedIcon
                  className={`text-white appearslowly ${
                    showModal ? "" : "reveal"
                  }`}
                  style={{ fontSize: "90px" }}
                />
              </div>
            </div>
            {ballotDetails?.hasOwnProperty("firstballotpass") &&
            ballotDetails?.firstballotpass === false ? (
              <p className="appearslowly text-dark defaultfontsize text-center mt-2 mb-4">
                You were unsuccessful in the 1st ballot and have been enrolled
                into the 2nd ballot
              </p>
            ) : (
              <p className="appearslowly text-dark defaultfontsize text-center mt-2 mb-4">
                {headerNotificationText}
                <b>Individual Balloting</b>!
              </p>
            )}
            <p className="appearslowly text-dark text-center mt-2 mb-3">
              {statusText}
            </p>

            <p className="appearslowly text-center mt-0 mb-3">
              <i>Results of the 2nd ballot will be released on 19 August.</i>
            </p>

            <div className="text-center appearslowly">
              <div className="">
                <Button
                  disabled={processing}
                  variant="primary"
                  className="btn-lg mt-4 px-5"
                  onClick={() => {
                    dispatch(setShowConfetti(false));
                    dispatch(setShowOptOut(true));
                    dispatch(
                      setHeaderNotificationText(
                        "You have successfully registered for "
                      )
                    );
                    history.push("/home");
                  }}
                >
                  <div className="mx-5 font-weight-bold">Done</div>
                </Button>
              </div>

              <div className="">
                <Button
                  disabled={processing}
                  variant={showOptOut ? "outline-primary" : "link"}
                  className="btn-lg mt-4 biggerfontsize"
                  onClick={
                    isEnablePFSGroupBallot()
                      ? handleGroupOptions
                      : () => setInstruction(true)
                  }
                >
                  {!processing ? (
                    <div className=" ">Join / Create Group</div>
                  ) : (
                    <SpinnerText text={"Please wait..."} />
                  )}
                </Button>
              </div>
              {showOptOut && (
                <div className="">
                  <Button
                    disabled={processing}
                    variant={"link"}
                    className="btn-lg text-danger mt-3 biggerfontsize"
                    onClick={() => {
                      dispatch(setShowConfetti(false));
                      dispatch(setShowModal(true));
                    }}
                  >
                    Cancel Ballot
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <YoutubeTutorial />
          </div>
        </div>
      </main>
      <Modal
        show={showModal}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
      >
        <div className="text-center align-items-center d-flex justify-content-center flex-column m-4">
          <WarningRoundedIcon
            className="text-danger appearslowly"
            style={{ fontSize: "100px" }}
          />
          <p className="appearslowly text-dark defaultfontsize  font-weight-bold  text-center mt-2 mb-3">
            Cancel your ballot registration?
          </p>
          <Button
            disabled={processing}
            variant={"danger"}
            className="btn-lg mt-3 px-4"
            onClick={handleCancelBallot}
          >
            {!processing ? (
              <div className=" font-weight-bold">Proceed, Cancel</div>
            ) : (
              <SpinnerText text={"Processing..."} />
            )}
          </Button>
          <Button
            disabled={processing}
            variant={"link"}
            className="btn-lg mt-3 biggerfontsize"
            onClick={() => dispatch(setShowModal(false))}
          >
            Go Back
          </Button>
        </div>
      </Modal>
      <Modal
        show={instruction}
        animation={false}
        centered
        backdrop="static"
        keyboard={false}
      >
        <div className="text-center align-items-center d-flex justify-content-center flex-column m-4">
          <p className="appearslowly text-dark defaultfontsize  text-left mt-2">
            <b>
              <span role="img" aria-label="warning" className="mr-2">
                🎉
              </span>
              Looking to group up?
            </b>
            <br />
            <br />
            The Digital Church Office is conducting final checks to ensure the
            robustness of the feature. Group Registration will be available from
            July 1, 2024.
          </p>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            disabled={processing}
            variant={"link"}
            className="btn-lg mb-3 biggerfontsize"
            onClick={() => setInstruction(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default IndividualSuccess;
