import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import ReloadModal from "../../common/reloadModal";
import JourneyWithPope from "./journeyWithPope";
import ConfettiExplosion from "react-confetti-explosion";

const largeProps = {
  force: 0.8,
  duration: 3000,
  particleCount: 200,
  width: 1600,
  // colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
};

function VolunteerScreen({ img }) {
  const history = useHistory();

  return (
    <div className="mt-4 row justify-content-center mx-auto">
      <main className="container">
        <ReloadModal />
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow px-4 pt-3 pb-4">
            <div className="d-flex justify-content-center align-items-center">
              <ConfettiExplosion zIndex={10000} {...largeProps} />
            </div>
            {img}
            {/* <div className="mt-3 pb-0 mb-1 bg-white">
              <hr style={{ borderTop: "2px dashed #f0f0ef" }} />
            </div> */}
            <div className="mt-2 mx-auto justify-content-center d-flex text-center align-items-center">
              <span role="img" aria-label="clap" style={{ fontSize: "80px" }}>
                👏
              </span>
            </div>

            <p className="appearslowly font-weight-bold defaultfontsize text-center mt-2 mb-3">
              You have signed up as a volunteer for the Papal Mass!
            </p>
            <p className="appearslowly normalsmallfontsize text-center my-2">
              We look forward to seeing you at the Papal Mass.
            </p>
            <p className="appearslowly normalsmallfontsize text-center my-3">
              God bless you{" "}
              <span role="img" aria-label="pray">
                🙏
              </span>
            </p>

            <div className="appearslowly text-center">
              <div className="">
                <Button
                  variant="link"
                  onClick={() => history.push("/home")}
                  className="btn-lg mt-4 biggerfontsize"
                >
                  Go Back
                </Button>
              </div>
            </div>
          </div>
          <JourneyWithPope code="vol" />
        </div>
      </main>
    </div>
  );
}

export default memo(VolunteerScreen);
