import React, { useState, useEffect } from "react";
import { Column, UseSortByState } from "react-table";
import Table from "../../../../common/table/catchIndex";
import { DocumentDatum, StatusDictionary } from "../../hooks/hooks";
import { getCsvDownloadLink } from "../../../../coworking/admin/service/csvUtil";
import { getParishNameById } from "../../../../../services/parish";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";

import GetAppIcon from "@material-ui/icons/GetApp";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

import ListButton from "./RegistrationListButton";

import StatusPill from "./StatusPill";
import { SvgIcon } from "@material-ui/core";
import { levelIdToName } from "../../services/levels";

/**
 * @template {string} Action
 * @typedef StaticActionProps
 * @prop {Action} action
 * @prop {SvgIcon} [Icon]
 * @prop {string} title
 * @prop {string} [variant]
 * @prop {boolean} [disabled]
 */
/**
 * @template {string} Status
 * @template {string} Action
 * @typedef {Partial<Record<Status, StaticActionProps<Action>[][]>>} StatusActionDictionary
 */

/**
 * @template {string} Status
 * @template {DocumentDatum<Status>} Datum
 * @template {string} Action
 * @typedef AbstractListProps
 * @prop {string} id Unique ID for the table.
 * @prop {StatusDictionary<Status>} statusDictionary Props for rendering each status' ToggleButton.
 * @prop {{name: string | JSX.Element, value: Status}[]?} radios Optional radio buttons for each status.
 * @prop {StatusActionDictionary<Status, Action>} statusActionDictionary Maps each active status to its array of radio button names.
 * @prop {Status} currentStatus The current selected status.
 * @prop {Column<Datum>[]} columnProps Columns props for the table.
 * @prop {Datum[]} rawDataProps Data props for the table.
 * @prop {UseSortByState<Datum>} sortByProps Data sorting props.
 * @prop {React.MutableRefObject<Datum[]>} selectedRowsRef Reference where selected rows will update to.
 * @prop {(action: Action, currentStatus?: Status) => Promise<void>} handleAction Callback to handle action.
 */

/**
 * Abstract component for registration & transfer lists.
 * @template {string} Status
 * @template {DocumentDatum<Status>} Datum
 * @template {string} Action
 * @param {AbstractListProps<Status, Datum, Action>} props
 */
export default function AbstractList({
  id,
  // statusDictionary,
  // radios,
  statusActionDictionary,
  currentStatus,
  columnProps,
  rawDataProps,
  sortByProps,
  selectedRowsRef,
  handleAction,
  // handleAssignClass,
  // handleSelectedStatus,
}) {
  const [searchKey, setSearchKey] = useState("");
  const [isHandlingAction, setIsHandlingAction] = useState(false);

  // useEffect(() => {
  //   if (id === "registration-table") {
  //     handleAssignClass(rawDataProps.filter(({ status }) => status === 1));
  //   }

  //   return () => {};
  // }, [rawDataProps]);

  /**
   * Wrapper for `handleAction` to update `isHandlingAction`.
   * @param {Action} action
   */
  const wrappedHandleAction = async (action) => {
    setIsHandlingAction(true);
    await handleAction(action, currentStatus);
    setIsHandlingAction(false);
  };

  async function handleExport() {
    // alert("This function is not implemented in this demo."); // TODO: Implement logic

    let headerRow;

    if (id === "registration-table") {
      headerRow = [
        "Name",
        "Sex",
        "Level",
        "Class",
        "Notes",
        "Main Contact Name",
        "Main Contact Email",
        "Main Contact No.",
        "Secondary Contact Name",
        "Secondary Contact Email",
        "Secondary Contact No.",
        "Sacraments",
        "Father's Religion",
        "Mother's Religion",
        "Remarks",
      ];
    } else {
      headerRow = [
        "Name",
        "Programme",
        "Level",
        "Class",
        "Sacraments",
        "Status",
        "From",
        "To",
        "Outside Boundary Reason",
        "Reason for transfer",
      ];
    }
    const registrationArray = [];
    const selectedData = selectedRowsRef.current;

    if (selectedData.length === 0) {
      alert("Please select row(s) first!");
      return;
    }

    for (const row of selectedData) {
      const arr = [];
      const sacraments = [];

      if (id === "registration-table") {
        if (row.sacraments?.baptism?.received) {
          sacraments.push("Baptism");
        }
        if (row.sacraments?.reconciliation?.received) {
          sacraments.push("Reconciliation");
        }
        if (row.sacraments?.eucharist?.received) {
          sacraments.push("Eucharist");
        }
        if (row.sacraments?.confirmation?.received) {
          sacraments.push("Confirmation");
        }

        arr.push(`${row.name}`);
        arr.push(`${row.sex}`);
        arr.push(levelIdToName(+row.level) ?? "");
        arr.push(row.assignedClass ?? "");
        arr.push(row.additionalInformation ?? "");
        arr.push(row.mainContact.name ?? "");
        arr.push(row.mainContact.email ?? "");
        arr.push(row.mainContact.mobileNo ?? "");
        arr.push(
          !row.secondaryContact.exists
            ? ""
            : row.secondaryContact.emergencies
            ? `${row.secondaryContact.name} (emergency only)`
            : row.secondaryContact.name
        );
        arr.push(
          !row.secondaryContact.exists
            ? ""
            : row.secondaryContact.emergencies
            ? `${row.secondaryContact.email} (emergency only)`
            : row.secondaryContact.email
        );
        arr.push(
          !row.secondaryContact.exists
            ? ""
            : row.secondaryContact.emergencies
            ? `${row.secondaryContact.mobileNo} (emergency only)`
            : row.secondaryContact.mobileNo
        );
        arr.push(`${sacraments.join(", ")}`);
        arr.push(row.fatherReligion ?? ""); // FATHER'S RELIGION
        arr.push(row.motherReligion ?? ""); // MOTHER'S RELIGION
        arr.push(row.notes?.map(({ note }) => note)?.join("\n") ?? ""); // ADMIN REMARKS
      } else {
        if (row.registration?.sacraments?.baptism?.received) {
          sacraments.push("Baptism");
        }
        if (row.registration?.sacraments?.reconciliation?.received) {
          sacraments.push("Reconciliation");
        }
        if (row.registration?.sacraments?.eucharist?.received) {
          sacraments.push("Eucharist");
        }
        if (row.registration?.sacraments?.confirmation?.received) {
          sacraments.push("Confirmation");
        }

        arr.push(`${row.registration.name}`);
        arr.push(
          `${
            id === "transfers-table"
              ? row.from.programmeName ?? ""
              : row.to.programmeName ?? ""
          }`
        );
        arr.push(levelIdToName(+row.registration.level) ?? "");
        arr.push(row.registration.assignedClass ?? "");
        arr.push(`${sacraments.join(", ")}`);
        arr.push(`${row.status}`);
        arr.push(`${row.from.parishName ?? ""}`);
        arr.push(`${row.to.parishName ?? ""}`);
        arr.push(`${row.outsideBoundaryReason}`);
        arr.push(`${row.transferReason}`);
      }

      registrationArray.push(arr);
    }

    const link = getCsvDownloadLink(
      id === "registration-table" ? "Registration.csv" : "Transfer.csv",
      registrationArray,
      headerRow
    );

    link.click();
  }

  return (
    <>
      <Row className="justify-content-between align-items-center">
        <Col lg>
          <InputGroup className="my-2" size="lg" style={{ minWidth: 300 }}>
            <InputGroup.Prepend>
              <InputGroup.Text id="search-icon">
                <SearchOutlinedIcon />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search-key"
              onChange={({ target: { value } }) => setSearchKey(value)}
            />
          </InputGroup>
        </Col>
        <Col lg>
          <Button
            size="lg"
            variant="outline-dark"
            className="my-2 pr-4 w-100"
            onClick={handleExport}
            style={{ minWidth: 300 }}
          >
            <GetAppIcon />{" "}
            <span className="font-weight-bold">Export as CSV</span>
          </Button>
        </Col>
      </Row>
      {currentStatus === "" ? (
        <Alert variant="info">
          <Alert.Heading>Action Bar</Alert.Heading>
          Please select a status from the{" "}
          <span className="font-weight-bold">Status</span> dropdown box above to
          access Admin Actions (Accept, Reject, etc.).
        </Alert>
      ) : (
        statusActionDictionary[currentStatus]?.map((row, index) => {
          return (
            !row.disabled && (
              <Row
                key={`action-row-${currentStatus}-${index}`}
                className="justify-content-between align-items-center"
              >
                {row.map((props) => {
                  return (
                    !props.disabled && (
                      <ListButton
                        key={props.title}
                        {...props}
                        handleAction={wrappedHandleAction}
                        disabled={props.disabled || isHandlingAction}
                        loading={isHandlingAction}
                      />
                    )
                  );
                })}
              </Row>
            )
          );
        })
      )}
      <Table
        id={id}
        // className="my-3"
        columnProps={columnProps}
        overrideColumnHash
        dataProps={rawDataProps}
        sortByEnabled
        sortByProps={sortByProps}
        styleProps={{ fontSize: "15px" }}
        filterProps={searchKey}
        rowSelectionEnabled
        updateSelectedRows={(rows) => (selectedRowsRef.current = rows)}
        pageSize={100}
      />
    </>
  );
}
