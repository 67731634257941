import React, { useEffect, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ConfettiExplosion from "react-confetti-explosion";
import {
  BALLOT_PHASE_6,
  BALLOT_ROLE_LEADER,
  BALLOT_STATUS_BALLOTED_SUCCESS,
} from "../utils/papalMassConst";
import ReloadModal from "../../common/reloadModal";
import { useDispatch, useSelector } from "react-redux";
import {
  extractTimestamp,
  getFormattedDate,
  surfaceLeader,
} from "../utils/utils";
import {
  getGroupDetails,
  updateFirstBallotSeen,
  updateSecondBallotSeen,
  withdrawBallot,
} from "../service/ballot";
import DisplayMembers from "./displayMembers";
import { setErrorProcessing } from "../redux/papalMassBallot";
import Input from "../../common/input";
import SpinnerText from "../../common/spinnerwithText";
import Withdrawn from "./withdrawn";
import DisplayInvitesNotJoin from "./displayInvitesNotJoin";
import JourneyWithPope from "./journeyWithPope";

const largeProps = {
  force: 0.8,
  duration: 3000,
  particleCount: 200,
  width: 1600,
  // colors: ["#041E43", "#1471BF", "#5BB4DC", "#FC027B", "#66D805"],
};

function BallotOutputSuccess() {
  const [processing, setProcessing] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [done, setDone] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const { currentUser, ballotStatus, ballotDetails, phase } = useSelector(
    (state) => state.papalMassBallot
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const firstballotseen = ballotDetails?.firstballotseen || false;
  const firstballotpass = ballotDetails?.firstballotpass || false;
  const firstballotinvites = ballotDetails?.firstballotinvites || [];
  const secondballotseen = ballotDetails?.secondballotseen || false;
  const secondballotpass = ballotDetails?.secondballotpass || false;
  const secondballotinvites = ballotDetails?.secondballotinvites || [];
  const isLeader = ballotDetails?.role === BALLOT_ROLE_LEADER ? true : false;

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    async function getData() {
      if (ballotDetails?.group) {
        const group = await getGroupDetails(currentUser?.userid);
        if (isMounted && group?.data?.group) {
          setMemberList(surfaceLeader(group?.data?.group));
        }
      }
    }

    getData();
    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setConfirmText(input.value);
  };

  const handleButton = async (type = "") => {
    if (firstballotpass === true && !firstballotseen) {
      //update
      await updateFirstBallotSeen(
        currentUser?.userid,
        currentUser?.email,
        true
      );
    }
    if (secondballotpass === true && !secondballotseen) {
      //update
      await updateSecondBallotSeen(
        currentUser?.userid,
        currentUser?.email,
        true
      );
    }

    // if (phase === BALLOT_PHASE_6) {
    //   //phase 6 withdrawal - PHASE 6 shows results of 2ND BALLOT
    //   if (!secondballotseen) {
    //     //set variable if not set before
    //     updateSecondBallotSeen(
    //       currentUser?.userid,
    //       currentUser?.email,
    //       true
    //     ).then(() => {
    //       console.log("Updated second ballot seen");
    //     });
    //   }
    // } else {
    //   //phase 4 withdrawal
    //   if (!firstballotseen) {
    //     updateFirstBallotSeen(
    //       currentUser?.userid,
    //       currentUser?.email,
    //       true
    //     ).then(() => {
    //       console.log("Updated first ballot seen");
    //     });
    //   }
    // }

    if (type === "Withdraw") {
      setShowModal(true);
    } else {
      history.push("/home");
    }
  };

  const doDecline = async () => {
    setProcessing(true);
    //decline
    const uid = currentUser?.userid;
    const result = await withdrawBallot(uid);
    if (result?.data?.status === 0 || result?.data?.status === -1) {
      dispatch(setErrorProcessing(true));
      return;
    } else {
      //success
      setDone(true);
    }
    setProcessing(false);
  };

  return (
    <div className="mt-4 row justify-content-center mx-auto">
      <main className="container">
        <ReloadModal />
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-0">
            <div className="d-flex justify-content-center align-items-center">
              <ConfettiExplosion zIndex={10000} {...largeProps} />
            </div>
            <div
              className="text-center background-div py-4"
              style={{ backgroundColor: "#ffc700" }}
            >
              <img
                width="200px"
                src="https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fpapalmass%2Fpopemobile.gif?alt=media&token=47b8569f-f040-4889-9792-d37484f7f429"
                alt="Pope Mobile"
              />
            </div>
            <p className="appearslowly font-weight-bold defaultfontsize text-center mt-4 mb-0 px-4">
              Your ballot to attend the Papal Mass on <b>12th September 2024</b>{" "}
              is successful!
            </p>
            {!ballotDetails?.group ? (
              <div className="mt-4 py-3 alert alert-primary text-center mx-4">
                {currentUser?.fullname && (
                  <div className="font-weight-bold defaultfontsize">
                    {currentUser?.fullname}
                  </div>
                )}
                {currentUser?.email && (
                  <div className="defaultfontsize">{currentUser?.email}</div>
                )}
                <div className="font-weight-bold defaultfontsize">
                  Individual Ballot
                </div>
                {ballotDetails?.hasOwnProperty("history") && (
                  <div className="mt-0 mediumfontsize">
                    {getFormattedDate(
                      new Date(
                        extractTimestamp(
                          ballotDetails?.history[
                            ballotDetails?.history?.length - 1
                          ]?.timestamp
                        )
                      )
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="px-4 py-4">
                {memberList?.length > 0 && (
                  <DisplayMembers memberList={memberList} />
                )}
                {firstballotinvites?.length > 0 && isLeader && (
                  <div className="mt-4">
                    <DisplayInvitesNotJoin
                      memberList={firstballotinvites}
                      text="1st"
                    />
                  </div>
                )}
                {secondballotinvites?.length > 0 && isLeader && (
                  <div className="mt-4">
                    <DisplayInvitesNotJoin
                      memberList={secondballotinvites}
                      text="2nd"
                    />
                  </div>
                )}
              </div>
            )}
            <p className="px-4 mt-1">
              Your ticket will be available here in September.
            </p>
            <p className="px-4 mt-1 text-muted">
              To issue your ticket, myCatholicSG will share necessary personal
              data with our ticketing partner, SISTIC. If you prefer not to
              share your data, please withdraw your attendance.
            </p>
            <div className="mb-4 appearslowly text-center">
              <div className="">
                <Button
                  variant="primary"
                  onClick={async () => {
                    await handleButton("Done");
                  }}
                  className="px-5 btn-lg mt-4 biggerfontsize"
                >
                  Done
                </Button>
                {ballotStatus === BALLOT_STATUS_BALLOTED_SUCCESS && (
                  <div className="">
                    <Button
                      variant="link"
                      onClick={async () => {
                        await handleButton("Withdraw");
                      }}
                      className="btn-lg mt-4 biggerfontsize text-danger"
                    >
                      Withdraw
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <JourneyWithPope code="rest" />
        </div>
      </main>
      <Modal
        show={showModal}
        animation={false}
        size="md"
        centered
        backdrop="static"
        keyboard={false}
      >
        {!done && (
          <div className="text-center d-flex justify-content-center flex-column m-4">
            <p className="font-weight-bold appearslowly text-dark defaultfontsize text-left mt-2 mb-3">
              You are about to withdraw your attendance from the Papal Mass.
            </p>
            <p className="appearslowly text-dark defaultfontsize text-left mt-2 mb-3">
              Your slot will be released and given to another person. You will
              not be allowed to participate in the ballot again.
            </p>
            <div className="appearslowly text-dark defaultfontsize text-left mt-2 mb-3">
              Please confirm that you are withdrawing for:
              <br />
              <Alert className="mt-3" variant="danger">
                <h4 className="m-0 font-weight-bold text-wrap">
                  {currentUser.email}
                </h4>
                <span className="font-weight-bold font-italic">
                  {currentUser.fullname}
                </span>
              </Alert>
            </div>
            <p className="appearslowly text-dark defaultfontsize text-left mt-0 mb-3">
              Enter <span className="font-weight-bold">WITHDRAW</span> below if
              you are absolutely sure you wish to proceed:
            </p>
            <Input
              disabled={processing}
              autoFocus={true}
              css="pl-0 pb-0 mt-2 mb-3 text-center align-self-center"
              type="text"
              name={`email`}
              value={confirmText}
              label=""
              placeholder=""
              onChange={handleChange}
            />
            <Button
              disabled={processing || confirmText !== "WITHDRAW"}
              variant={"danger"}
              className="btn-lg mt-3 px-4 align-self-center"
              onClick={doDecline}
            >
              {!processing ? (
                <div className="px-4 font-weight-bold">Withdraw</div>
              ) : (
                <SpinnerText text={"Processing..."} />
              )}
            </Button>
            <Button
              disabled={processing}
              variant={"link"}
              className="btn-lg mt-3 biggerfontsize align-self-center"
              onClick={() => setShowModal(false)}
            >
              Go Back
            </Button>
          </div>
        )}
        {done && (
          <div className="px-3">
            <Withdrawn />
          </div>
        )}
      </Modal>
    </div>
  );
}

export default BallotOutputSuccess;
