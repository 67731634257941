import React, { useEffect, useContext, useRef, useState } from "react";
import fire from "../../../../../services/fire";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { useForm, Controller } from "react-hook-form";

import AppGlobalContext from "../../../../../AppGlobalContext";

import {
  useWardSelection,
  useGuardian,
  useRegistrationDetails,
} from "../../hooks/hooks";
import { registrationCollectionName } from "../../services/collections";
import { registrationStatusNameToStatus } from "../../services/registrationStatus";
import { maritalStatusList, religionList } from "../../services/resources";

import SaveDraftConfirmationModal from "./SaveDraftConfirmationModal";

function handleSaveData(
  registrationCollection,
  registrationDetails,
  registrationData,
  ward,
  uid,
  setRegistrationStep,
  jumpTo,
  fromReview,
  setFromReview
) {
  if (!uid) {
    return () => {};
  }

  let registrationDoc;

  const mainContact = registrationData.mainContact;

  const secondaryContact = registrationData.secondaryContact;

  if (registrationDetails.id) {
    registrationDoc = registrationCollection.doc(registrationDetails.id);

    const docData = {
      ...registrationDetails,
      mainContact,
      secondaryContact,
    };

    registrationDoc.update(docData).then(() => {
      if (jumpTo > 0) {
        if (fromReview) {
          setRegistrationStep(7);
          setFromReview(false);
        } else {
          setRegistrationStep(jumpTo);
        }

        window.scrollTo(0, 0);
      }
    });
  } else {
    registrationDoc = registrationCollection.doc();

    const docData = {
      id: registrationDoc.id,
      status: registrationStatusNameToStatus("unregistered"),
      submittedAt: new Date(),
      submittedBy: uid,
      child: {
        name: ward.name,
        uid: ward.uid,
      },
      payments: [],
      ...registrationData,
      mainContact,
      secondaryContact,
    };

    registrationDoc.set(docData).then(() => {
      if (jumpTo > 0) {
        if (fromReview) {
          setRegistrationStep(7);
          setFromReview(false);
        } else {
          setRegistrationStep(jumpTo);
        }

        window.scrollTo(0, 0);
      }
    });
  }
}

export default function ContactDetails({
  registrationStep,
  setRegistrationStep,
  fromReview,
  setFromReview,
}) {
  const registrationDetails = useRegistrationDetails();

  const { user } = useContext(AppGlobalContext);
  const { selectedWardIndex } = useWardSelection();
  const { wardsData } = useGuardian();

  const ward = wardsData[selectedWardIndex];

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);

  const {
    register,
    control,
    watch,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mainContact: {
        name: "",
        relationship: "Father",
        relationshipOthers: "",
        maritalStatus: "",
        religion: "",
        marriedInCatholicChurch: "",
        parishInvolvement: "",
        email: "",
        mobileNo: "+65",
        homeNo: "+65",
        volunteer: false,
      },
      secondaryContact: {
        exists: "",
        name: "",
        relationship: "",
        relationshipOthers: "",
        legalGuardian: "",
        email: "",
        mobileNo: "+65",
        volunteer: false,
        emergencies: false,
      },
    },
  });

  const effectTriggeredRef = useRef(false);

  useEffect(() => {
    if (!effectTriggeredRef.current && registrationDetails.id) {
      effectTriggeredRef.current = true;
      const values = {
        mainContact: {
          name: "",
          relationship: "Father",
          relationshipOthers: "",
          maritalStatus: "",
          religion: "",
          marriedInCatholicChurch: "",
          parishInvolvement: "",
          email: "",
          mobileNo: "+65",
          homeNo: "+65",
          volunteer: false,
          contact: "",
        },
        secondaryContact: {
          exists: null,
          name: "",
          relationship: "",
          relationshipOthers: "",
          email: "",
          mobileNo: "+65",
          volunteer: false,
          emergencies: false,
        },
      };

      if (registrationDetails.mainContact) {
        values.mainContact = registrationDetails.mainContact;
      }

      if (registrationDetails.secondaryContact) {
        values.secondaryContact = registrationDetails.secondaryContact;
      }

      reset({ ...values });
    }

    return () => {
      effectTriggeredRef.current = false;
      reset();
    };
  }, [registrationDetails]);

  function onSubmit() {
    const registrationData = getValues();

    handleSaveData(
      registrationCollection,
      registrationDetails,
      registrationData,
      ward,
      user.uid,
      setRegistrationStep,
      registrationStep + 1,
      fromReview,
      setFromReview
    );
  }

  function onSaveDraft() {
    const registrationData = getValues();

    handleSaveData(
      registrationCollection,
      registrationDetails,
      registrationData,
      ward,
      user.uid,
      setRegistrationStep,
      0,
      fromReview,
      setFromReview
    );
  }

  const mainRelationship = watch("mainContact.Relationship");
  const secondaryContactExists = watch("secondaryContact.exists");
  const secondaryRelationship = watch("secondaryContact.relationship");
  const secondaryEmergencies = watch("secondaryContact.emergencies");

  let showMainOthers = false,
    showSecondary = false,
    showSecondaryOthers = false;

  if (mainRelationship === "Others") {
    showMainOthers = true;
  }

  if (secondaryContactExists) {
    showSecondary = true;
  }

  if (secondaryRelationship === "Others") {
    showSecondaryOthers = true;
  }

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>Preferred Contact</h3>
            <Alert
              variant="primary"
              className="d-flex justify-content-start align-items-center px-2"
            >
              <InfoOutlinedIcon className="text-primary mr-1" />
              <span className="text-dark">
                The Preferred Contact will be contacted for all catechetical
                matters and any emergencies.
              </span>
            </Alert>
          </Container>
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Name</Form.Label>
                  <Form.Control
                    size="lg"
                    className="w-100"
                    placeholder="Enter name"
                    {...register("mainContact.name", { required: true })}
                    isInvalid={!!errors.mainContact?.name}
                  />
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="d-flex flex-row w-100">
                    Relationship to child
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    as="select"
                    size="lg"
                    {...register("mainContact.relationship", {
                      required: true,
                    })}
                    isInvalid={!!errors.mainContact?.relationship}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    <option key="mainFather" value="Father">
                      Father
                    </option>
                    <option key="mainMother" value="Mother">
                      Mother
                    </option>
                    <option key="mainOthers" value="Others">
                      Others
                    </option>
                  </Form.Control>
                  {showMainOthers ? (
                    <Form.Control
                      size="lg"
                      className="w-100 mt-3"
                      placeholder="e.g. Grandparent"
                      {...register("mainContact.relationshipOthers", {
                        required: showMainOthers,
                      })}
                    />
                  ) : null}
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="d-flex flex-row w-100">
                    Religion
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    as="select"
                    size="lg"
                    {...register("mainContact.religion", { required: true })}
                    isInvalid={!!errors.mainContact?.religion}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    {religionList.map((v) => (
                      <option key={`maincontactreligion-${v}`} value={v}>
                        {v}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Marital Status&nbsp;
                    <span className="text-muted">(optional)</span>
                  </Form.Label>
                  <Form.Control
                    className="w-100"
                    as="select"
                    size="lg"
                    {...register("mainContact.maritalStatus", {
                      required: false,
                    })}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="text-muted font-italic"
                    >
                      Please select
                    </option>
                    {maritalStatusList.map((v) => (
                      <option key={`maincontactmarital-${v}`} value={v}>
                        {v}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Married in the Catholic Church?&nbsp;
                    <span className="text-muted">(optional)</span>
                  </Form.Label>
                  <Row className="w-100">
                    <Controller
                      control={control}
                      name="mainContact.marriedInCatholicChurch"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              label="Yes"
                              id="mainMarriedInCatholicChurchTrue"
                              onBlur={onBlur}
                              onChange={() => onChange(true)}
                              checked={value === true}
                              ref={ref}
                            />
                          </Col>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              label="No"
                              id="mainMarriedInCatholicChurchFalse"
                              onBlur={onBlur}
                              onChange={() => onChange(false)}
                              checked={value === false}
                              ref={ref}
                            />
                          </Col>
                        </>
                      )}
                    />
                  </Row>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Involved in a parish, ministry or community?&nbsp;
                    <span className="text-muted">(optional)</span>
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    className="w-100"
                    placeholder="e.g. Church Warden"
                    {...register("mainContact.parishInvolvement", {
                      required: false,
                    })}
                  />
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100">Email address</Form.Label>
                  <Form.Control
                    size="lg"
                    className="w-100"
                    placeholder="Enter email address"
                    {...register("mainContact.email", { required: true })}
                    isInvalid={!!errors.mainContact?.email}
                  />
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Col className="px-0 pr-sm-2" xs={12} sm={6}>
                    <Form.Label className="w-100">Mobile No.</Form.Label>
                    <Form.Control
                      size="lg"
                      className="w-100"
                      {...register("mainContact.mobileNo", {
                        required: true,
                        validate: (val) => /^\+65\s?\d{4}\s?\d{4}$/.test(val),
                      })}
                      isInvalid={!!errors.mainContact?.mobileNo}
                    />
                  </Col>
                  <Col className="px-0 pl-sm-2 mt-3 mt-sm-0" xs={12} sm={6}>
                    <Form.Label className="w-100 text-wrap">
                      Home Number&nbsp;
                      <span className="text-muted">(optional)</span>
                    </Form.Label>
                    <Form.Control
                      size="lg"
                      className="w-100"
                      {...register("mainContact.homeNo", { required: false })}
                    />
                  </Col>
                </Form.Row>
                <br />
                <Form.Row className="align-items-center">
                  <Row className="w-100 mx-0">
                    <Form.Check
                      className="w-100"
                      type="checkbox"
                      name="mainVolunteer"
                      label={
                        <>
                          I am available to volunteer for Catechetical
                          activities and agree to be contacted by The Catechists
                          for help if needed.&nbsp;
                          <span className="text-muted">(optional)</span>
                        </>
                      }
                      id="mainVolunteer"
                      {...register("mainContact.volunteer", {
                        required: false,
                      })}
                    />
                  </Row>
                </Form.Row>
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <br />
      <Card className="d-flex flex-column">
        <Card.Body className="d-flex flex-column align-items-center py-4">
          <br />
          <Container>
            <h3>Secondary Contact (Optional)</h3>
          </Container>
          <Form
            className="d-flex flex-column justify-content-center align-items-center w-100 px-4"
            style={{ gap: "1.5rem" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="w-100">
              <Form.Group className="w-100">
                <Form.Row className="align-items-center">
                  <Form.Label className="w-100 text-wrap">
                    Add a secondary contact?
                  </Form.Label>
                  <Row className="w-100">
                    <Controller
                      control={control}
                      name="secondaryContact.exists"
                      rules={{ validate: (v) => v === true || v === false }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              label="Yes"
                              id="secondaryContactTrue"
                              onBlur={onBlur}
                              onChange={() => onChange(true)}
                              checked={value === true}
                              ref={ref}
                              isInvalid={!!errors.secondaryContact?.exists}
                            />
                          </Col>
                          <Col xs="auto">
                            <Form.Check
                              className="w-100"
                              type="radio"
                              label="No"
                              id="secondaryContactFalse"
                              onBlur={onBlur}
                              onChange={() => onChange(false)}
                              checked={value === false}
                              ref={ref}
                              isInvalid={!!errors.secondaryContact?.exists}
                            />
                          </Col>
                        </>
                      )}
                    />
                  </Row>
                </Form.Row>
                {showSecondary ? (
                  <>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">Name</Form.Label>
                      <Form.Control
                        size="lg"
                        className="w-100"
                        placeholder="Enter name"
                        {...register("secondaryContact.name", {
                          required: showSecondary,
                        })}
                        isInvalid={!!errors.secondaryContact?.name}
                      />
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="d-flex flex-row w-100">
                        Relationship to child
                      </Form.Label>
                      <Form.Control
                        className="w-100"
                        as="select"
                        size="lg"
                        {...register("secondaryContact.relationship", {
                          required: showSecondary,
                        })}
                        isInvalid={!!errors.secondaryContact?.relationship}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          className="text-muted font-italic"
                        >
                          Please select
                        </option>
                        <option key="secondaryFather" value="Father">
                          Father
                        </option>
                        <option key="secondaryMother" value="Mother">
                          Mother
                        </option>
                        <option key="secondartOthers" value="Others">
                          Others
                        </option>
                      </Form.Control>
                      {showSecondaryOthers ? (
                        <Form.Control
                          size="lg"
                          className="w-100 mt-3"
                          placeholder="e.g. Grandparent"
                          {...register("secondaryContact.relationshipOthers", {
                            required: showSecondary && showSecondaryOthers,
                          })}
                        />
                      ) : null}
                    </Form.Row>
                    {/* <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">
                        Appointed as legal guardian?
                      </Form.Label>
                      <Row className="w-100">
                        <Col xs="auto">
                          <Form.Check
                            className="w-100"
                            type="radio"
                            label="Yes"
                            id="secondaryLegalGuardianTrue"
                            value="true"
                            {...register("secondaryContact.legalGuardian", { required: showSecondary })}
                            isInvalid={!!errors.secondaryContact?.legalGuardian}
                          />
                        </Col>
                        <Col xs="auto">
                          <Form.Check
                            className="w-100"
                            type="radio"
                            label="No"
                            id="secondaryLegalGuardianFalse"
                            value="false"
                            {...register("secondaryContact.legalGuardian", { required: showSecondary })}
                            isInvalid={!!errors.secondaryContact?.legalGuardian}
                          />
                        </Col>
                      </Row>
                    </Form.Row> */}
                    <br />
                    <Form.Row className="align-items-center">
                      <Form.Label className="w-100">Email address</Form.Label>
                      <Form.Control
                        size="lg"
                        className="w-100"
                        placeholder="Enter email address"
                        {...register("secondaryContact.email", {
                          required: showSecondary,
                        })}
                        isInvalid={!!errors.secondaryContact?.email}
                      />
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Col className="px-0 pr-sm-2" xs={12} sm={6}>
                        <Form.Label className="w-100">Mobile No.</Form.Label>
                        <Form.Control
                          size="lg"
                          className="w-100"
                          {...register("secondaryContact.mobileNo", {
                            required: showSecondary,
                            validate: (val) =>
                              /^\+65\s?\d{4}\s?\d{4}$/.test(val),
                          })}
                          isInvalid={!!errors.secondaryContact?.mobileNo}
                        />
                      </Col>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Row className="w-100 mx-0">
                        <Form.Check
                          className="w-100"
                          type="checkbox"
                          name="secondaryVolunteer"
                          label={
                            <>
                              I am available to volunteer for Catechetical
                              activities and agree to be contacted by The
                              Catechists for help if needed.&nbsp;
                              <span className="text-muted">(optional)</span>
                            </>
                          }
                          id="secondaryVolunteer"
                          {...register("secondaryContact.volunteer", {
                            required: false,
                          })}
                        />
                      </Row>
                    </Form.Row>
                    <br />
                    <Form.Row className="align-items-center">
                      <Row className="w-100 mx-0">
                        <Form.Check
                          className="w-100"
                          type="checkbox"
                          name="secondaryEmergencies"
                          label="Only to be contacted for emergencies"
                          id="secondaryEmergencies"
                          {...register("secondaryContact.emergencies", {
                            required: false,
                          })}
                          isInvalid={!!errors.secondaryContact?.emergencies}
                        />
                      </Row>
                    </Form.Row>
                    {secondaryEmergencies === true ? (
                      <Alert
                        variant="warning"
                        className="d-flex justify-content-start align-items-center px-2"
                      >
                        <InfoOutlinedIcon className="text-warning mr-1" />
                        <span className="text-dark">
                          The Secondary Contact will only be contacted for
                          emergencies.
                        </span>
                      </Alert>
                    ) : secondaryEmergencies === false ? (
                      <Alert
                        variant="primary"
                        className="d-flex justify-content-start align-items-center px-2"
                      >
                        <InfoOutlinedIcon className="text-primary mr-1" />
                        <span className="text-dark">
                          The Secondary Contact will be contacted for all
                          catechetical matters and any emergencies.
                        </span>
                      </Alert>
                    ) : null}
                  </>
                ) : null}
              </Form.Group>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Container className="px-0 mt-4">
        <Row>
          <Col xs={12} sm="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => {
                setRegistrationStep(registrationStep - 1);

                window.scrollTo(0, 0);
              }}
            >
              Back
            </Button>
          </Col>
          <Col className="d-none d-sm-flex" />
          <Col className="mt-3 mt-sm-0" xs="auto">
            <Button
              variant="outline-primary"
              size="lg"
              className="w-100"
              onClick={() => setShowModal(true)}
            >
              Save Draft
            </Button>
          </Col>
          <Col className="col mt-3 mt-sm-0" sm="auto">
            <Button
              variant="primary"
              size="lg"
              className="w-100"
              onClick={handleSubmit(onSubmit)}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Container>
      {registrationDetails.id && registrationDetails.programmeType ? (
        <Container className="px-0 mt-4 text-wrap text-center">
          If you have enquiries, please contact your Parish Catechist
          Coordinator at&nbsp;
          <span className="text-primary" style={{ cursor: "pointer" }}>
            {registrationDetails.programmeType.enquiryEmail}
          </span>
        </Container>
      ) : (
        <></>
      )}
      <SaveDraftConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSaveDraft={onSaveDraft}
      />
    </>
  );
}
