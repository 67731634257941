import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
  MutableRefObject,
} from "react";
import { Switch, Route } from "react-router-dom";
import LoadingSpinner from "../../../common/loadingSpinner";

import CatchUserMenu from "../routes/UserMenu";
// import CatchRegistrationReview from "../routes/RegistrationReview";
import CatchContactUpdate from "../routes/ContactUpdate";
import CatchParishTransfer from "../routes/ParishTransfer";
import CatchClassFund from "../routes/ClassFund";
import CatchRegistrationForm from "../routes/RegistrationForm";
import CatchConfirmationForm from "../routes/ConfirmationForm";
import CatchGuardianControl from "../routes/GuardianControl";
import CatchWardControl from "../routes/WardControl";

import fire from "../../../../services/fire";
import { firestore } from "firebase";
import Moment from "moment-timezone";

import AppGlobalContext from "../../../../AppGlobalContext";
import {
  ParishesConfigContext,
  QueriedUserContext,
  QueriedUserState,
  DynamicUserContext,
  DynamicUserState,
  RegistrationDatum,
  TransfersDatum,
  GuardianCodeDatum,
  GuardianState,
} from "../hooks/hooks";
import {
  parishesConfigCollectionName,
  guardianCodesCollectionName,
  registrationCollectionName,
  transfersCollectionName,
  catchConfirmationFormsCollectionName,
} from "../services/collections";
import { registrationStatusToStatusName } from "../services/registrationStatus";
import { getLatestVersion } from "../../../../services/getversion";
import ReloadModal from "../../../common/reloadModal";

function handleGetParishesConfig(setParishesConfig, parishesConfigCollection) {
  return parishesConfigCollection.onSnapshot((snapshot) => {
    const newDatum = [];

    snapshot.forEach((doc) => {
      const docData = doc.data();
      const programmes = docData.programmes.map((programme) => ({
        ...programme,
        label: programme.name,
      }));

      docData.programmes = programmes;
      newDatum.push(docData);
    });

    setParishesConfig(newDatum);
  });
}

/**
 * @template Datum
 * @callback UserQueryHandler
 * @param {string} [uid]
 * @param {Dispatch<SetStateAction<QueriedUserState>>} setQueriedUserState
 * @param {firestore.CollectionReference<Datum>} collection
 * @return {() => void} Unsubscribe method
 */

/**
 * @type {UserQueryHandler<GuardianCodeDatum>}
 */
async function handleGuardianQuery(
  uid,
  setGuardianState,
  guardianCodesCollection,
  isGuardian
) {
  if (!uid || !isGuardian) {
    const guardianState = {
      isGuardian,
      wardsData: [],
    };

    setGuardianState(guardianState);

    return () => {};
  }

  try {
    return await guardianCodesCollection
      .where("guardianId", "==", uid)
      .where("status", "==", "consumed")
      .onSnapshot(async (snapshot) => {
        const wardIds = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const { wardId, wardName } = doc.data();
            return { uid: wardId, name: wardName };
          })
        );

        const guardianState = {
          isGuardian: true,
          wardsData: wardIds,
        };

        setGuardianState(guardianState);
      });
  } catch (error) {
    console.error("An error occurred:", error);

    const guardianState = {
      isGuardian,
      wardsData: [],
    };

    setGuardianState(guardianState);

    return () => {};
  }
}

/**
 * @type {UserQueryHandler<RegistrationDatum>}
 */
function handleActiveRegistrationQuery(
  uid,
  setQueriedUserState,
  registrationCollection
) {
  // console.log({ queryUid: uid });
  if (!uid) {
    const activeRegistrationState = {
      exists: false,
      data: null,
      status: "empty",
    };

    // console.log("Queried active registration:", { activeRegistrationState });
    setQueriedUserState((state) => ({ ...state, activeRegistrationState }));
    return () => {};
  }

  return registrationCollection
    .where("child.uid", "==", uid)
    .orderBy("submittedAt", "desc")
    .orderBy("status")
    .limit(1)
    .onSnapshot((snapshot) => {
      const datum = snapshot.docs[0]?.data();
      const activeRegistrationState = snapshot.empty
        ? { exists: false, data: null, status: "unregistered" }
        : {
            exists: true,
            data: datum,
            status: registrationStatusToStatusName(datum.status),
          };

      // console.log("Queried active registration:", {
      //   activeRegistrationState,
      // });
      setQueriedUserState((state) => ({
        ...state,
        activeRegistrationState,
      }));
    });
}

/**
 * @type {UserQueryHandler<TransfersDatum>}
 */
function handlePendingTransferQuery(
  uid,
  setQueriedUserState,
  transfersCollection
) {
  if (!uid) {
    const pendingTransferState = { exists: false, data: null };

    // console.log("Queried pending transfer:", { pendingTransferState });
    setQueriedUserState((state) => ({ ...state, pendingTransferState }));
    return () => {};
  }

  return transfersCollection
    .where("child.uid", "==", uid)
    .where("status", "in", ["pendingOut", "pendingIn"])
    .limit(1)
    .onSnapshot((snapshot) => {
      const pendingTransferState = snapshot.empty
        ? { exists: false, data: null }
        : { exists: true, data: snapshot.docs[0].data() };

      // console.log("Queried pending transfer:", { pendingTransferState });
      setQueriedUserState((state) => ({ ...state, pendingTransferState }));
    });
}

function handlePendingConfirmationQuery(
  uid,
  setQueriedUserState,
  catchConfirmationFormsCollection
) {
  if (!uid) {
    const pendingConfirmationState = { exists: false, data: null };

    // console.log("Queried pending confirmation:", { pendingConfirmationState });
    setQueriedUserState((state) => ({ ...state, pendingConfirmationState }));
    return () => {};
  }

  return catchConfirmationFormsCollection
    .where("child.uid", "==", uid)
    .limit(1)
    .onSnapshot((snapshot) => {
      const pendingConfirmationState = snapshot.empty
        ? { exists: false, data: null }
        : { exists: true, data: snapshot.docs[0].data() };

      // console.log("Queried pending confirmation:", { pendingConfirmationState });
      setQueriedUserState((state) => ({ ...state, pendingConfirmationState }));
    });
}

export default function RouteManager() {
  const { user } = useContext(AppGlobalContext);
  // console.log({ currentUid: user.uid });
  // console.log(pathname);

  const db = fire.firestore();

  const parishesConfigCollection = db.collection(parishesConfigCollectionName);

  /** @type {firestore.CollectionReference<GuardianCodeDatum>} */
  const guardianCodesCollection = db.collection(guardianCodesCollectionName);

  /** @type {firestore.CollectionReference<RegistrationDatum>} */
  const registrationCollection = db.collection(registrationCollectionName);
  /** @type {firestore.CollectionReference<TransfersDatum>} */
  const transfersCollection = db.collection(transfersCollectionName);

  const catchConfirmationFormsCollection = db.collection(
    catchConfirmationFormsCollectionName
  );

  // console.log({ uid: user.uid });
  const isGuardian = Moment.tz("Asia/Singapore").year() - +user.dob >= 21;

  const [parishesConfig, setParishesConfig] = useState([]);
  /** @type {[GuardianState | undefined, Dispatch<SetStateAction<GuardianState>>]} */
  const [guardianState, setGuardianState] = useState();
  const [selectedWardIndex, setSelectedWardIndex] = useState(-1);
  const [version, setVersion] = useState(null);

  /** @type {DynamicUserState} */
  const dynamicUserState = {
    guardianState,
    wardSelectionState: { selectedWardIndex, setSelectedWardIndex },
  };

  /** @type {[Partial<QueriedUserState>, Dispatch<SetStateAction<Partial<QueriedUserState>>>]} */
  const [queriedUserState, setQueriedUserState] = useState({
    activeRegistrationState: undefined,
    pendingTransferState: undefined,
    pendingConfirmationState: undefined,
  });
  // console.log({ queriedUserState });

  /** @type {MutableRefObject<(() => void)} */
  const unsubUser = useRef(() => {});
  /** @type {MutableRefObject<(() => void)} */
  const unsubDynamic = useRef(() => {});

  useEffect(() => {
    unsubUser.current();

    const { uid } = user;
    const currentUnsubs = [
      handleGetParishesConfig(setParishesConfig, parishesConfigCollection),
      handleGuardianQuery(
        uid,
        setGuardianState,
        guardianCodesCollection,
        isGuardian
      ),
    ];

    if (!isGuardian) {
      currentUnsubs.push(
        handleActiveRegistrationQuery(
          uid,
          setQueriedUserState,
          registrationCollection
        ),
        handlePendingTransferQuery(
          uid,
          setQueriedUserState,
          transfersCollection
        ),
        handlePendingConfirmationQuery(
          uid,
          setQueriedUserState,
          catchConfirmationFormsCollection
        )
      );
    }

    getLatestVersion().then((version) => setVersion(version));

    unsubUser.current = () =>
      currentUnsubs.forEach((unsub) => {
        if (typeof unsub !== "function") {
          return;
        }
        return unsub();
      });
    return unsubUser.current;
  }, [user]);

  useEffect(() => {
    if (isGuardian && guardianState !== undefined) {
      unsubDynamic.current();
      setQueriedUserState({
        activeRegistrationState: undefined,
        pendingTransferState: undefined,
        pendingConfirmationState: undefined,
      });

      const { uid } = guardianState.wardsData[selectedWardIndex] ?? {};
      const currentUnsubs = [
        handleGetParishesConfig(setParishesConfig, parishesConfigCollection),
        handleActiveRegistrationQuery(
          uid,
          setQueriedUserState,
          registrationCollection
        ),
        handlePendingTransferQuery(
          uid,
          setQueriedUserState,
          transfersCollection
        ),
        handlePendingConfirmationQuery(
          uid,
          setQueriedUserState,
          catchConfirmationFormsCollection
        ),
      ];

      unsubDynamic.current = () => currentUnsubs.forEach((unsub) => unsub());
      return unsubDynamic.current;
    }
  }, [guardianState, selectedWardIndex]);

  // console.log("Render: Route Manager");

  return dynamicUserState.guardianState === undefined ||
    Object.values(queriedUserState).some((state) => state === undefined) ? (
    <LoadingSpinner />
  ) : (
    <ParishesConfigContext.Provider value={parishesConfig}>
      <DynamicUserContext.Provider value={dynamicUserState}>
        <QueriedUserContext.Provider value={queriedUserState}>
          {version && <ReloadModal version={version} />}
          <Switch>
            {/* <Route component={CatchRegistrationReview} path="/catch/registrationreview" /> */}
            <Route component={CatchContactUpdate} path="/catch/contactupdate" />
            <Route
              component={CatchParishTransfer}
              path="/catch/parishtransfer"
            />
            <Route component={CatchRegistrationForm} path="/catch/register" />
            <Route component={CatchClassFund} path="/catch/classfund" />
            <Route
              component={CatchConfirmationForm}
              path="/catch/confirmation"
            />
            <Route component={CatchGuardianControl} path="/catch/guardian" />
            <Route component={CatchWardControl} path="/catch/ward" />
            <Route component={CatchUserMenu} path="/catch" />
          </Switch>
        </QueriedUserContext.Provider>
      </DynamicUserContext.Provider>
    </ParishesConfigContext.Provider>
  );
}
