import React from "react";
import { Button } from "react-bootstrap";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";

function PapalMassPlaceholder({ user }) {
  const handleBackToHome = () => {
    window.open("/home", "_self");
  };

  return (
    <div className="mt-4 row justify-content-center mx-auto">
      <main className="container">
        <div className="col-lg-6 px-0 mx-auto">
          <div className="card shadow p-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fpapalmass%2Fpope_unsplash_2040x815.jpg?alt=media&token=a68e3b7c-ea91-44cd-b2e4-9276db560f4f"
              style={{ maxWidth: "100%", height: "auto" }}
              className="mt-0 responsive rounded"
              alt="Papal Photo"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fpapalmass%2Fpfs1.png?alt=media&token=064ecce6-fe5c-404a-a6d3-401bc1637f5b"
              style={{ maxWidth: "100%", height: "auto" }}
              className="mt-2 mb-0 responsive"
              alt="Papal Mass Logo"
            />
            <div className="pb-0 mb-0 bg-white">
              <hr style={{ borderTop: "2px dashed #f0f0ef" }} />
            </div>
            <p className="text-left ">
              Welcome{" "}
              {user?.fullname?.toUpperCase() ||
                user?.displayName?.toUpperCase()}
              ,
            </p>
            <p className="text-left mt-0 mb-3">
              Looking to ballot for the Papal Mass? You are in the right place!
            </p>
            <p className="mediumfontsize alert alert-success text-dark font-weight-bold text-left mt-0 mb-3">
              Registration will open soon! Please check back on 24 June at 10
              AM.
            </p>
            <p className="mediumfontsize  text-dark  text-left mt-0 mb-3 pb-4">
              Please keep a lookout on our official websites and channels for
              the latest updates.
              <br />
              <br />
              <a
                href="https://www.popefrancis2024.sg"
                rel="noopener noreferrer"
                target="_blank"
              >
                www.popefrancis2024.sg{" "}
                <OpenInNewOutlinedIcon style={{ fontSize: "14px" }} />
              </a>
              <br />
              <a
                href="https://www.catholic.sg"
                rel="noopener noreferrer"
                target="_blank"
              >
                www.catholic.sg{" "}
                <OpenInNewOutlinedIcon style={{ fontSize: "14px" }} />
              </a>
              <br />
              <a
                href="https://www.mycatholic.sg"
                rel="noopener noreferrer"
                target="_blank"
              >
                www.mycatholic.sg{" "}
                <OpenInNewOutlinedIcon style={{ fontSize: "14px" }} />
              </a>
              <br />
              <a
                href="https://www.catholicnews.sg"
                rel="noopener noreferrer"
                target="_blank"
              >
                www.catholicnews.sg{" "}
                <OpenInNewOutlinedIcon style={{ fontSize: "14px" }} />
              </a>
              <br />
              <a
                href="https://www.catholicfoundation.sg"
                rel="noopener noreferrer"
                target="_blank"
              >
                www.catholicfoundation.sg{" "}
                <OpenInNewOutlinedIcon style={{ fontSize: "14px" }} />
              </a>
              <br />
            </p>

            <div className="px-4 mb-4">
              <Button
                variant="primary"
                className="btn-block mt-4"
                onClick={handleBackToHome}
              >
                Back to Home
              </Button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default PapalMassPlaceholder;
