export function getMaxDays() {
  //front end
  return 40;
}

export function getAdminScheduleMaxDays() {
  //back end, parish admin
  return 90;
}

export function getEventTypes() {
  return [
    "Mass",
    "Adoration",
    "Confession",
    "Devotion",
    "Good Friday",
    "Holy Hour",
    "Stations of the Cross",
    "Christmas",
    "New Year",
    "Penitential",
    "Simbang Gabi",
    "Service",
  ];
}

export function getScheduleFrequency() {
  return ["Week", "1st", "2nd", "3rd", "4th", "5th"];
}

export function getEventTypeColor(type) {
  const defaultValue = { background: "#0089e8", color: "#ffffff", abbrev: "" };
  const colorMap = {
    Mass: { background: "#0089e8", color: "#ffffff", abbrev: "M" },
    Christmas: { background: "#008000", color: "#ffffff", abbrev: "C" },
    "Simbang Gabi": { background: "#800080", color: "#ffffff", abbrev: "SG" },
    Penitential: { background: "#ff0000", color: "#ffffff", abbrev: "P" },
    "New Year": { background: "#ffa500", color: "#ffffff", abbrev: "NY" },
    Devotion: { background: "#ffc107", color: "#000000", abbrev: "D" },
    "Holy Hour": { background: "#13745e", color: "#ffffff", abbrev: "HH" },
    "Stations of the Cross": {
      background: "#fd7e14",
      color: "#ffffff",
      abbrev: "SC",
    },
    "Ash Wednesday": { background: "#4b0082", color: "#ffffff", abbrev: "AW" },
    Adoration: { background: "#17a2b8", color: "#ffffff", abbrev: "A" },
    "Good Friday": { background: "#c20013", color: "#ffffff", abbrev: "GF" },
    "Maundy Thursday": {
      background: "#808080",
      color: "#ffffff",
      abbrev: "MT",
    },
    Service: { background: "#c20013", color: "#ffffff", abbrev: "SV" },
    Confession: { background: "#8c00ff", color: "#ffffff", abbrev: "CN" },
  };
  return colorMap[type] || defaultValue;
}

export const INIT_MODAL = {
  text: "",
  add: false,
  show: false,
  delete: false,
  edit: false,
  confirm: false,
  addsuccess: false,
  editsuccess: false,
  deletesuccess: false,
};

export const INIT_FORM = {
  selectdate: new Date().getTime(),
  capacity: null,
  reserved1: null,
  available: null,
  duration: null,
  frequency: "Week",
  location: "Main Church",
  override: false,
  lang: "English",
  notes: "",
  time: "6:30 AM",
  type: "Mass",
};
//modals
export const TYPE_VIEW = "view";
export const TYPE_SELECT_DAY = "selectday";
export const TYPE_CHOOSE_DELETE_TYPE = "choosedeletetype";

//add new
export const TYPE_ADD_ONETIME = "addonetime";
export const TYPE_DELETE_ONETIME = "deleteonetime";
export const TYPE_EDIT_ONETIME = "editonetime";
export const TYPE_CONFIRM_DELETE_ONETIME = "confirmdeleteonetime";

//add repeat
export const TYPE_ADD_REPEAT = "addrepeat";
export const TYPE_DELETE_REPEAT = "deleterepeat";
export const TYPE_DELETE_ONE_REPEAT = "deleteonerepeat";
export const TYPE_EDIT_REPEAT = "editrepeat";
export const TYPE_CONFIRM_DELETE_REPEAT = "confirmdeleterepeat";

export const SOURCE_SUMMARY = "summary";
export const SOURCE_MODAL = "modal";

export const ACTION_WEEKLY = "weekly";
export const ACTION_NTHWEEK = "nthweek";
export const ACTION_ADD = "add";
export const ACTION_OVERRIDE = "override";
export const ACTION_REMOVE = "remove";
