import React, { useState } from "react";
import Input from "../../common/input";
import { Button } from "react-bootstrap";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { validateEmail } from "../../../utils/utils";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import {
  BALLOT_EMAIL_PREFIX,
  BALLOT_MAX_MEMBERS,
} from "../utils/papalMassConst";
import { addInvite, checkAddInvite, checkCreateGroup } from "../service/ballot";
import SpinnerText from "../../common/spinnerwithText";
import { useSelector, useDispatch } from "react-redux";
import { setErrorProcessing } from "../redux/papalMassBallot";

function InviteEntry({
  mode = "",
  entries = [],
  onSubmit = () => {},
  setProcess = () => {},
  submitText = "Submit",
  limit = BALLOT_MAX_MEMBERS,
  checkExisting = false,
}) {
  const { currentUser, inviteList, acceptList } = useSelector(
    (state) => state.papalMassBallot
  );
  const [count, setCount] = useState(limit - entries?.length);
  const [inputs, setInputs] = useState(entries);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validateEntries = () => {
    let errorCount = 0;
    // console.log(inputs);
    for (let i = 0; i < inputs.length; i++) {
      const entry = inputs[i];
      const name = `${BALLOT_EMAIL_PREFIX}${entry?.id}`;
      const value = entry[name]?.toLowerCase()?.trim();
      const errCount = validateEntry(name, value);
      if (errCount) {
        errorCount = errorCount + errCount;
      }
    }
    return errorCount;
  };

  const getEmailsFromInputs = () => {
    const emails = [];
    for (let i = 0; i < inputs.length; i++) {
      const entry = inputs[i];
      const name = `${BALLOT_EMAIL_PREFIX}${entry?.id}`;
      const value = entry[name]?.toLowerCase()?.trim();
      if (value) {
        emails.push(value);
      }
    }
    return emails;
  };

  const findKeyByEmail = (emailList, email) => {
    for (const obj of emailList) {
      const key = Object.keys(obj).find((key) => obj[key] === email);
      if (key) return key;
    }
    return null;
  };

  const checkDuplicate = (name, value, list, threshold, message) => {
    // console.log("checkDuplicate called");
    //check if email already exists in the list
    let hasDuplicate = false;
    let freq = 0;
    let errorCount = 0;
    for (let i = 0; i < list.length; i++) {
      const entry = list[i];
      const key = `${BALLOT_EMAIL_PREFIX}${entry?.id}`;
      if (entry[key] === value) {
        freq++;
        if (freq >= threshold) {
          hasDuplicate = true;
          errorCount++;
          setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: message,
          }));
        }
      }
    }

    if (!hasDuplicate) {
      //clear error
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    return errorCount;
  };

  const checkInRecord = (name, value, list, threshold, message) => {
    //check if email already exists in the list
    let freq = 0;
    let errorCount = 0;
    for (let i = 0; i < list.length; i++) {
      const entry = list[i];
      // console.log(entry);
      let isMatch = false;
      if (entry?.id) {
        //use entry id
        const key = `${BALLOT_EMAIL_PREFIX}${entry?.id}`;
        if (entry[key] === value) {
          isMatch = true;
        }
      } else {
        //use email
        if (entry?.email === value) {
          isMatch = true;
        }
      }
      if (isMatch) {
        freq++;
        if (freq >= threshold) {
          errorCount++;
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: message,
          }));
        }
      }
    }

    return errorCount;
  };
  const validateEntry = (name, value) => {
    const email = currentUser?.email?.trim();

    let errorCount = 0;
    if (value === "") {
      errorCount++;

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Email cannot be empty",
      }));
    } else if (!validateEmail(value)) {
      errorCount++;

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Invalid email format",
      }));
    } else if (email === value) {
      errorCount++;

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "You can't use your own email",
      }));
    } else {
      //check if email already exists inside inputs
      const errorThreshold = 2; //allow 2 error
      errorCount = checkDuplicate(
        name,
        value,
        inputs,
        errorThreshold,
        "Duplicate email"
      );
    }
    if (checkExisting) {
      // console.log(inviteList);
      const errorThreshold = 1; //any duplicate count in invite list
      errorCount += checkInRecord(
        name,
        value,
        inviteList,
        errorThreshold,
        "Already invited"
      );
    }
    if (checkExisting) {
      // console.log(acceptList);
      const errorThreshold = 1; //any duplicate count in accept list
      // console.log(name, value);
      errorCount += checkInRecord(
        name,
        value,
        acceptList,
        errorThreshold,
        "Already in your group"
      );
    }

    return errorCount;
  };

  const handleChange = ({ currentTarget: input }) => {
    const updatedValue = input.value?.toLowerCase()?.trim();
    const key = input.name;
    // console.log(key, updatedValue);
    //update only the fields that have been changed
    const list = inputs.map((item) => {
      if (key in item) {
        return { ...item, [key]: updatedValue };
      }
      return item;
    });

    setInputs(list);
  };

  const handleOutFocus = ({ currentTarget: input }) => {
    const name = input.name;
    const value = input.value?.toLowerCase()?.trim() || "";
    validateEntry(name, value);
  };

  const tryRandomId = () => {
    return Math.floor(Math.random() * 10000000);
  };

  const handleAddNew = () => {
    const id = tryRandomId();
    const fields = [
      ...inputs,
      {
        [`${BALLOT_EMAIL_PREFIX}${id}`]: "", //make sure this is always empty
        id: id,
      },
    ];
    setCount(limit - fields.length);
    setInputs(fields);
  };

  const handleDelete = (id) => {
    const fields = inputs.filter((i) => i?.id !== id);
    setCount(limit - fields.length);
    setInputs(fields);
    validateEntries();
  };

  const tryCreateGroup = async () => {
    const uid = currentUser?.userid;
    // console.log(inviteList);
    // console.log(inputs);
    const emails = getEmailsFromInputs();
    // console.log(emails);
    //try to invite
    let dup = 0;
    const result = await checkCreateGroup(uid, emails);
    if (result?.data?.status === 2) {
      //user is already part of a group
      const emailList = result?.data?.emails;
      for (let i = 0; i < emailList.length; i++) {
        const email = emailList[i];
        const key = findKeyByEmail(inputs, email);
        // console.log(key);
        dup++;
        //user is already part of a group
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: "Already in a group",
        }));
        // console.log(result);
      }
    }

    // console.log(result);
    return dup;
  };

  const tryAddInvite = async () => {
    const uid = currentUser?.userid;
    // console.log(inviteList);
    // console.log(inputs);
    const emails = getEmailsFromInputs();
    // console.log(emails);
    //try to invite
    let dup = 0;
    for (let i = 0; i < emails.length; i++) {
      const email = emails[i];
      const out = await checkAddInvite(uid, email);
      if (out?.data?.status === 2) {
        dup++;
        const key = findKeyByEmail(inputs, email);
        // console.log(key);
        //user is already part of a group
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: "Already in a group",
        }));
      }
      // console.log(result);
    }
    return dup;
  };

  const doAddInvite = async () => {
    const uid = currentUser?.userid;
    // console.log(inviteList);
    // console.log(inputs);
    const emails = getEmailsFromInputs();
    // console.log(emails);
    //try to invite
    let value = 0;
    for (let i = 0; i < emails.length; i++) {
      const email = emails[i];
      const out = await addInvite(uid, email);
      // console.log(out);
      //if error, show error
      if (out?.data?.status === 0 || out?.data?.status === -1) {
        value++;
      }
    }
    if (value > 0) {
      dispatch(setErrorProcessing(true));
      return;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const errCount = validateEntries();
    // console.log(errCount);
    if (errCount > 0) {
      setLoading(false);
    } else if (errCount === 0) {
      if (mode === "create") {
        //try create group
        setProcess(true);
        const out = await tryCreateGroup();
        // console.log(out);
        setLoading(false);
        setProcess(false);
        if (out === 0) {
          onSubmit(inputs);
        }
      } else {
        //try additional invite
        setProcess(true);
        const dup = await tryAddInvite();
        // console.log(dup);
        if (dup > 0) {
          setLoading(false);
          setProcess(false);
        } else if (dup === 0) {
          //add here
          await doAddInvite();
          setLoading(false);
          setProcess(false);
          onSubmit(inputs);
        }
      }
    }
  };

  return (
    <div className="mb-3">
      <p className="appearslowly text-dark defaultfontsize text-center mt-2 mb-3">
        You can add up to <b>{count}</b> Member
        {parseInt(count) === 1 ? "" : "s"} with{" "}
        <b>valid myCatholicSG accounts.</b>
      </p>
      {inputs.length > 0 &&
        inputs.map((i, index) => {
          //get the name
          const name = `${BALLOT_EMAIL_PREFIX}${i?.id}`;
          //extract  uid
          const uid = i?.id;
          //we are differentiating email by uid
          return (
            <div
              key={uid}
              className="d-flex justify-content-center align-items-start px-0 mb-2"
            >
              <div style={{ marginTop: "10px" }}>{index + 1}</div>
              <Input
                disabled={loading}
                css="pl-0 pb-0 mb-0 ml-2 mr-1 text-left"
                type="text"
                name={`email_${uid}`}
                value={i[name]}
                label=""
                onBlur={handleOutFocus}
                // style={{ minWidth: "190px" }}
                placeholder="myCatholicSG Email"
                onChange={handleChange}
                error={errors[`email_${uid}`]}
              />
              <button
                disabled={loading}
                className="ml-1 p-0 pointer btn btn-sm btn-outline-danger"
                style={{ marginTop: "10px" }}
                onClick={() => handleDelete(uid)}
              >
                <CloseOutlinedIcon className="" />
              </button>
            </div>
          );
        })}
      <div className="mt-4 d-flex justify-content-center align-items-center">
        {inputs?.length < limit && (
          <Button
            disabled={loading}
            variant="link"
            style={{ border: "1px dashed #c0c0c0", borderRadius: "4px" }}
            className="py-2 px-4 font-weight-bold"
            onClick={handleAddNew}
            onMouseDown={(e) => e.preventDefault()}
          >
            <AddOutlinedIcon />
            Add Member
          </Button>
        )}
      </div>
      <div className="text-center appearslowly mt-4 mb-0">
        <div className="">
          <Button
            disabled={inputs?.length === 0 || loading}
            variant="primary"
            className="btn-lg mt-4 px-5"
            onClick={handleSubmit}
          >
            {!loading ? (
              <div className="font-weight-bold">{submitText}</div>
            ) : (
              <SpinnerText text={"Processing..."} />
            )}
            {/* <div className="font-weight-bold">{submitText}</div> */}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default InviteEntry;
