import { convertToInteger } from "../../../utils/string";
/*
Screens:
0 - init
1 - individual
2 - individual success
3 - individual cancel
4 - group
5 - group success
6 - group cancel
7 - 
???
Note: we use strings so we can easily insert values like "1.1", etc
*/

export const BALLOT_SCREEN_INIT = "0";
export const BALLOT_SCREEN_INDIVIDUAL = "1";
export const BALLOT_SCREEN_INDIVIDUAL_SUCCESS = "2";
export const BALLOT_SCREEN_INDIVIDUAL_CANCEL = "3";
export const BALLOT_SCREEN_GROUP_NO_INVITE = "4";
export const BALLOT_SCREEN_GROUP_HAVE_INVITE = "5";
export const BALLOT_SCREEN_GROUP_CREATE_INIT = "6";
export const BALLOT_SCREEN_GROUP_CREATE_SUCCESS = "7";
export const BALLOT_SCREEN_GROUP_SUCCESS = "7";
export const BALLOT_SCREEN_GROUP_CANCEL = "8";
export const BALLOT_SCREEN_GROUP_JOIN_SUCCESS = "9";
export const BALLOT_SCREEN_SPECIAL_INVITE = "10";
export const BALLOT_SCREEN_VOLUNTEER = "11";

/*
  Phases:
  1 - papal mass ballot placeholder
  2 - registration for ballot
  3 - close registration for ballot AND start of ballot
  4 - 1st ballot results
  5 - 2nd ballot registration?
  6 - 2nd ballot results
  7 - ticket assigning
  8 ???
*/

export const BALLOT_PHASE_1 = "1"; //placeholder
export const BALLOT_PHASE_2 = "2"; //registration for ballot
export const BALLOT_PHASE_3 = "3"; //close registration for ballot AND start of ballot
export const BALLOT_PHASE_4 = "4"; //1st ballot results
export const BALLOT_PHASE_5 = "5"; //close registration for 2nd ballot
export const BALLOT_PHASE_6 = "6"; //2nd ballot results
export const BALLOT_PHASE_7 = "7";
export const BALLOT_PHASE_8 = "8";

/*
User Status:
0/? - init
1 - individual ballot
2 - individual ballot cancelled 
3 - join group ballot
4 - join group ballot cancelled
5 - create group
6 - create group cancelled
???
*/

export const BALLOT_USER_INIT = "0";
export const BALLOT_USER_INDIVIDUAL_SELECTED = "1";
export const BALLOT_USER_INDIVIDUAL_CANCELLED = "2";
export const BALLOT_USER_GROUP_CREATED = "3";
export const BALLOT_USER_LEAVE_GROUP_CREATED = "4";
export const BALLOT_USER_JOIN_GROUP_SELECTED = "5";
export const BALLOT_USER_LEAVE_JOINED_GROUP = "6";
export const BALLOT_USER_JOIN_GROUP_CANCELLED = "7";
export const BALLOT_USER_SPECIAL_INVITE = "8";
export const BALLOT_USER_VOLUNTEER = "9";

/* ERROR */
export const BALLOT_USER_INDIVIDUAL_ERROR = "-1";

/* Actions Prefix */
export const PFS_CODE = "PFS";
export const PFS_CODE_STRING = convertToInteger(PFS_CODE);
/* Actions List */
export const PFS_REG_INDIVIDUAL_BALLOT = `${PFS_CODE_STRING}01`;
export const PFS_REG_INDIVIDUAL_BALLOT_OK = `${PFS_CODE_STRING}02`;
export const PFS_REG_INDIVIDUAL_BALLOT_NOK = `${PFS_CODE_STRING}03`;
export const PFS_REG_CANCEL_BALLOT = `${PFS_CODE_STRING}04`;
export const PFS_REG_CANCEL_BALLOT_OK = `${PFS_CODE_STRING}05`;
export const PFS_REG_CANCEL_BALLOT_NOK = `${PFS_CODE_STRING}06`;
export const PFS_REG_CREATE_GROUP_BALLOT = `${PFS_CODE_STRING}07`;
export const PFS_REG_CREATE_GROUP_BALLOT_OK = `${PFS_CODE_STRING}08`;
export const PFS_REG_CREATE_GROUP_BALLOT_NOK = `${PFS_CODE_STRING}09`;
export const PFS_REG_LEAVE_CREATED_GROUP_BALLOT = `${PFS_CODE_STRING}10`;
export const PFS_REG_LEAVE_CREATED_GROUP_BALLOT_OK = `${PFS_CODE_STRING}11`;
export const PFS_REG_LEAVE_CREATED_GROUP_BALLOT_NOK = `${PFS_CODE_STRING}12`;
export const PFS_REG_JOIN_GROUP_BALLOT = `${PFS_CODE_STRING}13`;
export const PFS_REG_JOIN_GROUP_BALLOT_OK = `${PFS_CODE_STRING}14`;
export const PFS_REG_JOIN_GROUP_BALLOT_NOK = `${PFS_CODE_STRING}15`;
export const PFS_REG_LEAVE_JOINED_GROUP_BALLOT = `${PFS_CODE_STRING}16`;
export const PFS_REG_LEAVE_JOINED_GROUP_BALLOT_OK = `${PFS_CODE_STRING}17`;
export const PFS_REG_LEAVE_JOINED_GROUP_BALLOT_NOK = `${PFS_CODE_STRING}18`;

/* Invites */
export const BALLOT_USER_INVITE_PENDING = "pending";
export const BALLOT_USER_INVITE_ACCEPTED = "accepted";
export const BALLOT_USER_INVITE_DECLINED = "declined";

/* Misc */
export const BALLOT_MAX_MEMBERS = 5;
export const BALLOT_HEADER_TEXT = "You have successfully registered for ";
export const BALLOT_EMAIL_PREFIX = "email_";
// change accordingly
export const NEXT_BALLOT_START = "5 August, 10:00";
export const NEXT_BALLOT_END = "18 August, 23:59";
export const BALLOT_SUCCESS_ACCEPT_DATE = "17 August 2024";

/* Role */
export const BALLOT_ROLE_LEADER = "leader";
export const BALLOT_ROLE_MEMBER = "member";

/* Ballot Status Values */
export const BALLOT_STATUS_UNKNOWN = "-1";
export const BALLOT_STATUS_UNREGISTERED = "0";
export const BALLOT_STATUS_INVITED = "1";
export const BALLOT_STATUS_REGISTERED = "2";
export const BALLOT_STATUS_BALLOTED_SUCCESS = "3";
export const BALLOT_STATUS_BALLOTED_ACCEPTED = "4";
export const BALLOT_STATUS_BALLOTED_REJECTED = "5";
export const BALLOT_STATUS_BALLOTED_UNSUCCESSFUL = "6";
export const BALLOT_STATUS_BALLOTED_WAITLISTED = "7";
export const BALLOT_STATUS_TICKET_ISSUED = "8";
export const BALLOT_STATUS_TICKET_RETURNED = "9";
export const BALLOT_STATUS_VOLUNTEER = "10";

/* Test Values */
export const BALLOT_SIMULATE = false;
export const BALLOT_INVITE_LIST_INIT = [
  {
    [`${BALLOT_EMAIL_PREFIX}297334697`]: "peter@gmail.com",
    name: "",
    id: "297334697",
    parish: "",
    // role: "",
    // created: new Date().getTime(),
  },
  // {
  //   [`${BALLOT_EMAIL_PREFIX}195578245`]: "paul@gmail.com",
  // name: "",
  //   id: "195578245",
  //   parish: "",
  // role: "",
  // created: new Date().getTime(),
  // },
  // {
  //   [`${BALLOT_EMAIL_PREFIX}067626765`]: "mark@gmail.com",
  // name: "",
  //   id: "067626765",
  //   parish: "",
  // role: "",
  // created: new Date().getTime(),
  // },
  // {
  //   [`${BALLOT_EMAIL_PREFIX}146252565`]: "luke@gmail.com",
  // name: "",
  //   id: "146252565",
  //   parish: "",
  // role: "",
  // created: new Date().getTime(),
  // },
  // {
  //   [`${BALLOT_EMAIL_PREFIX}729172330`]: "matthew@gmail.com",
  // name: "",
  //   id: "729172330",
  //   parish: "",
  // role: "",
  // created: new Date().getTime(),
  // },
];

export const BALLOT_ACCEPT_LIST_INIT = [
  {
    [`${BALLOT_EMAIL_PREFIX}297334697`]: "peter@gmail.com",
    name: "Peter",
    parish: "",
    id: "297334697",
    role: "",
    created: new Date().getTime(),
  },
  {
    [`${BALLOT_EMAIL_PREFIX}195578245`]: "paul@gmail.com",
    name: "Paul",
    parish: "",
    id: "195578245",
    role: "",
    created: new Date().getTime(),
  },
  // {
  //   [`${BALLOT_EMAIL_PREFIX}067626765`]: "mark@gmail.com",
  // name: "Mark",
  // parish: "",
  //   id: "067626765",
  // role: "",
  // created: new Date().getTime(),
  // },
  // {
  //   [`${BALLOT_EMAIL_PREFIX}146252565`]: "luke@gmail.com",
  // name: "Luke",
  // parish: "",
  //   id: "146252565",
  // role: "",
  // created: new Date().getTime(),
  // },
  {
    [`${BALLOT_EMAIL_PREFIX}729172330`]: "matthew@gmail.com",
    name: "Matthew",
    parish: "",
    id: "729172330",
    role: "",
    created: new Date().getTime(),
  },
];

export const BALLOT_INVITATION_LIST_INIT = [
  // {
  //   [`${BALLOT_EMAIL_PREFIX}297334697`]: "peter@gmail.com",
  //name: "Peter",
  //   id: "297334697",
  // parish: "",
  // role: "",
  // },
  // {
  //   [`${BALLOT_EMAIL_PREFIX}195578245`]: "paul@gmail.com",
  //name: "Paul",
  //   id: "195578245",
  // parish: "",
  // role: "",
  // created: new Date().getTime(),
  // },
  {
    [`${BALLOT_EMAIL_PREFIX}067626765`]: "mark@gmail.com",
    name: "Mark",
    id: "067626765",
    parish: "1",
    role: "",
    created: new Date().getTime(),
  },
  {
    [`${BALLOT_EMAIL_PREFIX}146252565`]: "luke@gmail.com",
    name: "Luke",
    id: "146252565",
    parish: "2",
    role: "",
    created: new Date().getTime(),
  },
  // {
  //   [`${BALLOT_EMAIL_PREFIX}729172330`]: "matthew@gmail.com",
  // name: "Matthew",
  //   id: "729172330",
  // parish: "",
  // role: "",
  // created: new Date().getTime(),
  // },
];

export const BALLOT_MEMBER_LIST_INIT = [
  // {
  //   [`${BALLOT_EMAIL_PREFIX}297334697`]: "peter@gmail.com",
  //name: "Peter",
  //   id: "297334697",
  // parish: "",
  // role: "",
  // created: new Date().getTime(),
  // },
  // {
  //   [`${BALLOT_EMAIL_PREFIX}195578245`]: "paul@gmail.com",
  //name: "Paul",
  //   id: "195578245",
  // parish: "",
  // role: "",
  // created: new Date().getTime(),
  // },
  // {
  //   [`${BALLOT_EMAIL_PREFIX}067626765`]: "mark@gmail.com",
  //   name: "Mark",
  //   id: "067626765",
  //   parish: "1",
  // role: "",
  // created: new Date().getTime(),
  // },
  // {
  //   [`${BALLOT_EMAIL_PREFIX}146252565`]: "luke@gmail.com",
  //   name: "Luke",
  //   id: "146252565",
  //   parish: "2",
  // role: "",
  // created: new Date().getTime(),
  // },
  {
    [`${BALLOT_EMAIL_PREFIX}729172330`]: "matthew@gmail.com",
    name: "Matthew",
    id: "729172330",
    parish: "",
    role: "",
    created: new Date().getTime(),
  },
];
